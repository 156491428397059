import { FC } from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { FaStar } from "react-icons/fa"
import { Swiper, SwiperSlide } from "~/components/modules"

const testimonials = [
  {
    text: "We are very happy that we decided to list our home with HonestDoor. We saved thousands of dollars and our house is SOLD. The process was very simple and the service was great. Highly recommend!",
    author: "Michelle",
  },
  {
    text: "HonestDoor team is very professional, patient, good with communication, and working hard for less. I recommend for anyone who wants to have more control over sale of their own house and wants to save.",
    author: "Ewa R.",
  },
  {
    text: "I found this to be a great resource as I search for a home. More informative than many realtors! Please keep this service going and updated.",
    author: "Sherif M.",
  },
  {
    text: "Flat fee “mere posting” service on MLS® is very cool!",
    author: "Robin V.",
  },
  {
    text: "Fantastic experience! I've been a huge fan of HonestDoor since they launched years ago so I jumped at the opportunity to list my house through their MLS® listing option. House sold within a few hours of listing! Amazing assistance from the team when needed made this an ideal experience. Highly recommended!",
    author: "Rob B.",
  },
  {
    text: "We were very pleased with the experience we had with honest door. It was a simple and affordable process, and we appreciated having control over the interactions with potential buyers and realtors. We are satisfied with the results.",
    author: "Melissa B.",
  },
]

export const TestimonialsSlider: FC = () => {
  return (
    <Box backgroundColor="gold.500" position="relative" borderRadius="lg" padding="24px">
      <Flex justifyContent="center" alignItems="center" direction="column">
        <Flex alignItems="center" direction="column">
          <Heading as="h4" size="lg" color="white" textTransform="uppercase">
            HonestDoor Reviews
          </Heading>
        </Flex>
      </Flex>

      <Box position="relative">
        <Swiper
          slidesPerView={1}
          navigation={true}
          loop={true}
          centeredSlides={false}
          freeMode={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
          }}
          className="mls-testimonials"
        >
          {testimonials.map((item, i) => (
            <SwiperSlide key={i}>
              <Flex direction="column" position="relative" color="white" padding="24px">
                <Flex>
                  <FaStar color="bisque" />
                  <FaStar color="bisque" />
                  <FaStar color="bisque" />
                  <FaStar color="bisque" />
                  <FaStar color="bisque" />
                </Flex>
                <Box lineHeight="24px" my="12px">
                  &quot;{item.text}&quot;
                </Box>
                <Box fontWeight="bold">{item.author}</Box>
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}
