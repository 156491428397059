import { FC, useState } from "react"
import { useRouter } from "next/router"
import { gql, useLazyQuery } from "@apollo/client"
import {
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  useToast,
  Center,
  Spinner,
  VStack,
  Text,
  Link,
} from "@chakra-ui/react"
import { FaArrowRight, FaSearch } from "react-icons/fa"
import { PropertiesAutocomplete } from "~/components/modules"
import { ButtonVariants } from "~/theme/components"
import { calculateCommission } from "~/utils/helpers"
import { formatCurrency, provinceLongToProvinceAbbr } from "~/utils"
import {
  PropertyFullDocument,
  PropertyFullFragment,
  useCreatePrivateLeadMutation,
} from "~/generated/graphql"
import { useAuth, useUser } from "~/components/hooks"
import Blur from "~/components/helpers/Blur"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"
import { provinces } from "~/utils/constants"

export const CommissionCalculator: FC = () => {
  const router = useRouter()
  const { user } = useUser()
  const { openSignInModal } = useAuth()
  const toast = useToast()
  const [property, setProperty] = useState(null)
  const [calculatedValue, setCalculatedValue] = useState(null)
  const [loadProperty, { loading: isPropertyLoading }] = useLazyQuery(gql(PropertyFullDocument))
  const { mutateAsync: createPrivateLead, isLoading: isPrivateLeadCreateLoading } =
    useCreatePrivateLeadMutation()

  const onPropertySelect = async (selectedProperty: PropertyFullFragment) => {
    try {
      const { data } = await loadProperty({ variables: { id: selectedProperty.id } })
      setProperty(data?.property)
      if (!data?.property.predictedValue) return

      const province = provinceLongToProvinceAbbr(data?.property?.province)
      const commission = calculateCommission(data?.property?.predictedValue, province, "sell")

      setCalculatedValue(commission)
      await createPrivateLead({
        input: {
          province,
          source: PrivateLeadSource.SellCashbackCalc,
          type: PrivateLeadType.Sell,
          cityName: data?.property?.cityName,
          streetAddress: data?.property?.unparsedAddress,
          slug: data?.property?.slug,
          cashback: Math.ceil(+commission.amountSaved),
          hdPrice: data?.property?.predictedValue,
        },
      })
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  const onSearchAgainClick = () => {
    setProperty(null)
    setCalculatedValue(null)
  }

  return (
    <Box color="initial">
      <Blur
        minH="110px"
        blurred={!user}
        overlay={
          <VStack h="100%" w="100%" justifyContent="center">
            <Text textAlign="center">
              <Link fontWeight="bold" onClick={() => openSignInModal()}>
                Sign in
              </Link>
              &nbsp;to use calculator
            </Text>
          </VStack>
        }
      >
        {(isPropertyLoading || isPrivateLeadCreateLoading) && (
          <Box position="absolute" left={0} right={0} top={0} bottom={0} zIndex={1000}>
            <Center h="100%" w="100%">
              <Spinner color="hdGold.full" size="xl" thickness="5px" />
            </Center>
          </Box>
        )}
        {property ? (
          <>
            <Stack spacing={3}>
              <Box textAlign="center" color="gray">
                {[property.unparsedAddress, property.cityName, property.province].join(", ")}
              </Box>
              {property.predictedValue ? (
                <>
                  <Heading as="h3" fontSize="22px" color="hdGold.full" textAlign="center">
                    Estimated value {`${formatCurrency(property?.predictedValue)}`}
                  </Heading>

                  {calculatedValue && (
                    <Stack direction="column" spacing={3}>
                      <Heading as="h3" fontSize="22px" textAlign="center" color="hdMoney.full">
                        {formatCurrency(calculatedValue.amountSaved)} estimated savings*
                      </Heading>
                      <Box fontSize="12px">
                        {[provinces.ON].includes(property?.province) ? (
                          <>
                            * These estimated savings are calculated using the sell side commission
                            in {property?.province}; based on {(calculatedValue.rate * 100).toFixed(2)}% on the
                            sale price.
                          </>
                        ) : (
                          <>
                            * These estimated savings are calculated using the sell side commission
                            in {property?.province}; based on{" "}
                            {(calculatedValue.rate * 100).toFixed(2)}% on the first{" "}
                            {formatCurrency(calculatedValue.threshold)} of the sale price and{" "}
                            {(calculatedValue.rateRemaining * 100).toFixed(2)}% on the remainder.
                          </>
                        )}
                      </Box>
                    </Stack>
                  )}
                </>
              ) : (
                <Box textAlign="center" color="hdFire.full">
                  Price is not available for the selected property
                </Box>
              )}

              <Center>
                <Stack
                  direction={["column", "column", "column", "row"]}
                  align="center"
                  textAlign="center"
                >
                  <Button
                    variant={ButtonVariants.BlackRounded}
                    backgroundColor="hdMoney.full"
                    leftIcon={<Icon as={FaSearch} />}
                    onClick={onSearchAgainClick}
                  >
                    Search again
                  </Button>

                  <Button
                    variant={ButtonVariants.BlackRounded}
                    backgroundColor="hdMoney.full"
                    rightIcon={<Icon as={FaArrowRight} />}
                    px="32px"
                    onClick={() => router.push(`/property/${property?.slug}`)}
                  >
                    View Property
                  </Button>
                </Stack>
              </Center>
            </Stack>
          </>
        ) : (
          <PropertiesAutocomplete onSelect={onPropertySelect} />
        )}
      </Blur>
    </Box>
  )
}
