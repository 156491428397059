import { UseSessionOptions, useSession } from "next-auth/react"
import { useGetMeQuery } from "~/generated/graphql"

export const useUser = (props?: UseSessionOptions<any> | undefined) => {
  const { status } = useSession(props)

  const { data, isLoading } = useGetMeQuery(null, {
    enabled: status === "authenticated",
  })

  return {
    user: data?.getMe ?? null,
    isLoading: isLoading || status === "loading",
  }
}
