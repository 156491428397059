import getConfig from "next/config"
import { max, minYearBuiltActual } from "~/utils/constants/sliderMinMax"

import { Filter } from "./Map.types"

const MAPBOX_STYLE_URL =
  process.env.NODE_ENV !== "test" ? getConfig().publicRuntimeConfig.MAPBOX_STYLE_URL : ""

export const polygon = {
  initialState: {
    isActive: false,
    isFromSearch: true,
    currentHighlight: "",
    sourceId: "",
    propertyId: "",
    geoJsonSource: null,
  },
}

export const MapDefaults = {
  __typename: "hdMap",
  style: MAPBOX_STYLE_URL,
  center: [-113.323975, 53.631611] as [number, number],
  zoom: [3] as [number], // stupid ts tuples
  bounds: null,
  coordinates: null,
  offset: null,
  properties: null,
  listing: null,
  listingsData: null,
  listingsCategory: "For Sale",
  isLoading: true,
  showSatellite: false,
  geoIp: false,
  userCoords: null,
  activeMultiPin: null,
  clickedFeature: {
    __typename: "clickedFeature",
    ...polygon.initialState,
  },
  selectedFeature: {
    __typename: "selectedFeature",
    ...polygon.initialState,
  },
  filteredFeatures: {
    __typename: "filteredFeatures",
    isActive: false,
    isLoading: false,
    total: 0,
    count: 0,
    geojson: null,
  },
  propertiesDataAll: [],
  popupProps: {
    __typename: "popupProps",
    coordinates: [],
    offset: [],
  },
}


export const MapFilterDefaults = {
  __typename: "filter",
  channelID: null,
  focusedFields: [],
  activeFields: [],
  isOpen: false,
  listView: false,
  baths: 0,
  beds: 0,
  lastEstimatedPrice: [5000, max.lastEstimatedPrice],
  closePrice: [5000, max.closePrice],
  closeDate: [2018, new Date().getFullYear() + 1],
  yearBuiltActual: [minYearBuiltActual, new Date().getFullYear() + 1],
  lotSizeArea: [500, 20000],
  livingArea: [100, max.livingArea],
  transactionsHistory: [0, 10],
  propertySubType: [],
  zoning: [],
  assessmentClass: [],
} as Filter
