import { useGoogleOneTapSignIn } from "~/components/hooks"

export const GoogleOneTapLogin = () => {
  useGoogleOneTapSignIn({
    redirect: false,
    parentContainerId: "oneTap",
  })

  return <div id="oneTap" style={{ position: "absolute", top: "50px", right: "0" }} />
}
