import "~/styles/index.scss"

import {
  Box,
  Button,
  ChakraProvider,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Switch,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import dynamic from "next/dynamic"
import { DefaultOptions, QueryClient, QueryClientProvider } from "@tanstack/react-query" // "react-query"
import { StoreProvider, useFeatureFlagsStore, useMapStore } from "~/store/StoreProvider"
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react"
import { AppProps } from "next/app"
import { DefaultSeo } from "next-seo"
import { FaCode, FaRulerHorizontal } from "react-icons/fa"
import { Footer } from "~/components/modules/Footer"
import { MapView } from "~/store/map"
import NProgress from "nprogress" //nprogress module
import Nav from "~/components/modules/Nav"
import Script from "next/script"
import { SessionProvider } from "next-auth/react"
import chakraTheme from "~/theme"
import { defaultMetadata } from "~/utils/seo"
import getConfig from "next/config"
import gtag from "~/utils/lib/gtag"
import { observer } from "mobx-react-lite"
import { useRouter } from "next/router"
import { Session } from "next-auth"
import { ApolloProvider } from "@apollo/client"
import { useApollo } from "~/utils/lib/apolloClient"
import { AuthProvider } from "~/components/context-providers"
import { PopupListOnMls, SignInModal } from "~/components/modules"
import { useAuth } from "~/components/hooks/use-auth.hook"
import { GoogleOneTapLogin } from "~/components/modules/google-one-tap-login"

const CrispChat = dynamic(() => import("~/components/modules/crisp").then(mod => mod.CrispChat), {
  ssr: false,
})

// import { datadogRum } from "@datadog/browser-rum"

const { GOOGLE_ADS_TAG_ID } = getConfig().publicRuntimeConfig

// if (!isDev && process.browser) {
//   datadogRum.init({
//     applicationId: "726fcbec-ef81-466d-ac1e-ef49a962433b",
//     clientToken: "pub77117414d15320d3cdd43ea1486b6812",
//     site: "datadoghq.com",
//     service: "honestdoor",
//     env: ENV,
//     version: appVersion,
//     sampleRate: 100,
//     replaySampleRate: 5,
//     trackInteractions: true,
//     defaultPrivacyLevel: "mask-user-input",
//   })

//   datadogRum.startSessionReplayRecording()
// }

NProgress.configure({
  showSpinner: false,
  template:
    '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
})

/**
 * Hack to address viewport height on mobile as the size of vh is not based on
 * the active window height, but the height of the window before the browser
 * bars are added (See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/).
 * There is related CSS in ~/styles/global.ts
 */
const viewportHeightHandler = () => {
  const vh = window?.innerHeight * 0.01
  document?.documentElement.style.setProperty("--vh", `${vh}px`)
}

const defaultQueryOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
  },
}

const App = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session
  initialState: any
}>): JSX.Element => {
  const [queryClient] = useState(() => new QueryClient({ defaultOptions: defaultQueryOptions }))
  const router = useRouter()
  const apolloClient = useApollo()

  useEffect(() => {
    viewportHeightHandler()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", viewportHeightHandler)

      // if (ENV === "production") {
      //   // facebook pixel
      //   import("react-facebook-pixel")
      //     .then(x => x.default)
      //     .then(ReactPixel => {
      //       ReactPixel.init("1567138164108574")
      //       ReactPixel.pageView()
      //       router.events.on("routeChangeComplete", () => ReactPixel.pageView())
      //     })
      // }
    }

    router.events.on("routeChangeStart", () => NProgress.start())
    router.events.on("routeChangeComplete", () => NProgress.done())
    router.events.on("routeChangeError", () => NProgress.done())

    return () => {
      window.removeEventListener("resize", viewportHeightHandler)
      router.events.off("routeChangeStart", () => NProgress.start())
      router.events.off("routeChangeComplete", () => NProgress.done())
      router.events.off("routeChangeError", () => NProgress.done())
    }
  }, [router.events])

  const noLayout = useMemo(
    () => ["/unsubscribe/agent"].includes(router.pathname),
    [router.pathname],
  )

  return (
    <>
      <DefaultSeo {...defaultMetadata} />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />

      <Script src={`https://accounts.google.com/gsi/client`} async strategy="beforeInteractive" />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtag.GA_TRACKING_ID}', {
          page_path: window.location.pathname,
        });
        gtag('config', '${GOOGLE_ADS_TAG_ID}');
      `,
        }}
      />

      {/*{ENV === "production" && (*/}
      {/*  <Script*/}
      {/*    id="reddit-pixel"*/}
      {/*    strategy="afterInteractive"*/}
      {/*    dangerouslySetInnerHTML={{*/}
      {/*      __html: `*/}
      {/*     !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_egcsfd79fuld', {"aaid":"<AAID-HERE>","email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>","idfa":"<IDFA-HERE>"});rdt('track', 'PageVisit');*/}
      {/*    `,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}

      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <SessionProvider session={pageProps.session}>
            <QueryClientProvider client={queryClient}>
              <StoreProvider initialState={pageProps.initialState}>
                <ChakraProvider resetCSS={true} theme={chakraTheme}>
                  {!noLayout ? (
                    <MainBox>
                      <Nav />
                      <FeatureFlagsMenu />
                      <Component {...pageProps} />
                      <Footer />
                    </MainBox>
                  ) : (
                    <Component {...pageProps} />
                  )}
                </ChakraProvider>
              </StoreProvider>
            </QueryClientProvider>
          </SessionProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  )
}

const MainBox: FC<PropsWithChildren> = observer(({ children }) => {
  const router = useRouter()
  const { view } = useMapStore()
  const { isSignInModalOpen, onCloseSignInModal, callbackUrl } = useAuth()
  return (
    <>
      <Flex
        flexDir="column"
        height={
          router.pathname.includes("/map") && view !== MapView.LIST
            ? "calc(var(--vh, 1vh) * 100)"
            : "initial"
        }
      >
        {children}
      </Flex>

      <SignInModal
        isOpen={isSignInModalOpen}
        onClose={onCloseSignInModal}
        callbackUrl={callbackUrl}
      />
      <GoogleOneTapLogin />
      <PopupListOnMls />
      <CrispChat />
    </>
  )
})

const FeatureFlagsMenu = observer(() => {
  const [isDevTools, setIsDevTools] = useState(false)
  const { flags, setFlags } = useFeatureFlagsStore()
  const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key == "`") {
        setIsDevTools(d => !d)
      }
    }

    window.addEventListener("keydown", listener)

    return () => {
      window.removeEventListener("keydown", listener)
    }
  }, [])

  if (!flags.settings && !isDevTools) return null

  return (
    <Box
      zIndex={"999999"}
      position={"fixed"}
      bottom={2}
      left={"50%"}
      transform={"translateX(-50%)"}
    >
      <VStack>
        <Button
          colorScheme={"blackAlpha"}
          size={"sm"}
          leftIcon={<Icon as={FaCode} />}
          rightIcon={
            <Icon
              color={["red", "orange", "yellow", "green", "blue", "indigo", "violet"]}
              as={FaRulerHorizontal}
            />
          }
          onClick={onToggle}
        >
          Dev Settings
        </Button>
        {isOpen && (
          <Box minW={150} p={3} rounded={"md"} bgColor={"blackAlpha.500"}>
            <Text color={"white"} fontSize={"sm"} fontWeight={"medium"}>
              Feature Flags
            </Text>
            <Divider my={2} />
            {Object.keys(flags).map(feature => (
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                key={feature}
              >
                <FormLabel color={"white"} fontSize={"sm"} htmlFor="property-filters" mb="0">
                  {feature}
                </FormLabel>
                <Switch
                  isChecked={flags[feature]}
                  onChange={() => setFlags({ [feature]: !flags[feature] })}
                  size={"sm"}
                  id={feature}
                />
              </FormControl>
            ))}
          </Box>
        )}
      </VStack>
    </Box>
  )
})

// export async function reportWebVitals(metric: NextWebVitalsMetric) {
//   const { id, label, name, value } = metric

//   if (label !== "web-vital") return

//   const basePath = window.location.pathname.split("/")[1]

//   window.gtag("event", name, {
//     event_category: `Web Vitals - /${basePath}`,
//     value: name === "CLS" ? value * 1000 : (value / 1000).toFixed(2),
//     event_label: id,
//     non_interaction: true,
//   })
// }

export default App
