export enum ButtonVariants {
  WhiteRounded = "white-rounded",
  BlackRounded = "black-rounded",
  WhiteRoundedGhost = "white-rounded-ghost",
  BlackRoundedGhost = "black-rounded-ghost",
  Rounded = "rounded",
  WhiteRoundedBorder = "white-rounded-border",
  GreenRounded = "green-rounded",
  RedRounded = "red-rounded",
  HDMoneyRounded = "hdMoney-rounded",
  GreyRounded = "grey-rounded",
  GreyBackBlackText = "grey-black",
  Primary = "primary",
  Secondary = "secondary",
  Link = "link",
  Floating = "floating",
}

const Button = {
  variants: {
    "green-rounded": {
      bgColor: "hdMoney.full",
      color: "white",
      borderRadius: "3xl",
      _hover: { bgColor: "hdMoney.faded" },
    },
    "red-rounded": {
      bgColor: "hdFire.full",
      color: "white",
      borderRadius: "3xl",
      _hover: { bgColor: "hdFire.faded" },
    },
    "white-rounded": {
      bgColor: "white",
      color: "hdBlack.500",
      borderRadius: "3xl",
      _hover: { bgColor: "hdGold.faded" },
    },
    "white-rounded-border": {
      bgColor: "white",
      color: "hdBlack.500",
      borderWidth: "1.5px",
      borderColor: "black",
      borderRadius: "3xl",
      _hover: { bgColor: "hdGold.faded" },
    },
    "black-rounded": {
      bgColor: "hdBlack.500",
      color: "white",
      borderRadius: "3xl",
      _disabled: {
        bgColor: "hdBlack.300",
      },
      _hover: {
        bgColor: "hdBlack.300",
        _disabled: {
          bgColor: "hdBlack.300",
        },
      },
    },
    "hdMoney-rounded": {
      bgColor: "hdMoney.500",
      color: "white",
      borderRadius: "3xl",
      _disabled: {
        bgColor: "hdMoney.300",
      },
      _hover: {
        bgColor: "hdMoney.300",
        _disabled: {
          bgColor: "hdMoney.300",
        },
      },
    },
    "white-rounded-ghost": {
      borderRadius: "3xl",
      bgColor: "transparent",
      color: "white",
      _hover: { bgColor: "white", color: "hdBlack.500" },
    },
    "black-rounded-ghost": {
      borderRadius: "3xl",
      bgColor: "transparent",
      color: "hdBlack.500",
      _hover: { bgColor: "hdBlack.500", color: "white" },
    },
    rounded: {
      borderRadius: "3xl",
    },
    "grey-black": {
      borderRadius: "3xl",
      bgColor: "hdBlack.light",
      color: "hdBlack.500",
      _hover: { color: "hdBlack.full", bgColor: "hdBlack.200" },
    },
    "grey-rounded": {
      borderRadius: "3xl",
      bgColor: "hdBlack.light",
      color: "white",
      _hover: { bgColor: "hdBlack.200" },
    },
    // UI refresh components
    primary: {
      color: "gray.900",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "500",
      borderColor: "gray.900",
      border: "1px solid",
      _hover: { bgColor: "gray.200" },
      _active: { bgColor: "gray.300" },
      _disabled: { color: "gray.500", bgColor: "gray.200" },
      // TODO: Custom focus colors? The blue is a little weird
      // _focus: { boxShadow: "none" },
    },
    secondary: {
      color: "white",
      bgColor: "gold.500",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "500",
      borderColor: "gray.900",
      border: "1px solid",
      _hover: { bgColor: "gold.600" },
      _active: { bgColor: "gold.700" },
      _disabled: { color: "gray.500", bgColor: "gray.200" },
    },
    link: {
      color: "gray.900",
      borderRadius: 0,
      textDecoration: "underline",
      _hover: { color: "gold.500" },
      _active: { color: "gold.700" },
      _disabled: { color: "gray.500" },
      _focus: { boxShadow: "none !important" },
    },
    floating: {
      color: "white",
      bgColor: "#282F45D8",
      _hover: { bgColor: "#3F4A6BD8" },
      _active: { bgColor: "#4F5D86D8" },
    },
  },
}

export default Button
