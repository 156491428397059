import { ReactNode } from "react"
import NextLink from "next/link"
import { EmblaOptionsType } from "embla-carousel-react"
import {
  Box,
  Flex,
  Heading,
  HeadingProps,
  Link,
  Text,
  chakra,
} from "@chakra-ui/react"
import { BreakpointKeys } from "~/theme/breakpoints"
import { Carousel } from "components/helpers/carousels"

type TMultiCarouselHeaderProps = {
  title?: string
  titleSuffix?: string
  titleFontSize?: HeadingProps["fontSize"]
  link?: {
    label: string
    href: string
    onClick?: () => void
  }
  wrapperFlexPadding?: string | string[]
}

export type TMultiCarouselProps = {
  showArrows?: boolean
  showDots?: boolean
  sizes?: { [P in BreakpointKeys]?: number }
  spacing?: number
  children: ReactNode
} & TMultiCarouselHeaderProps &
  EmblaOptionsType

export const MultiCarousel = (props: TMultiCarouselProps): JSX.Element => {
  // TODO: For each element in sizes create a column array
  return (
    <>
      {(props.title || props.link) && (
        <MultiCarouselHeader
          title={props.title}
          titleSuffix={props.titleSuffix}
          link={props.link}
          titleFontSize={props.titleFontSize}
        />
      )}
      <Carousel {...props} />
    </>
  )
}

MultiCarousel.defaultProps = {
  showArrows: true,
  showDots: true,
  sizes: {
    "3xl": 6,
    "2xl": 5,
    xl: 4,
    lg: 3,
    md: 2,
    sm: 1,
  },
} as TMultiCarouselProps & EmblaOptionsType

export const MultiCarouselHeader = (props: TMultiCarouselHeaderProps) => {
  const { title, titleSuffix, link, titleFontSize } = props
  return (
    <Flex justify={"space-between"} align={"center"} mb={5}>
      {title && (
        <Heading
          color="hdBlack.500"
          as="h2"
          fontSize={titleFontSize || ["28px", "28px", "28px", "38px"]}
          fontWeight="500"
        >
          {title}&nbsp;
          <Box as="br" display={[null, null, null, null, "none"]} />
          {titleSuffix && (
            <Text
              fontSize={["24px", "24px", "24px", "26px"]}
              color="hdBlack.300"
              as="span"
              textTransform={"capitalize"}
            >
              {titleSuffix}
            </Text>
          )}
        </Heading>
      )}
      {link && (
        <Link
          as={NextLink}
          prefetch={false}
          href={link.href}
          passHref
          fontSize={["20px", "20px", "20px", "26px"]}
          minW={"fit-content"}
          onClick={link.onClick}
        >
          <chakra.span display={["none", "none", "none", "initial"]}>{link.label}</chakra.span>
          <chakra.span display={["initial", "initial", "initial", "none"]}>See More</chakra.span>
        </Link>
      )}
    </Flex>
  )
}
