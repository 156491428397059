import { FC, createContext, ReactNode, useState } from "react"
import {
  signIn as nextSignIn,
  signOut as nextSignOut,
  LiteralUnion,
  SignInAuthorizationParams,
  SignInOptions,
  SignInResponse,
  SignOutParams,
} from "next-auth/react"
import { BuiltInProviderType } from "next-auth/providers"
import { useDisclosure } from "@chakra-ui/react"

interface IAuthProvider {
  children: ReactNode
}

interface AuthContext {
  signIn: (
    provider?: LiteralUnion<BuiltInProviderType>,
    options?: SignInOptions,
    authorizationParams?: SignInAuthorizationParams,
  ) => Promise<SignInResponse>
  signOut: () => Promise<void>
  isSignInModalOpen: boolean
  onCloseSignInModal: () => void
  openSignInModal: (params?: { callbackUrl?: string }) => void
  callbackUrl?: string
  clearCallbackUrl: () => void
}

export const AuthProviderContext = createContext<AuthContext>({
  signIn: () => Promise.resolve(null),
  signOut: () => Promise.resolve(null),
  isSignInModalOpen: false,
  onCloseSignInModal: () => {},
  openSignInModal: () => {},
  clearCallbackUrl: () => {},
})

export const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const [callbackUrl, setCallbackUrl] = useState<string>()
  const { isOpen: isSignInModalOpen, onClose: onCloseSignInModal, onOpen } = useDisclosure()

  function openSignInModal({ callbackUrl }: { callbackUrl?: string } = {}) {
    if (callbackUrl) {
      setCallbackUrl(callbackUrl)
    }
    onOpen()
  }

  async function signIn(
    provider?: LiteralUnion<BuiltInProviderType>,
    options?: SignInOptions,
    authorizationParams?: SignInAuthorizationParams,
  ) {
    return nextSignIn(provider, options, authorizationParams)
  }

  async function signOut(options?: SignOutParams) {
    return nextSignOut(options)
  }

  function clearCallbackUrl() {
    setCallbackUrl(undefined)
  }

  const value = {
    signIn,
    signOut,
    isSignInModalOpen,
    onCloseSignInModal,
    openSignInModal,
    callbackUrl,
    clearCallbackUrl,
  }

  return <AuthProviderContext.Provider value={value}>{children}</AuthProviderContext.Provider>
}
