import { FC } from "react"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react"
import { ListOnMlsForm } from "~/components/modules/mls/list-on-mls.form"

export const ListOnMlsModal: FC<Partial<ModalProps>> = ({ isOpen, onClose }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>List your property on the MLS® for $500</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text as="p">
            Selling your house? We are offering our newest feature at a promotional rate, you can list your property on the MLS® for $500. Your listing will be on Realtor.ca and all syndicate sites.
          </Text>

          <Text as="p" my={5}>
            Provide your information and we&apos;ll be in touch!
          </Text>
          <ListOnMlsForm onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
