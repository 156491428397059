import { addDays } from "date-fns/addDays"
import { isBefore } from "date-fns/isBefore"
import React, { useEffect, useReducer } from "react"

const IP_API = "http://ip-api.com/json/"

type UserLocation = {
  query?: string
  status?: string
  country?: string
  countryCode?: string
  region?: string
  regionName?: string
  city?: string
  zip?: string
  lat?: number
  lon?: number
  timezone?: string
  isp?: string
  org?: string
  as?: string
}

type Action = { type: "success"; payload: UserLocation } | { type: "fail" }

export const UserLocationContext = React.createContext<{
  location: UserLocation
}>({ location: null })

export const useUserLocation = () => React.useContext(UserLocationContext)

function reducer(_: UserLocation, action: Action) {
  switch (action.type) {
    case "success":
      return { ...action.payload }
    case "fail":
      return null
    default:
      throw new Error()
  }
}

export const UserLocationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null)

  const updateUserLocation = async () => {
    try {
      const locationRaw = localStorage.getItem("hd-loc")

      if (locationRaw) {
        const locationJson = JSON.parse(locationRaw)

        if (isBefore(new Date(), new Date(locationJson.refreshAt))) {
          return dispatch({ type: "success", payload: locationJson })
        }
      }

      const data = await fetch(`${IP_API}`).then(data => data.json())

      if (data.status === "success") {
        dispatch({ type: "success", payload: data })
        return storeUserLocation(data)
      } else {
        dispatch({ type: "fail" })
        return removeUserLocation()
      }
    } catch (error) {
      dispatch({ type: "fail" })
      return removeUserLocation()
    }
  }

  const storeUserLocation = (location: UserLocation) =>
    localStorage.setItem(
      "hd-loc",
      JSON.stringify({ ...location, refreshAt: addDays(new Date(), 7) }),
    )

  const removeUserLocation = () => localStorage.removeItem("hd-loc")

  useEffect(() => {
    updateUserLocation()
  }, [])

  return (
    <UserLocationContext.Provider value={{ location: state }}>
      {children}
    </UserLocationContext.Provider>
  )
}
