import { extendTheme } from "@chakra-ui/react"

import { colors } from "./colors"
import { components } from "./components"
import { fonts } from "./fonts"
import { shadows } from "./shadows"
import { breakpoints } from "./breakpoints"

export default extendTheme({
  colors,
  fonts,
  shadows,
  components,
  breakpoints,
  styles: {
    global: () => ({
      body: {
        fontSize: 16,
        // light gray
        bgColor: "gray.50",
        color: "hdBlack.base",
      },
      /**
       * @TODO: Temporarily moved styles from delete GlobalStyle (global.ts).
       * This can be removed once the new MapControls component gets merged.
       */
      ".mapboxgl-map": {
        zIndex: 0,
      },
      ".sheet-root": {
        zIndex: "1000 !important",
      },
      // ".sheet-root .react-modal-sheet-container": {
      //   // background: "green !important",
      //   width: "90% !important",
      //   left: '50% !important',
      //   transform: 'translateX(-50%) !important'
      // },
      ".hd-map-control": {
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        borderRadius: "4px !important",
        cursor: "pointer !important",
        height: "auto !important",
        color: "white !important",
        width: "80px !important",
        backgroundColor: "#202020 !important",
      },
      ".hd-map-control-btn": {
        padding: "7px !important",
        borderRadius: "0 !important",
        backgroundColor: "#202020 !important",
        color: "white !important",
        "&:not(:first-of-type)": {
          borderTop: "1px solid #012532 !important",
          borderLeft: "none !important",
        },
      },
      ".hd-map-control-circle": {
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        borderRadius: "100px !important",
        cursor: "pointer !important",
        height: "auto !important",
        color: "white !important",
        backgroundColor: "#202020 !important",
      },
      ".mapboxgl-ctrl-group": {
        backgroundColor: "#202020 !important",
        button: {
          borderTop: "1px solid #012532 !important",
          width: "45px !important",
          height: "45px !important",
        },
      },
      ".mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M13 10h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2zm8.172 14l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z'/%3E%3C/svg%3E") !important`,
      },
      ".mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M13 10h-8v-2h8v2zm8.172 14l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z'/%3E%3C/svg%3E") !important`,
      },
      ".mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon": {
        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg width='34' height='34' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='white'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E") !important`,
      },
      ".mapbox-location-marker": {
        display: "block !important",
        width: "22px !important",
        height: "22px !important",
        borderRadius: "50% !important",
        border: "2px solid white !important",
        backgroundColor: "#0082ff !important",
        cursor: "pointer !important",
        boxShadow: "0 0 0 rgba(0,130,255, 0.4) !important",
      },
      ".ReactModal__Overlay": {
        zIndex: "9999999 !important",
      },
    }),
  },
})
