var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wp4mKOOxNAS8ivkQOavn2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  enabled: process.env.ENV !== "development",
  environment: process.env.ENV,
  dsn: process.env.SENTRY_DSN,
  // tracesSampleRate: process.env.ENV !== 'development' ? 0.001 : 1.0,
});
