import isEqual from "lodash/isEqual"
import { subDays } from "date-fns/subDays"
import { action, computed, makeAutoObservable } from "mobx"
import { enableStaticRendering } from "mobx-react-lite"
import { RangeFilter, SortFilter, SortOrder } from "~/generated/graphql"
import { FilterCategory } from "../mapFilters"
import { store, updateMapQueryParams } from "../StoreProvider"

enableStaticRendering(typeof window === "undefined")

export type RecentlySoldFilters = {
  page: number
  soldPrice: RangeFilter
  soldDate: SoldDateOption
  sort: SortOption
}

const now = new Date()

export enum SoldDateOption {
  NINETY_DAYS = "NINETY_DAYS",
  ONE_HUNDRED_AND_EIGHTY_DAYS = "ONE_HUNDRED_AND_EIGHTY_DAYS",
  YEAR_TO_DATE = "YEAR_TO_DATE",
  LAST_CALENDAR_YEAR = "LAST_CALENDAR_YEAR",
}

export const getSoldDateValue = (soldDate: SoldDateOption): RangeFilter => {
  switch (soldDate) {
    case SoldDateOption.NINETY_DAYS:
      return {
        gte: subDays(now, 90).toISOString(),
        lte: now.toISOString(),
      }
    case SoldDateOption.ONE_HUNDRED_AND_EIGHTY_DAYS:
      return {
        gte: subDays(now, 180).toISOString(),
        lte: now.toISOString(),
      }
    case SoldDateOption.YEAR_TO_DATE:
      return {
        gte: new Date(now.getFullYear(), 0, 1).toISOString(),
        lte: now.toISOString(),
      }
    case SoldDateOption.LAST_CALENDAR_YEAR:
      return {
        gte: new Date(now.getFullYear() - 1, 0, 1).toISOString(),
        lte: new Date(now.getFullYear(), 0, 1).toISOString(),
      }
  }
}

export const soldDateOptions: Record<SoldDateOption, any> = {
  [SoldDateOption.NINETY_DAYS]: { label: "Last 90 days" },
  [SoldDateOption.ONE_HUNDRED_AND_EIGHTY_DAYS]: { label: "Last 180 days" },
  [SoldDateOption.YEAR_TO_DATE]: { label: "Year to date" },
  [SoldDateOption.LAST_CALENDAR_YEAR]: { label: "Last calendar year" },
}

export enum SortOption {
  MOST_RECENT = "MOST_RECENT",
  OLDEST = "OLDEST",
  MOST_EXPENSIVE = "MOST_EXPENSIVE",
  LEAST_EXPENSIVE = "LEAST_EXPENSIVE",
}

export const sortOptions: Record<SortOption, SortFilter> = {
  [SortOption.MOST_RECENT]: { field: "closeDate", order: SortOrder.Desc },
  [SortOption.OLDEST]: { field: "closeDate", order: SortOrder.Asc },
  [SortOption.MOST_EXPENSIVE]: { field: "closePrice", order: SortOrder.Desc },
  [SortOption.LEAST_EXPENSIVE]: { field: "closePrice", order: SortOrder.Asc },
}

export const defaultRecentlySoldFilters: RecentlySoldFilters = {
  page: 1,
  soldDate: SoldDateOption.ONE_HUNDRED_AND_EIGHTY_DAYS,
  soldPrice: { gte: null, lte: null },
  sort: SortOption.MOST_RECENT,
}

export class RecentlySoldFiltersStore {
  page: number = defaultRecentlySoldFilters.page
  soldDate: SoldDateOption = defaultRecentlySoldFilters.soldDate
  soldPrice: RangeFilter = defaultRecentlySoldFilters.soldPrice
  sort: SortOption = defaultRecentlySoldFilters.sort

  constructor() {
    makeAutoObservable(this, {
      setPage: action,
      setSoldDate: action,
      setSoldPrice: action,
      setSort: action,
      setFilters: action,
      resetFilters: action,
      resetDisabled: computed,
      filters: computed,
      isFiltered: computed,
      isDisabled: computed,
      activeFiltersCount: computed,
      hydrate: action,
    })
  }

  setPage = (page: number): void => {
    this.page = page
    updateMapQueryParams(
      { filters: { recentlySold: { page } } },
      { skipRouter: true, scroll: true },
    )
  }

  setSoldDate = (soldDate: SoldDateOption): void => {
    this.page = 1
    this.soldDate = soldDate
    updateMapQueryParams(
      { filters: { recentlySold: { soldDate, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setSoldPrice = (soldPrice: RangeFilter): void => {
    this.page = 1
    this.soldPrice = soldPrice
    updateMapQueryParams(
      { filters: { recentlySold: { soldPrice, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setSort = (sort: SortOption): void => {
    this.page = 1
    this.sort = sort
    updateMapQueryParams(
      { filters: { recentlySold: { sort, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setFilters = (filters: { soldDate?: SoldDateOption }): void | Promise<boolean> =>
    updateMapQueryParams({ filters: { recentlySold: { ...filters, page: 1 } } })

  resetFilters = (): void => {
    this.page = 1
    this.soldDate = defaultRecentlySoldFilters.soldDate
    this.soldPrice = defaultRecentlySoldFilters.soldPrice

    updateMapQueryParams(
      {
        filters: {
          recentlySold: {
            page: defaultRecentlySoldFilters.page,
            soldDate: defaultRecentlySoldFilters.soldDate,
            soldPrice: defaultRecentlySoldFilters.soldPrice,
          },
        },
      },
      { skipRouter: true, scroll: true },
    )
  }

  get filters(): Omit<Omit<RecentlySoldFilters, "sort">, "soldDate"> & {
    sort: SortFilter
    soldDate: RangeFilter
  } {
    return {
      page: this.page - 1,
      soldDate: getSoldDateValue(this.soldDate),
      soldPrice: this.soldPrice,
      sort: sortOptions[this.sort],
    }
  }

  get isFiltered(): boolean {
    return store?.mapFiltersStore.filterCategory === FilterCategory.RecentlySold
  }

  get resetDisabled(): boolean {
    return (
      this.soldDate === defaultRecentlySoldFilters.soldDate &&
      this.soldPrice.gte === defaultRecentlySoldFilters.soldPrice.gte &&
      this.soldPrice.lte === defaultRecentlySoldFilters.soldPrice.lte
    )
  }

  get isDisabled(): boolean {
    return (
      store?.creaFiltersStore.isFiltered &&
      store?.mapFiltersStore.filterCategory === FilterCategory.ForSale
    )
  }

  get activeFiltersCount(): number {
    return Object.keys(this.filters).reduce((count, key) => {
      if (["sort", "page"].includes(key)) return count
      if (key === "soldDate")
        return isEqual(this.filters[key], getSoldDateValue(defaultRecentlySoldFilters[key]))
          ? count
          : count + 1
      return isEqual(this.filters[key], defaultRecentlySoldFilters[key]) ? count : count + 1
    }, 0)
  }

  hydrate = (data: this): void => {
    if (!data) return

    if (data.page) {
      this.page = data.page
    }

    if (data.soldDate) {
      this.soldDate = data.soldDate
    }

    if (data.soldPrice) {
      this.soldPrice = data.soldPrice
    }

    if (data.sort) {
      this.sort = data.sort
    }
  }
}
