import { FC, useMemo } from "react"
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react"
import { IoMdCopy } from "react-icons/io"
import { useRouter } from "next/router"
import { HiShare } from "react-icons/hi"
import { ISocialLinksProps, SocialLinks } from "components/modules"
import { ButtonVariants } from "~/theme/components"
import { CreaListing, EsProperty } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"
import gtag from "~/utils/lib/gtag"

export const generateSharingUrls = (url: string): Record<string, string> => ({
  facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
  twitter: `https://twitter.com/intent/tweet?text=${url}`,
  mail: `mailto:?&subject=Check%20out%20this%20blog%20post%20by%20HonestDoor&body=${url}`,
})

type ShareButtonProps = ISocialLinksProps & {
  board?: ValueOf<typeof PROPERTY_BOARDS>
  creaListing?: CreaListing
  property?: EsProperty
}

const buildCreaMapUrl = (c: CreaListing): string => {
  if (!c || !c?.location) {
    return null
  }
  const strlon = `${c.location.lon}`
  const strlat = `${c.location.lat}`
  const encodedState = encodeURI(
    `{"view":"MAP","viewport":{"center":[${strlon},${strlat}],"zoom":[16]},"highlightedFeatures":[${c.meta.id}]}`,
  )
  const encodedPageModal = encodeURI(
    `{"type":"CREA_LISTING","isOpen":true,"id":"${c.meta.id}","slug":"${c.slug}"}`,
  )
  const queryString = `/map?state=${encodedState}&pageModal=${encodedPageModal}`
  return `https://honestdoor.com${queryString}`
}

const buildPropertyMapUrl = (p: EsProperty): string => {
  if (!p) {
    return null
  }
  const strlon = `${p.location?.lon}`
  const strlat = `${p.location?.lat}`
  const encodedState = encodeURI(
    `{"view":"MAP","viewport":{"center":[${strlon},${strlat}],"zoom":[16]},"highlightedFeatures":["${p.id}"]}`,
  )
  const encodedPageModal = encodeURI(
    `{"type":"PROPERTY","isOpen":true,"id":"${p.id}","slug":"${p.slug}"}`,
  )
  const queryString = `/map?state=${encodedState}&pageModal=${encodedPageModal}`
  return `https://honestdoor.com${queryString}`
}

export const ShareButton: FC<ShareButtonProps> = ({
  board,
  creaListing,
  property,
  networkNames,
  dark,
  urls,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const router = useRouter()
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)")
  const thisPageUrl = `https://honestdoor.com${router.asPath}`

  const urlToCopy = useMemo(() => {
    if (creaListing) {
      return buildCreaMapUrl(creaListing)
    }
    if (property) {
      return buildPropertyMapUrl(property)
    }

    return thisPageUrl
  }, [creaListing, property])

  const urlToShare = creaListing
    ? `https://honestdoor.com/listing/${creaListing.slug}`
    : property
      ? `https://honestdoor.com/listing/${property.slug}`
      : thisPageUrl

  return (
    <>
      <Button
        data-cy="share-button"
        variant={ButtonVariants.Link}
        textDecoration={"underline"}
        leftIcon={<HiShare />}
        onClick={() => {
          if (board && board === PROPERTY_BOARDS.UNLISTED) {
            gtag.event({
              action: "Property_share_button",
            })
          }

          if (typeof navigator != "undefined") {
            if (navigator.share && isSmallerThan600) {
              navigator
                .share({
                  title: "honestdoor.com",
                  text: "Check out this page on HonestDoor",
                  url: urlToShare,
                })
                .catch()
            } else {
              onOpen()
            }
          }
        }}
      >
        Share
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
        <ModalOverlay />
        <ModalContent w={"250px"} data-cy="share-button-modal">
          <ModalHeader>Share this Page</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box as={"dl"}>
              <Box as={"dt"} mb={"8px"}>
                Share Link Via
              </Box>
              <Flex w={"100%"} justify={"center"}>
                <Flex as={"dd"} mb={"15px"}>
                  <SocialLinks networkNames={networkNames} dark={dark} urls={urls} />
                </Flex>
              </Flex>
              <Box // if there is no navigator clipboard then just don't show this part
                display={
                  typeof navigator != "undefined" && typeof navigator?.clipboard != "undefined"
                    ? "block"
                    : "none"
                }
                w={"100%"}
              >
                <Box as={"dt"} mb={"8px"}>
                  Or Copy Link
                </Box>
                <Flex as={"dd"} w={"100%"} justify={"center"} align={"center"} mb={"15px"}>
                  <Button
                    leftIcon={<IoMdCopy fontSize={24} />}
                    variant={ButtonVariants.BlackRounded}
                    onClick={() => {
                      typeof navigator != "undefined" &&
                        typeof navigator.clipboard != "undefined" &&
                        navigator.clipboard.writeText(urlToCopy ? urlToCopy : thisPageUrl)

                      toast.closeAll()
                      toast({
                        title: "Copied to clipboard!",
                        position: "top",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      })
                      onClose()
                    }}
                  >
                    Copy
                  </Button>
                </Flex>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
