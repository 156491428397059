const determineImageExtension = (type: string): string => {
  switch (type) {
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'png';
    case 'image/webp':
      return 'webp';
    default:
      return 'jpeg'; // Default extension if type is not recognized
  }
};

export const compressImageFile = async (
  file: File,
  {
    quality = 1,
    type = 'image/webp',
    maxWidth,
    maxHeight,
  } : {
    quality?: number,
    type?: string,
    maxWidth?: number,
    maxHeight?: number,
  }
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Unable to get canvas context'));
      return;
    }

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (maxWidth && width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (maxHeight && height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas to Blob conversion failed'));
          return;
        }
        const newFile = new File(
          [blob],
          `${file.name.replace(/\.[^/.]+$/, '')}.${determineImageExtension(type)}`,
          { type: type || file.type, lastModified: Date.now() });
        resolve(newFile);
      }, type, quality);
    };

    img.onerror = () => {
      reject(new Error('Image loading error'));
    };

    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') {
        img.src = e.target.result;
      }
    };
    reader.onerror = () => {
      reject(new Error('FileReader error'));
    };

    reader.readAsDataURL(file);
  });
}
