import { observer } from "mobx-react-lite"
import { useRouter } from "next/router"
import InternalLink from "~/components/elements/InternalLink"
import { IconHonestdoorFull } from "~/components/icons"
import { useMapStore } from "~/store/StoreProvider"
import { MapView } from "~/store/map"

import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Img,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"

import MailChimpForm from "../MailChimpForm"
import { SocialLinks } from "../social-links"

const links = [
  {
    title: "Core",
    links: [
      { label: "Home", href: "/" },
      { label: "Blog", href: "/blog" },
      { label: "Press", href: "/press" },
      { label: "About Us", href: "/about" },
      { label: "For Business", href: "/for-business" },
      { label: "List a Property", href: "/sell-with-honestdoor" },
    ],
  },
  {
    title: "Browse",
    links: [
      { label: "Map", href: "/map" },
      { label: "Real Estate Listings", href: "/real-estate" },

      // Disable for now
      // { label: "Private Listings", href: "/listings" },
      { label: "City Statistics", href: "/cities" },
      { label: "Property Permits", href: "/permits" },
      { label: "Recently Sold Properties", href: "/recently-sold" },
    ],
  },

  {
    title: "Legal",
    links: [
      { label: "Terms of Use", href: "/terms" },
      { label: "Privacy Policy", href: "/privacy" },
    ],
  },
]

const Footer = () => {
  const router = useRouter()
  const { view } = useMapStore()

  if (router.pathname === "/map" && view === MapView.MAP) return null

  return (
    <Box bgColor="hdBlack.500">
      <Container
        as="footer"
        maxW={"container.full"}
        px={{
          base: 5,
          lg: 10,
        }}
      >
        <Stack
          spacing={{ base: "12", md: "8" }}
          direction={{ base: "column", lg: "row" }}
          py={{ base: "12", md: "16" }}
          justify="space-between"
        >
          <Flex direction={"column"} justify={"space-between"}>
            <Stack spacing="4">
              <Text fontSize="lg" fontWeight="semibold" color="gray.300">
                Sign up for our newsletter
              </Text>
              <MailChimpForm />
            </Stack>
            <Text
              maxW={{
                base: "full",
                lg: 500,
                xl: 700,
              }}
              fontSize={12}
              color={"whiteAlpha.500"}
              display={{
                base: "none",
                lg: "block",
              }}
            >
              REALTOR®, REALTORS®, and the REALTOR® logo are certification marks that are owned by
              REALTOR® Canada Inc. and licensed exclusively to The Canadian Real Estate Association
              (CREA). These certification marks identify real estate professionals who are members
              of CREA and who must abide by CREA&apos;s By-Laws, Rules, and the REALTOR® Code. The
              MLS® trademark and the MLS® logo are owned by CREA and identify the quality of
              services provided by real estate professionals who are members of CREA.
            </Text>
          </Flex>
          <SimpleGrid columns={{ base: 2, md: 3 }} gap={16} width={{ base: "full", lg: "auto" }}>
            {links.map((group, idx) => (
              <Stack key={idx} spacing="4" minW={{ lg: "40" }}>
                <Text fontSize="sm" fontWeight="semibold" color="gray.400">
                  {group.title}
                </Text>
                <Stack spacing="3" shouldWrapChildren>
                  {group.links.map((link, idx) => (
                    <InternalLink
                      key={idx}
                      href={link.href}
                      linkProps={{
                        color: "gray.300",
                        fontWeight: "medium",
                      }}
                    >
                      {link.label}
                    </InternalLink>
                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Text
          maxW={{
            base: "full",
            lg: 700,
          }}
          fontSize={12}
          color={"whiteAlpha.500"}
          display={{
            base: "block",
            lg: "none",
          }}
          mb={5}
        >
          REALTOR®, REALTORS®, and the REALTOR® logo are certification marks that are owned by
          REALTOR® Canada Inc. and licensed exclusively to The Canadian Real Estate Association
          (CREA). These certification marks identify real estate professionals who are members of
          CREA and who must abide by CREA&apos;s By-Laws, Rules, and the REALTOR® Code. The MLS®
          trademark and the MLS® logo are owned by CREA and identify the quality of services
          provided by real estate professionals who are members of CREA.
        </Text>
        <Divider borderColor={"gray.500"} />
        <Stack
          pb="12"
          pt="8"
          justify="space-between"
          direction={{ base: "column", md: "row" }}
          align={{ base: "start", md: "center" }}
        >
          <HStack
            justify={{ base: "space-between", sm: "start" }}
            width={{ base: "full", sm: "auto" }}
            spacing="8"
            align={"center"}
          >
            <Img as={IconHonestdoorFull} h="100%" w={"200px"} light={true} pt={3} />
            <SocialLinks networkNames={["facebook", "linkedin", "instagram", "twitter"]} />
          </HStack>
          <Text fontSize="sm" color="gray.200">
            &copy; {new Date().getFullYear()} HonestDoor, Inc. All rights reserved.
          </Text>
        </Stack>
      </Container>
    </Box>
  )
}

export default observer(Footer)
