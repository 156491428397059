import { FC, PropsWithChildren } from "react"
import { Box } from "@chakra-ui/react"

export const StickyToolbar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      bgColor="white"
      w="full"
      py={[2, 2, 3]}
      borderBottomWidth="1px"
      borderTopWidth="1px"
      shadow="md"
      position="sticky"
      zIndex={999}
      top={0}
      data-cy="mainshell-toolbar"
    >
      <Box maxWidth="1600px" marginX={[6, 6, 20, 20, 40]}>
        {children}
      </Box>
    </Box>
  )
}
