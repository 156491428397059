import * as Yup from "yup"
import { provinces } from "~/utils/constants"

export const VALIDATION_REGEX = {
  LOCATION: /^[a-z0-9-]+$/,
  PHONE:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
}
Yup.addMethod<Yup.StringSchema>(Yup.string, "provinceAbbr", function () {
  return this.test("provinceAbbr", "Must be a valid province abbr", value => {
    return !(!VALIDATION_REGEX.LOCATION.test(value) || !provinces[value.toUpperCase()])
  })
})

Yup.addMethod<Yup.StringSchema>(Yup.string, "city", function () {
  return this.test("city", "Must be a valid city", value => {
    return !!VALIDATION_REGEX.LOCATION.test(value)
  })
})

Yup.addMethod<Yup.StringSchema>(Yup.string, "neighbourhood", function () {
  return this.test("neighbourhood", "Must be a valid neighbourhood", value => {
    return !!VALIDATION_REGEX.LOCATION.test(value)
  })
})

Yup.addMethod<Yup.StringSchema>(Yup.string, "page", function () {
  return this.when({
    is: exist => !!exist,
    then: schema => schema.matches(/^[0-9]+$/),
    otherwise: schema => schema.notRequired(),
  })
})

Yup.addMethod<Yup.StringSchema>(Yup.string, "sort", function (sortingObject) {
  return this.when({
    is: exist => !!exist,
    then: schema => schema.oneOf(sortingObject),
    otherwise: schema => schema.notRequired(),
  })
})

Yup.addMethod<Yup.StringSchema>(Yup.string, "phoneNumber", function () {
  return this.min(10).test("phoneNumber", "Phone number is not valid", value => {
    return value?.replace(/\D/g, "").length <= 10 && Boolean(value?.match(VALIDATION_REGEX.PHONE))
  })
})

export { Yup }
