import { ComponentPropsWithRef, FC, useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { Button, UseToastOptions, useToast, ButtonProps } from "@chakra-ui/react"
import { AiOutlineRise } from "react-icons/ai"
import { FiArrowUpRight } from "react-icons/fi"
import theme from "~/theme"
import { ButtonVariants } from "~/theme/components"
import { WatchPropertyModal } from "~/components/modules"
import { formatStreet } from "~/utils/lib/string"
import { usePropertyPageStore } from "~/store/StoreProvider"
import {
  useUser,
  useWatchProperty,
  useModalDisclosure,
  useStorage,
  useAuth,
} from "~/components/hooks"
import { EsProperty } from "~/generated/graphql"
import { useGetMyNotificationSubscriptionsQuery } from "~/generated/graphql"

interface IWatchButtonProps extends ComponentPropsWithRef<any> {
  property: EsProperty
  unwatchedText?: string
  watchedText?: string
  variant?: "cta" | "listing"
  onClick?: () => void
  buttonProps?: ButtonProps
}

const _WatchPriceButton: FC<IWatchButtonProps> = ({
  property,
  watchedText = "You are watching this property",
  unwatchedText = "Watch the HonestDoor price",
  variant,
  onClick,
  buttonProps: customButtonProps,
}) => {
  const { signIn } = useAuth()
  const { isOpen, onOpen, onClose, hasOpened } = useModalDisclosure()
  const { getItem } = useStorage()
  const [email, setEmail] = useState(getItem("email") || "")
  const { watching, setWatching } = usePropertyPageStore()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { user } = useUser()
  const { unparsedAddress } = property
  const { data } = useGetMyNotificationSubscriptionsQuery({}, { enabled: !!user })
  const isSubscribed =
    data?.getMyNotificationSubscriptions?.filter(sub => sub?.propertyId === property.id).length > 0

  const successToastOptions = {
    title: (
      <>
        <span>{`You will now receive HonestDoor price updates for`}</span>
        <span>{` ${formatStreet(unparsedAddress)}`}</span>
      </>
    ),
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top",
  } as UseToastOptions

  const { watch, loading } = useWatchProperty({
    property,
    email: user?.email || email,
    onSuccess: async () => {
      if (isOpen) {
        onClose()
      }
      toast(successToastOptions)
      setWatching(true)
      await queryClient.invalidateQueries(["getUser"])
    },
  })

  useEffect(() => () => setWatching(false), [])

  if (!property) {
    return null
  }

  const buttonProps: ButtonProps = {
    onClick: () => {
      if (onClick) onClick()
      user ? watch() : onOpen()
    },
    isDisabled: isSubscribed || watching,
    size: "sm",
    w: "fit-content",

    // Default
    ...(!variant && {
      variant: undefined,
      color: "gray.900",
      paddingTop: "20px",
      paddingBottom: "20px",
      leftIcon: <AiOutlineRise color={theme.colors.gold["500"]} size={20} />,
    }),

    // Call to action section
    ...(variant &&
      variant === "cta" && {
        variant: ButtonVariants.Link,
        rightIcon: <FiArrowUpRight size={20} />,
        fontSize: "16px",
      }),

    // Public Listing
    ...(variant &&
      variant === "listing" && {
        variant: ButtonVariants.Primary,
        leftIcon: <AiOutlineRise size={20} />,
        paddingTop: "19px",
        paddingBottom: "19px",
      }),
    ...customButtonProps,
  }

  return (
    <>
      <Button {...buttonProps}>{watching || isSubscribed ? watchedText : unwatchedText}</Button>

      {hasOpened && (
        <WatchPropertyModal
          {...{
            email,
            setEmail,
            isOpen,
            onClose,
            onSubmit: watch,
            onSignUp: signIn,
            loading,
          }}
        />
      )}
    </>
  )
}
export const WatchPriceButton = observer(_WatchPriceButton)
