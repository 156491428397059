import { FC } from "react"
import { LinkList } from "components/modules"
import { capitalizeAll, createUrl } from "~/utils"

interface INeighbourhoodLinks {
  neighbourhoods: any
  provinceAbbr: string
  cityName: string
}

export const NeighbourhoodLinks: FC<INeighbourhoodLinks> = ({
  neighbourhoods,
  provinceAbbr,
  cityName,
}) => {
  const inAlphabeticalOrder = (a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  }

  const startsWithNumber = neighbourhoods.filter(n => !isNaN(Number(n.name[0])))
  const startsWithLetter = neighbourhoods.filter(n => isNaN(Number(n.name[0])))

  const neighbourhoodsWithNumbersLast = [
    ...startsWithLetter.sort(inAlphabeticalOrder),
    ...startsWithNumber.sort(inAlphabeticalOrder),
  ]

  return (
    <LinkList
      categoryDir="horizontal"
      items={[
        {
          category: `Neighbourhoods in ${capitalizeAll(cityName)}`,
          links: neighbourhoodsWithNumbersLast.map(n => {
            return {
              label: n.name,
              href: createUrl({
                variant: "cities",
                provinceAbbr,
                city: cityName,
                neighbourhood: n.name,
              }),
            }
          }),
        },
      ]}
    />
  )
}
