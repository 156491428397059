import { FC, useState } from "react"
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FaGoogle } from "react-icons/fa"
import { useAuth } from "~/components/hooks"
import { Yup } from "~/utils/validation"
import { useRouter } from "next/router"

interface ILoginBox {
  callbackUrl?: string
  onSuccess?: () => void
}

export const LoginBox: FC<ILoginBox> = ({ onSuccess, callbackUrl: cbURL }) => {
  const { signIn } = useAuth()
  const router = useRouter()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const href = typeof window !== "undefined" ? window.location.href : router.asPath
  const callbackUrl = cbURL ? cbURL : href

  return (
    <Stack spacing="8">
      <Stack spacing="6">
        <Stack spacing="3">
          <Button
            variant={"outline"}
            leftIcon={<Icon as={FaGoogle} />}
            iconSpacing="3"
            onClick={() => signIn("google", { callbackUrl })}
          >
            Continue with Google
          </Button>
          {/*<Button*/}
          {/*  variant="outline"*/}
          {/*  leftIcon={<FaFacebook />}*/}
          {/*  iconSpacing="3"*/}
          {/*  onClick={() => signIn("facebook", { callbackUrl })}*/}
          {/*>*/}
          {/*  Continue with Facebook*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  variant="outline"*/}
          {/*  leftIcon={<FaApple />}*/}
          {/*  iconSpacing="3"*/}
          {/*  onClick={() => signIn("apple", { callbackUrl })}*/}
          {/*>*/}
          {/*  Continue with Apple*/}
          {/*</Button>*/}
          {/* <Button
            variant={"outline"}
            leftIcon={<FaLinkedin />}
            iconSpacing="3"
            onClick={() => signIn("linkedin", { callbackUrl })}
            disabled
          >
            Continue with LinkedIn
          </Button> */}
        </Stack>
        <HStack>
          <Divider />
          <Text fontSize="sm" color="muted">
            OR
          </Text>
          <Divider />
        </HStack>

        <Stack spacing="4">
          <Formik
            initialValues={{ email: "" }}
            onSubmit={async (v, { resetForm }) => {
              try {
                setIsLoading(true)
                const res = await signIn("email", {
                  redirect: false,
                  email: v.email,
                  callbackUrl,
                })
                if (res.ok) {
                  toast({
                    title: "A sign-in link has been sent to your email address",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                  })
                  resetForm()
                  if (onSuccess) {
                    onSuccess()
                  }
                } else {
                  toast({
                    title: res.error,
                    status: "error",
                    duration: 5000,
                    position: "top-right",
                  })
                }
                setIsLoading(false)
              } catch (e) {
                console.log("Error", e)
                setIsLoading(false)
                toast({
                  title: "Something went wrong, please try again",
                  status: "error",
                  duration: 5000,
                  position: "top-right",
                })
              }
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required(),
            })}
            validateOnChange={false}
          >
            {({ submitForm, getFieldProps, touched, errors }) => (
              <>
                <FormControl label="email" isInvalid={errors.email && touched.email}>
                  <Input
                    {...getFieldProps("email")}
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    onKeyDown={(e) => e.key === 'Enter' ? submitForm() : false}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <Button colorScheme={"hdGold"} onClick={submitForm} isLoading={isLoading}>
                  Continue with email
                </Button>
              </>
            )}
          </Formik>
        </Stack>
      </Stack>
      <HStack spacing="1" justify="center" verticalAlign={"middle"}>
        <Text fontSize="sm">
          Having issues?&nbsp;
          <span>
            <Button
              as={Link}
              variant="link"
              colorScheme="blue"
              size="sm"
              href="mailto:info@honestdoor.com"
            >
              Contact us
            </Button>
          </span>
        </Text>
      </HStack>
    </Stack>
  )
}
