import { Children, FC, PropsWithChildren } from "react"
import {
  Swiper as SwiperComponent,
  SwiperProps,
  SwiperSlide as SwiperSlideComponent,
} from "swiper/react"
import { Navigation, EffectFade, Keyboard, Autoplay } from "swiper/modules"

import "swiper/css"
import "swiper/css/navigation"

export const Swiper: FC<PropsWithChildren<SwiperProps>> = ({ children, ...rest }) => {
  return (
    <SwiperComponent
      modules={[Navigation, EffectFade, Keyboard, Autoplay]}
      keyboard={{ enabled: true }}
      {...rest}
    >
      {Children.toArray(children).map(child => child)}
    </SwiperComponent>
  )
}
export const SwiperSlide = SwiperSlideComponent
