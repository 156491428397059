import { default as React } from "react"
import { IconProps } from "./types"
import { chakra } from "@chakra-ui/react"

export const IconHonestdoorFull = (props: IconProps): JSX.Element => {
  const { light = false, ...restProps } = props

  return (
    <chakra.svg viewBox="0 0 220 37" {...restProps}>
      <g id="Filters" fill="none" fillRule="evenodd">
        <g id="Filter---tablet" transform="translate(-19 -24)">
          <g id="Header">
            <g id="Logo" transform="translate(19 24)">
              <path
                id="Fill-1"
                fill="#C4985F"
                d="M36.532 14.987c0 8.278-6.706 14.988-14.979 14.988-8.272 0-14.978-6.71-14.978-14.988C6.575 6.71 13.28 0 21.553 0c8.273 0 14.98 6.71 14.98 14.987"
              />
              <path
                id="Fill-3"
                fill="#FFF"
                d="M28.58 12.406l-6.058-5.478a1.44 1.44 0 0 0-.969-.371 1.44 1.44 0 0 0-.968.371l-6.058 5.478a1.4 1.4 0 0 0-.463 1.038v6.69c0 .78.64 1.41 1.431 1.41h3.25v-2.81a2.818 2.818 0 0 1 2.808-2.81 2.818 2.818 0 0 1 2.809 2.81v2.81h3.25c.79 0 1.43-.63 1.43-1.41v-6.69a1.4 1.4 0 0 0-.462-1.038"
              />
              <g id="Group-7" transform="translate(0 26.25)">
                <path id="Clip-6" d="M0 .332h9.966v9.972H0z" />
                <path
                  id="Fill-5"
                  fill="#C4985F"
                  d="M6.852.332L.92 5.56a2.711 2.711 0 0 0 0 4.068c1.122.989 2.834.88 3.823-.243l5.224-5.937A18.865 18.865 0 0 1 6.852.332"
                />
              </g>
              <path
                id="Fill-8"
                fill={light ? "#FFF" : "#000"}
                d="M50.893 7.494h2.958v5.47h5.935v-5.47h2.959v13.114h-2.959v-5.171h-5.935v5.17h-2.958z"
              />
              <path
                id="Fill-10"
                fill={light ? "#FFF" : "#000"}
                d="M72.742 10.425a4.036 4.036 0 0 0-1.479 1.509 4.17 4.17 0 0 0-.543 2.098c0 .774.184 1.48.553 2.117a4.202 4.202 0 0 0 1.479 1.517 3.81 3.81 0 0 0 2.013.562c.723 0 1.388-.184 1.994-.552a4.01 4.01 0 0 0 1.441-1.518 4.318 4.318 0 0 0 .534-2.126c0-.762-.178-1.461-.534-2.098a4.035 4.035 0 0 0-1.441-1.509 3.767 3.767 0 0 0-1.994-.552c-.725 0-1.399.184-2.023.552m5.561-2.257a6.587 6.587 0 0 1 2.537 2.426c.618 1.03.927 2.177.927 3.438a6.628 6.628 0 0 1-3.464 5.892c-1.073.593-2.265.89-3.576.89-1.31 0-2.503-.297-3.576-.89a6.649 6.649 0 0 1-2.537-2.436c-.618-1.03-.927-2.182-.927-3.456 0-1.261.31-2.408.927-3.438a6.604 6.604 0 0 1 2.528-2.426c1.067-.587 2.262-.88 3.585-.88 1.31 0 2.503.293 3.576.88"
              />
              <path
                id="Fill-12"
                fill={light ? "#FFF" : "#000"}
                d="M95.398 7.494h2.808v13.114H95.51l-6.347-8.43v8.43h-2.827V7.494h2.696l6.366 8.468z"
              />
              <path
                id="Fill-14"
                fill={light ? "#FFF" : "#000"}
                d="M105.209 7.494h9.98v2.454h-7.022v2.847h6.31v2.455h-6.31v2.903h7.227v2.455H105.21z"
              />
              <path
                id="Fill-16"
                fill={light ? "#FFF" : "#000"}
                d="M128.697 10.163c-.755-.243-1.401-.365-1.937-.365-.513 0-.918.094-1.218.281a.913.913 0 0 0-.449.824c0 .463.215.815.646 1.059.43.243 1.095.496 1.994.759.899.262 1.638.524 2.219.786.58.263 1.076.656 1.488 1.18.412.525.618 1.218.618 2.08 0 .837-.221 1.558-.664 2.164-.444.606-1.052 1.065-1.826 1.377-.774.312-1.654.468-2.64.468a9.03 9.03 0 0 1-3.127-.562c-1.023-.374-1.91-.886-2.659-1.536l1.18-2.417c.661.6 1.423 1.08 2.284 1.443.862.362 1.648.543 2.36.543.61 0 1.088-.112 1.432-.337.343-.225.515-.55.515-.974 0-.337-.116-.616-.347-.834a2.65 2.65 0 0 0-.88-.534 17.229 17.229 0 0 0-1.47-.468c-.898-.25-1.632-.5-2.2-.75a3.864 3.864 0 0 1-1.47-1.142c-.411-.512-.617-1.193-.617-2.042 0-.787.212-1.477.636-2.07.425-.593 1.018-1.05 1.78-1.368.76-.319 1.647-.478 2.658-.478.899 0 1.785.131 2.659.394a9.002 9.002 0 0 1 2.321 1.049l-1.142 2.454a9.951 9.951 0 0 0-2.144-.984"
              />
              <path
                id="Fill-18"
                fill={light ? "#FFF" : "#000"}
                d="M137.338 7.494h10.953v2.51h-4.007v10.604h-2.958V10.004h-3.988z"
              />
              <path
                id="Fill-20"
                fill={light ? "#FFF" : "#000"}
                d="M161.969 17.6a3.573 3.573 0 0 0 1.357-1.432c.325-.612.487-1.312.487-2.099 0-.786-.172-1.492-.515-2.117a3.706 3.706 0 0 0-1.404-1.451c-.593-.344-1.264-.516-2.013-.516h-2.546v8.131h2.677c.724 0 1.376-.171 1.957-.515m1.544-9.274a6.047 6.047 0 0 1 2.444 2.323c.58.993.87 2.127.87 3.4 0 1.275-.296 2.408-.889 3.4-.593.994-1.42 1.769-2.48 2.324-1.062.556-2.272.834-3.633.834h-5.449V7.494h5.561c1.336 0 2.528.278 3.576.833"
              />
              <path
                id="Fill-22"
                fill={light ? "#FFF" : "#000"}
                d="M175.927 10.425a4.036 4.036 0 0 0-1.48 1.509 4.17 4.17 0 0 0-.542 2.098c0 .774.184 1.48.552 2.117a4.202 4.202 0 0 0 1.48 1.517 3.81 3.81 0 0 0 2.012.562c.724 0 1.388-.184 1.994-.552a4.01 4.01 0 0 0 1.442-1.518 4.318 4.318 0 0 0 .533-2.126c0-.762-.177-1.461-.533-2.098a4.035 4.035 0 0 0-1.442-1.509 3.767 3.767 0 0 0-1.994-.552c-.724 0-1.398.184-2.022.552m5.56-2.257a6.587 6.587 0 0 1 2.538 2.426c.618 1.03.927 2.177.927 3.438a6.628 6.628 0 0 1-3.464 5.892c-1.074.593-2.266.89-3.576.89-1.311 0-2.503-.297-3.577-.89a6.649 6.649 0 0 1-2.537-2.436c-.617-1.03-.926-2.182-.926-3.456 0-1.261.309-2.408.926-3.438a6.604 6.604 0 0 1 2.528-2.426c1.067-.587 2.262-.88 3.586-.88 1.31 0 2.502.293 3.576.88"
              />
              <path
                id="Fill-24"
                fill={light ? "#FFF" : "#000"}
                d="M194.051 10.425a4.036 4.036 0 0 0-1.48 1.509 4.17 4.17 0 0 0-.542 2.098c0 .774.184 1.48.552 2.117a4.202 4.202 0 0 0 1.48 1.517 3.81 3.81 0 0 0 2.012.562c.724 0 1.388-.184 1.994-.552a4.01 4.01 0 0 0 1.442-1.518 4.318 4.318 0 0 0 .533-2.126c0-.762-.177-1.461-.533-2.098a4.035 4.035 0 0 0-1.442-1.509 3.767 3.767 0 0 0-1.994-.552c-.724 0-1.398.184-2.022.552m5.56-2.257a6.587 6.587 0 0 1 2.538 2.426c.618 1.03.927 2.177.927 3.438a6.628 6.628 0 0 1-3.464 5.892c-1.074.593-2.266.89-3.576.89-1.311 0-2.503-.297-3.576-.89a6.649 6.649 0 0 1-2.538-2.436c-.617-1.03-.926-2.182-.926-3.456 0-1.261.309-2.408.926-3.438a6.604 6.604 0 0 1 2.528-2.426c1.067-.587 2.262-.88 3.586-.88 1.31 0 2.502.293 3.576.88"
              />
              <g id="Group-28" transform="translate(208.298 7.047)">
                <path id="Clip-27" d="M.095.447h11.403v13.114H.095z" />
                <path
                  id="Fill-26"
                  fill={light ? "#FFF" : "#000"}
                  d="M3.054 7.341h2.733c.824 0 1.457-.19 1.9-.571.443-.381.665-.94.665-1.677 0-.725-.222-1.27-.664-1.64-.444-.367-1.077-.552-1.9-.552H3.053v4.44zm5.092 6.22L6.068 9.814H3.054v3.747H.095V.447h5.692c1.71 0 3.033.396 3.97 1.19.936.793 1.404 1.914 1.404 3.362 0 1.012-.21 1.877-.627 2.595-.419.718-1.027 1.265-1.826 1.64l2.79 4.327H8.146z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </chakra.svg>
  )
}
