import { ChangeEvent, FC, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Box, BoxProps } from "@chakra-ui/layout"
import { InputProps } from "@chakra-ui/input"
import { chakra } from "@chakra-ui/system"
import SearchBar, { TResultItem, TResults } from "~/components/elements/SearchBar"
import { createUrl, provinceLongToProvinceAbbr } from "~/utils"
import { MapView } from "~/store/map"
import { PageType } from "~/store/pageModal"
import { formatStreet } from "~/utils/lib/string"
import { useNavigation } from "~/components/hooks"
import { City, EsProperty, Neighbourhood, useMultiSearchQuery } from "~/generated/graphql"

type TCategories = "Properties" | "Cities" | "Neighbourhoods"

export enum HrefDestinations {
  RECENTLY_SOLD = "recently-sold",
  MAP = "map",
  CITIES = "cities",
  REAL_ESTATE = "real-estate",
  LISTINGS = "listings",
  PERMITS = "permits",
}

type TSearch = {
  categories?: TCategories[]
  size?: "md" | "lg"
  placeholder?: string
  inputProps?: InputProps
  onFocusChanged?: (focused: boolean) => void
  onPropertyResultClick?: any
  navigateTo?: HrefDestinations
  disabled?: boolean
  onIconClick?: () => void
  onResultItemClick?: () => void
} & BoxProps

export const Search: FC<TSearch> = ({
  categories,
  size,
  placeholder,
  inputProps,
  onFocusChanged,
  onPropertyResultClick,
  navigateTo,
  onIconClick,
  onResultItemClick,
  disabled = false,
  ...rest
}) => {
  const router = useRouter()
  const [query, setQuery] = useState<string>(null)
  const { data, isLoading } = useMultiSearchQuery({ query }, { enabled: query != null })
  const [results, setResults] = useState<TResults>(null)
  const { navigate } = useNavigation()
  const isMap = router.pathname === "/map"
  const isRealEstate = router.pathname.includes("/real-estate")
  const isLanding = router.pathname === "/"

  const handleQueryChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target?.value)
  }

  const hrefBuilder = {
    [HrefDestinations.RECENTLY_SOLD]: {
      city: city => {
        return createUrl({
          variant: "recently-sold",
          provinceAbbr: city.provinceAbbr,
          city: city.name,
        })
      },
      neighbourhood: neighbourhood => {
        return createUrl({
          variant: "recently-sold",
          provinceAbbr: neighbourhood.city.provinceAbbr,
          city: neighbourhood.cityName,
          neighbourhood: neighbourhood.name,
        })
      },
    },

    [HrefDestinations.REAL_ESTATE]: {
      city: city => {
        return createUrl({
          variant: "real-estate",
          provinceAbbr: city.provinceAbbr,
          city: city.name,
        })
      },
      neighbourhood: neighbourhood => {
        return createUrl({
          variant: "real-estate",
          provinceAbbr: neighbourhood.city.provinceAbbr,
          city: neighbourhood.cityName,
          neighbourhood: neighbourhood.name,
        })
      },
    },

    [HrefDestinations.LISTINGS]: {
      city: c => `/listings?city=${c.name}`,
    },

    [HrefDestinations.PERMITS]: {
      city: city => {
        return createUrl({
          variant: "permits",
          provinceAbbr: city.provinceAbbr,
          city: city.name,
        })
      },
      neighbourhood: neighbourhood => {
        return createUrl({
          variant: "permits",
          provinceAbbr: neighbourhood.city.provinceAbbr,
          city: neighbourhood.cityName,
          neighbourhood: neighbourhood.name,
        })
      },
    },
  }

  const handlePropertyClick = (p: EsProperty): void => {
    if (onPropertyResultClick) {
      onPropertyResultClick(p)
    } else if (p?.creaListing?.slug) {
      navigate.map({
        state: {
          view: MapView.MAP,
          viewport: {
            center: [p.creaListing.location.lon, p.creaListing.location.lat],
            zoom: [16],
          },
          highlightedFeatures: [p.creaListing.meta.id],
        },
        pageModal: {
          type: PageType.CREA_LISTING,
          isOpen: true,
          id: p.creaListing.meta.id,
          slug: p.creaListing.slug,
        },
      })
    } else if (isLanding || isMap) {
      navigate.map({
        state: {
          view: MapView.MAP,
          viewport: {
            center: [p.location.lon, p.location.lat],
            zoom: [16],
          },
          highlightedFeatures: [p.id],
        },
        pageModal: {
          type: PageType.PROPERTY,
          isOpen: true,
          id: p.id,
        },
      })
    } else {
      router.push(`/property/${encodeURIComponent(p.slug)}`.toLowerCase())
    }
  }

  useEffect(() => {
    if (data) {
      let resultOptions: TResults = []

      const { properties, cities, neighbourhoods } = data?.multiSearch || {}

      if (properties?.length && categories.includes("Properties")) {
        const propertyResults: TResultItem[] = properties.map((p: EsProperty) => {
          return {
            label: formatStreet(p.unparsedAddress),
            render: label => (
              <chakra.span alignItems="center">
                {formatStreet(label)},&nbsp;
                <chakra.span color="hdGold.full">{p.cityName}</chakra.span>
                &nbsp;
                <chakra.span color="hdGold.full">
                  {provinceLongToProvinceAbbr(p.province)}
                </chakra.span>
                {p.creaListing?.listingId ? (
                  <chakra.span
                    {...{
                      content: '"For Sale"',
                      position: "sticky",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      verticalAlign: "text-top",
                      padding: "8px",
                      marginLeft: "6px",
                      height: "16px",
                      borderRadius: "999px",
                      backgroundColor: "white",
                      border: "1px solid",
                      borderColor: "hdFire.full",
                      color: "hdFire.full",
                      fontSize: "12px",
                      lineHeight: "12px",
                      fontStyle: "italic",
                    }}
                  >
                    For Sale
                  </chakra.span>
                ) : null}
              </chakra.span>
            ),
            value: p,
            href: "",
            onClick: (p: EsProperty) => {
              if (onResultItemClick) {
                onResultItemClick()
              }
              handlePropertyClick(p)
            },
          } as TResultItem
        })

        resultOptions.push({
          category: "Properties",
          items: propertyResults,
        })
      }

      if (cities?.length && categories.includes("Cities")) {
        const cityResults: TResultItem[] = cities.map((c: City) => {
          return {
            label: c.name,
            render: label => (
              <chakra.span>
                {label},&nbsp;
                <chakra.span color="hdGold.full">{c.provinceAbbr}</chakra.span>
              </chakra.span>
            ),
            value: c,
            href: "",
            onClick: () => {
              if (onResultItemClick) {
                onResultItemClick()
              }

              navigateTo
                ? router.push(hrefBuilder[navigateTo].city(c))
                : isMap && c.centroid?.lon && c.centroid?.lat
                  ? navigate.map({
                      state: {
                        view: MapView.MAP,
                        viewport: {
                          center: [c.centroid.lon, c.centroid.lat],
                          zoom: [11],
                        },
                      },
                      filters: {
                        place: {
                          province: { abbr: c.provinceAbbr, name: c.provinceName },
                          city: c.name,
                        },
                      },
                    })
                  : isRealEstate
                    ? router.push(
                        createUrl({
                          variant: "real-estate",
                          provinceAbbr: c.provinceAbbr,
                          city: c.name,
                        }),
                      )
                    : router.push(
                        createUrl({
                          variant: "cities",
                          provinceAbbr: c.provinceAbbr,
                          city: c.name,
                        }),
                      )
            },
          } as TResultItem
        })

        resultOptions.push({
          category: "Cities",
          items: cityResults,
        })
      }

      if (neighbourhoods?.length && categories.includes("Neighbourhoods")) {
        const neighbourhoodResults: TResultItem[] = neighbourhoods.map((n: Neighbourhood) => {
          return {
            label: n.name,
            render: label => (
              <chakra.span>
                {label},&nbsp;
                <chakra.span color="hdGold.full">{n.cityName}</chakra.span>
              </chakra.span>
            ),
            value: n,
            href: "",
            onClick: () => {
              if(onResultItemClick) {
                onResultItemClick()
              }

              navigateTo
                ? router.push(hrefBuilder[navigateTo].neighbourhood(n))
                : isMap && n.centroid?.lon && n.centroid?.lat
                  ? navigate.map({
                    state: {
                      view: MapView.MAP,
                      viewport: { center: [n.centroid.lon, n.centroid.lat], zoom: [14] },
                    },
                    filters: {
                      place: {
                        province: { abbr: n.city.provinceAbbr, name: n.city.provinceName },
                        city: n.cityName,
                        neighbourhood: n.name,
                      },
                    },
                  })
                  : isRealEstate
                    ? router.push(
                      createUrl({
                        variant: "real-estate",
                        provinceAbbr: n.city.provinceAbbr,
                        city: n.cityName,
                        neighbourhood: n.name,
                      }),
                    )
                    : router.push(
                      createUrl({
                        variant: "cities",
                        provinceAbbr: n.city.provinceAbbr,
                        city: n.cityName,
                        neighbourhood: n.name,
                      }),
                    )
            },
          } as TResultItem
        })

        resultOptions.push({
          category: "Neighbourhoods",
          items: neighbourhoodResults,
        })
      }

      let order: string[]

      if (!Number(query[0])) {
        order = ["Cities", "Neighbourhoods", "Properties"]
      } else {
        order = ["Properties", "Cities", "Neighbourhoods"]
      }

      resultOptions = resultOptions.sort((a: { category: string }, b: { category: string }) => {
        if (order.indexOf(a.category) === -1) return 1
        if (order.indexOf(b.category) === -1) return -1
        return order.indexOf(a.category) - order.indexOf(b.category)
      })

      if (!resultOptions.length) {
        setResults(null)
      } else {
        setResults(resultOptions)
      }
    }
  }, [data])

  return (
    <Box {...rest} data-cy="hd-search">
      <SearchBar
        resultOptions={results}
        onChange={handleQueryChange}
        size={size}
        loading={isLoading}
        placeholder={placeholder}
        onFocusChanged={onFocusChanged}
        isDisabled={disabled}
        onIconClick={onIconClick}
        {...inputProps}
      />
    </Box>
  )
}

Search.defaultProps = {
  categories: ["Properties", "Cities", "Neighbourhoods"],
  size: "md",
} as TSearch
