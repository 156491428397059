import { ReactNode, useState } from "react"
import { useRouter } from "next/router"
import NextImg from "next/legacy/image"
import { observer } from "mobx-react-lite"
import { Box, Flex, FlexProps, HStack, LinkProps, List, ListItem } from "@chakra-ui/layout"
import { Button, ButtonProps, IconButton } from "@chakra-ui/button"
import { Icon } from "@chakra-ui/icon"
import { FaBars, FaCaretDown } from "react-icons/fa"
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@chakra-ui/popover"
import { Avatar } from "@chakra-ui/avatar"
import { Search } from "~/components/modules"
import InternalLink from "~/components/elements/InternalLink"
import { usePageModalStore } from "~/store/StoreProvider"

const flexTransparentProps: FlexProps = {
  bgColor: "transparent",
  mb: "-76px",
}

const flexSolidProps: FlexProps = {
  bgColor: "white",
}

const buttonTransparentProps = (active: boolean): ButtonProps => ({
  variant: active ? "white-rounded" : "white-rounded-ghost",
  fontWeight: "500",
})

const buttonSolidProps = (active: boolean): ButtonProps => ({
  variant: active ? "black-rounded" : "black-rounded-ghost",
  fontWeight: "500",
})

type TNavSubMenuItem = {
  key: string | number
  label: string
  description?: string
  href: string
  linkProps?: LinkProps
}

type TNavMenuItem = {
  key: string | number
  label: string | ReactNode
  href?: string
  subMenuItems?: TNavSubMenuItem[]
  buttonProps?: ButtonProps
}

type TNavbarButtonProps = {
  item: TNavMenuItem
  activeMenuItem: string | number
  transparent: boolean
}

/**
 * Item is currently specific to the Navbar,
 * but could be moved to ~/elements in the future if it's needed elsewhere.
 */
const NavbarButton = ({ item, activeMenuItem, transparent }: TNavbarButtonProps): JSX.Element => {
  /* TODO: Create new "InternalLink" utilizing Chakra "Link" component */

  return (
    <>
      {item.href ? (
        <InternalLink prefetch={false} href={item.href} passHref>
          <Button
            key={item.key}
            {...(transparent
              ? buttonTransparentProps(item.key === activeMenuItem)
              : buttonSolidProps(item.key === activeMenuItem))}
            {...item.buttonProps}
          >
            {item.label}
          </Button>
        </InternalLink>
      ) : (
        <Button
          key={item.key}
          {...(transparent
            ? buttonTransparentProps(item.key === activeMenuItem)
            : buttonSolidProps(item.key === activeMenuItem))}
          {...item.buttonProps}
        >
          {item.label}
        </Button>
      )}
    </>
  )
}

/**
 * Item is currently specific to the Navbar,
 * but could be moved to ~/elements in the future if it's needed elsewhere.
 */
const NavbarDropdown = ({ item, activeMenuItem, transparent }: TNavbarButtonProps): JSX.Element => (
  /* TODO: Create new "InternalLink" utilizing Chakra "Link" component */
  <Popover trigger="hover" placement="bottom-start">
    {item.href ? (
      <InternalLink href={item.href} passHref>
        <PopoverTrigger>
          <Button
            key={item.key}
            rightIcon={<Icon as={FaCaretDown} />}
            {...(transparent
              ? buttonTransparentProps(item.key === activeMenuItem)
              : buttonSolidProps(item.key === activeMenuItem))}
            {...item.buttonProps}
          >
            {item.label}
          </Button>
        </PopoverTrigger>
      </InternalLink>
    ) : (
      <PopoverTrigger>
        <Button
          key={item.key}
          rightIcon={<Icon as={FaCaretDown} />}
          {...(transparent
            ? buttonTransparentProps(item.key === activeMenuItem)
            : buttonSolidProps(item.key === activeMenuItem))}
          {...item.buttonProps}
        >
          {item.label}
        </Button>
      </PopoverTrigger>
    )}
    <PopoverContent w="fit-content" px="4" py="3" data-cy="navbar-dropdown">
      <PopoverArrow />
      <List spacing={2}>
        {item.subMenuItems.map(sub => (
          <ListItem key={sub.key}>
            <InternalLink href={sub.href} linkProps={sub.linkProps} passHref>
              {sub.label}
            </InternalLink>
            {sub.description && <Box color="gray">{sub.description}</Box>}
          </ListItem>
        ))}
      </List>
    </PopoverContent>
  </Popover>
)

export type TNavbarProps = {
  transparent?: boolean
  menuItems: TNavMenuItem[]
  activeMenuItem: string | number
  user?: {
    name?: string
    image?: string
  }
  onDrawerBtnClick?: () => void
  onSignInBtnClick?: () => void
}

const Navbar = (props: TNavbarProps): JSX.Element => {
  const { transparent, menuItems, activeMenuItem, user, onDrawerBtnClick, onSignInBtnClick } = props

  const [isSearching, setIsSearching] = useState<boolean>(false)

  const router = useRouter()

  const handleDrawerBtnClicked = () => onDrawerBtnClick && onDrawerBtnClick()

  const headerProps = transparent ? flexTransparentProps : flexSolidProps

  const showSearchBar = !(router.pathname === "/")

  const maxSearchBar = showSearchBar && isSearching

  const { isOpen: isModalOpen } = usePageModalStore()

  /**
   * TODO: Might need to move the Nav into the MainShell eventually so we don't have to do this here.
   *       This is a temporary solution to remove the shadow from the Navbar on MainShell pages.
   */
  const noShadowRoutes = [
    "map",
    "real-estate",
    "recently-sold",
    "listings",
    "cities",
    "permits",
  ].filter(Boolean)

  const showShadow = !transparent && !noShadowRoutes.includes(router.pathname.split("/")[1])

  return (
    <Flex
      height={["64px", "64px", "76px"]}
      w="100%"
      alignItems="center"
      px={["3", "3", "6"]}
      py="4"
      zIndex="1000"
      shadow={showShadow ? "md" : null}
      {...headerProps}
      data-cy="navbar"
    >
      <Flex
        display={[maxSearchBar ? "none" : "flex", maxSearchBar ? "none" : "flex", "flex"]}
        w={!showSearchBar && "100%"}
        h="100%"
        mr="4"
      >
        <Box h="100%" width={showSearchBar ? "44px" : "220px"} position="relative">
          <InternalLink
            href="/"
            linkProps={{
              "aria-label": "HonestDoor",
            }}
          >
            {showSearchBar ? (
              <NextImg src="/icons/hd.icon.svg" layout="fill" alt="HonestDoor" />
            ) : (
              <NextImg src="/icons/hd-full.icon.svg" layout="fill" alt="HonestDoor" />
            )}
          </InternalLink>
        </Box>
      </Flex>
      <Flex w="100%" justifyContent={showSearchBar ? "space-evenly" : "center"}>
        {showSearchBar && (
          <Search
            maxW={["100%", "100%", "400px"]}
            w="100%"
            categories={["Properties", "Cities", "Neighbourhoods"]}
            size="md"
            placeholder="Search an Address, City, or Neighbourhood"
            mr={["0", "0", "8"]}
            onFocusChanged={setIsSearching}
            disabled={isModalOpen}
            inputProps={{
              fontSize: 17,
            }}
          />
        )}
        <HStack spacing={1.5} display={["none", "none", "none", "none", "flex"]} flexWrap="nowrap">
          {menuItems.map(item => {
            if (item.subMenuItems?.length) {
              return (
                <NavbarDropdown
                  key={item.key}
                  item={item}
                  activeMenuItem={activeMenuItem}
                  transparent={transparent}
                />
              )
            } else {
              return (
                <NavbarButton
                  key={item.key}
                  item={item}
                  activeMenuItem={activeMenuItem}
                  transparent={transparent}
                />
              )
            }
          })}
        </HStack>
      </Flex>

      <Flex
        display={[maxSearchBar ? "none" : "flex", maxSearchBar ? "none" : "flex", "flex"]}
        ml="auto"
        w={!showSearchBar && "100%"}
        justifyContent="flex-end"
      >
        <HStack>
          {user ? (
            <Avatar
              // display={[showSearchBar && "none", showSearchBar && "none", "initial"]}
              display={[showSearchBar && "none", showSearchBar && "none", "flex"]}
              size="sm"
              name={user.name}
              src={user.image}
              _hover={{ cursor: "pointer" }}
              onClick={() => router.push("/profile")}
            />
          ) : (
            <Button
              colorScheme={showSearchBar ? "hdGold" : undefined}
              color={!showSearchBar ? "white" : undefined}
              _hover={
                !showSearchBar
                  ? {
                      color: "black",
                      bg: "white",
                    }
                  : null
              }
              variant={"ghost"}
              size={"sm"}
              onClick={onSignInBtnClick}
              display={{
                base: "none",
                md: "flex",
              }}
            >
              Sign in
            </Button>
          )}
          <IconButton
            aria-label="Drawer Menu"
            icon={<Icon fontSize="2xl" as={FaBars} />}
            {...(transparent ? buttonTransparentProps(false) : buttonSolidProps(false))}
            onClick={handleDrawerBtnClicked}
          />
        </HStack>
      </Flex>
    </Flex>
  )
}

Navbar.defaultProps = {
  transparent: false,
}

export default observer(Navbar)
