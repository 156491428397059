
// @TODO: enum HouseStyle
export const PROPERTY_TYPES = {
  CONDO: "Condo",
  RESIDENTIAL: "Residential",
  COMMERCIAL: "Commercial",

  HOUSE: "House",
  TOWNHOUSE: "Town House",
  DUPLEX: "Duplex",
  BUNGALOW: "Bungalow",
  MOBILE: "Mobile Home",
  LAND: "Land",
  OTHER: "Other",
}

export const MEASUREMENT_UNITS = {
  SQFT: "sqft",
  SQM: "sqm",
  ACRES: "acres",
} as const


export const MEASUREMENT_UNITS_TEXTS = {
  [MEASUREMENT_UNITS.SQFT]: "Square Feet",
  [MEASUREMENT_UNITS.SQM]: "Square Metres",
  [MEASUREMENT_UNITS.ACRES]: "Acres",
} as const


export const PROPERTY_BOARDS = {
  CREA: 'crea',
  PRIVATE: 'private',
  UNLISTED: 'unlisted'
} as const
