import { action, makeAutoObservable } from "mobx"
import { enableStaticRendering } from "mobx-react-lite"

enableStaticRendering(typeof window === "undefined")

export class PropertyPageStore {
  watching: boolean = false
  constructor() {
    makeAutoObservable(this, { setWatching: action.bound })
  }

  setWatching(watching: boolean): void {
    this.watching = watching
  }
}
