import { max, minYearBuiltActual } from "~/utils/constants/sliderMinMax"

import { Filter } from "~/components/layouts/mapLayouts/MapFilters/MapFilters.types"
import { IHDMap } from "~/components/layouts/mapLayouts/Map.types"
import { IListingSteps } from "../defaults"
import { PropertiesListParams } from "~/components/layouts/mapLayouts/PropertyList/PropertyList.types"
import deepmerge from "deepmerge"
import getConfig from "next/config"
import { makeVar } from "@apollo/client"
import { polygon } from "~/components/layouts/mapLayouts/Map.defaults"

const overwriteMerge = (_: any[], sourceArray: any[]): any[] => sourceArray

const MAPBOX_STYLE_URL =
  process.env.NODE_ENV !== "test" ? getConfig().publicRuntimeConfig.MAPBOX_STYLE_URL : ""

export const HDMapState = makeVar<IHDMap>({
  style: MAPBOX_STYLE_URL,
  center: [-101.01651362796643, 54.433364269612376] as [number, number],
  zoom: [4] as [number], // stupid ts tuples
  bounds: null,
  properties: null,
  listing: null,
  listingsData: null,
  listingsCategory: "For Sale",
  isLoading: true,
  showSatellite: false,
  userCoords: null,
  locationLoading: false,
  activeMultiPin: null,
  clickedFeature: polygon.initialState,
  selectedFeature: polygon.initialState,
  highlightedFeature: null,
  highlightedFeatures: [],
  filteredFeatures: {
    isActive: false,
    isLoading: false,
    total: 0,
    count: 0,
    geojson: null,
  },
  propertiesDataAll: [],
  popupProps: {
    coordinates: [],
    offset: [],
  },
  tooltip: {
    isActive: false,
    coordinates: null,
    featureId: null,
    propertyIds: null,
    listingIds: null,
  },
})

export const setHDMapState = (state: IHDMap): IHDMap =>
  HDMapState(deepmerge(HDMapState(), state, { arrayMerge: overwriteMerge }))

export const HDFilterState = makeVar<Filter>({
  channelID: null,
  focusedFields: [],
  activeFields: [],
  isOpen: false,
  listView: false,
  saleTypes: ["PRIVATE", "REALTOR", "BUILDER", "COMMERCIAL"],
  baths: 0,
  beds: 0,
  lastEstimatedPrice: [5000, max.lastEstimatedPrice],
  closePrice: [5000, max.closePrice],
  closeDate: [2018, new Date().getFullYear() + 1],
  yearBuiltActual: [minYearBuiltActual, new Date().getFullYear() + 1],
  lotSizeArea: [500, 20000],
  livingArea: [100, max.livingArea],
  transactionsHistory: [0, 10],
  propertySubType: [],
  zoning: [],
  assessmentClass: [],
})

export const setHDFilterState = (state: Filter): Filter =>
  HDFilterState(deepmerge(HDFilterState(), state, { arrayMerge: overwriteMerge }))

export const HDListState = makeVar<PropertiesListParams>({
  count: 0,
  propertiesList: [],
  currentPage: 1,
  perPage: 12,
})

export const setHDListState = (state: PropertiesListParams): PropertiesListParams =>
  HDListState(deepmerge(HDListState(), state, { arrayMerge: overwriteMerge }))

export const HDListingStepsState = makeVar<IListingSteps>({
  currentStep: 0,
  totalSteps: 3,
  profileStep: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    contactMethod: "Phone",
  },
  listingDetailsStep: {
    property: null,
    listing: {
      property: null,
      title: "",
      description: "",
      askingPrice: null,
      tags: [],
      images: [],
      unit: "",
      street: "",
      fullAddress: "",
      unparsedAddress: "",
      city: "",
      province: "",
      houseStyle: "",
      bedroomsTotal: null,
      bathroomsTotal: null,
      basement: "",
      livingArea: null,
      lotSizeArea: null,
      yearBuiltActual: null,
      latitude: null,
      longitude: null,
    },
  },
})

export const setHDListingStepsState = (state: IListingSteps): IListingSteps =>
  HDListingStepsState(deepmerge(HDListingStepsState(), state, { arrayMerge: overwriteMerge }))
