import { FC, forwardRef } from "react"
import { FaArrowLeft, FaArrowRight, FaEllipsisH } from "react-icons/fa"
import { useMediaQuerySSR } from "~/components/hooks"
import { Button } from "@chakra-ui/react"
import ChocPagination from "@choc-ui/paginator"
import { Pagination as TPaginationProps } from "@choc-ui/paginator/dist/providers/pagination-provider"

interface AddedPaginationProps {
  size?: string
}

export const Pagination:FC<Partial<TPaginationProps> & AddedPaginationProps> = (props) => {
  const isSmallerThan600 = useMediaQuerySSR(600)

  const Prev = forwardRef<HTMLButtonElement>((props, ref) => (
    <Button ref={ref} {...props} backgroundColor={"#0000 !important"}>
      <FaArrowLeft />
    </Button>
  ))
  const Next = forwardRef<HTMLButtonElement>((props, ref) => (
    <Button ref={ref} {...props} backgroundColor={"#0000 !important"}>
      <FaArrowRight />
    </Button>
  ))

  const Ellipsis = forwardRef<HTMLButtonElement>((props, ref) => (
    <Button ref={ref} {...props}>
      <FaEllipsisH />
    </Button>
  ))

  const itemRender = (_, type) => {
    if (type === "prev") {
      return Prev
    }
    if (type === "next") {
      return Next
    }
    if (type === "backward" || type === "forward") {
      return Ellipsis
    }
  }
  return (
    <div data-cy={"pagination"}>
      <ChocPagination
        pageNeighbours={isSmallerThan600 ? 0 : 1}
        paginationProps={{ display: "flex" }}
        baseStyles={{ bg: "var(--chakra-colors-gray-100)" }}
        activeStyles={{ bg: "var(--chakra-colors-gray-300)" }}
        hoverStyles={{ bg: "var(--chakra-colors-gray-200) !important" }}
        itemRender={itemRender}
        size={props.size ? props.size : isSmallerThan600 ? "xs" : "sm"}
        // responsive={{ activePage: true, fastForward: true, fastBackward: true }}
        {...props}
      />
    </div>
  )
}
