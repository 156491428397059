import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"

export function isUnlisted(property: EsProperty | PrivateListing | CreaListing): property is EsProperty {
  return property.__typename === "ESProperty" || "accountNumber" in property
}

export function isPrivateListing(
  property: EsProperty | PrivateListing | CreaListing,
): property is PrivateListing {
  return property.__typename === "PrivateListing" || "askingPrice" in property
}

export function isPublicListing(
  property: EsProperty | PrivateListing | CreaListing,
): property is CreaListing {
  return property.__typename === "CreaListing" || "address" in property
}

export function getPropertyBoard(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return PROPERTY_BOARDS.UNLISTED
  }
  if (isPrivateListing(property)) {
    return PROPERTY_BOARDS.PRIVATE
  }
  if (isPublicListing(property)) {
    return PROPERTY_BOARDS.CREA
  }
}

export function getMeta(property: EsProperty | PrivateListing | CreaListing) {
  const id = getId(property)
  const slug = getSlug(property)
  return { id, slug }
}

export function getId(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.id || ""
  }
  if (isPrivateListing(property)) {
    return property?.id || ""
  }
  if (isPublicListing(property)) {
    return property?.property?.id || ""
  }
}

export function getSlug(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.slug || ""
  }
  if (isPrivateListing(property)) {
    return property?.slug || ""
  }
  if (isPublicListing(property)) {
    return property?.slug || ""
  }
}

export function getPropertyType(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.assessmentClass
  }
  if (isPrivateListing(property)) {
    return property?.houseStyle
  }
  if (isPublicListing(property)) {
    return property?.propertyType
  }
}
