import { FC } from "react"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  ModalProps,
} from "@chakra-ui/react"
import { LoginBox } from "./login-box"
import { useAuth } from "~/components/hooks"

interface ISignInModal extends Omit<ModalProps, "children"> {
  callbackUrl?: string
}

export const SignInModal: FC<ISignInModal> = ({ callbackUrl, onClose, ...modalProps }) => {
  const { clearCallbackUrl } = useAuth()

  return (
    <Modal
      {...modalProps}
      onClose={onClose}
      size={useBreakpointValue({ base: "full", md: "sm" })}
      motionPreset={useBreakpointValue({ base: "slideInBottom", md: "scale" })}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sign in to your account</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={5}>
          <LoginBox
            callbackUrl={callbackUrl}
            onSuccess={() => {
              clearCallbackUrl()
              onClose()
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
