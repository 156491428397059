import { isPrivateListing, isPublicListing, isUnlisted } from "~/utils/helpers/common.helper"
import {
  convertMeasurement,
  convertToSquareFeet,
  convertToSquareMeters,
} from "~/utils/helpers/math.helper"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { MEASUREMENT_UNITS } from "~/utils/constants"

/**
 * Convert string value - "815 m2" or "815 ft2" to ft2 number
 */
export function getSqftFromValue(size: string) {
  if (!size) return null
  const sizeArr = size.split(" ")
  const sizeNum = Number(sizeArr[0])

  if (sizeArr.length === 1) {
    return sizeNum
  }
  const sizeUnit = sizeArr[1]
  return sizeUnit === "m2" ? convertToSquareFeet(sizeNum) : sizeNum
}

/**
 * Format to more readable value
 * 1938 => 1,938
 */
export function formatSizeValue(value: number) {
  if (!value) return null
  return Intl.NumberFormat().format(Number(value.toFixed(0)))
}

export function calcLotSizeArea(property: EsProperty | PrivateListing | CreaListing) {
  const { value, estimate } = getLandSpace(property)
  if (isUnlisted(property)) {
    if ((value as number) >= 12140) {
      const acres = convertMeasurement(+value, MEASUREMENT_UNITS.SQM, MEASUREMENT_UNITS.ACRES)
      return { value: `${acres} acres`, estimate }
    } else {
      return { value: calcAreaFromSqft(convertToSquareFeet(value as number)), estimate }
    }
  }

  return { value, estimate }
}

export function calcAreaFromSqft(sqft: number) {
  if (!sqft) return null
  const sqmFormatted = formatSizeValue(convertToSquareMeters(sqft))
  const sqftFormatted = formatSizeValue(sqft)

  return `${sqmFormatted} sqm (${sqftFormatted} sqft)`
}
export function calcAreaFromSqm(sqm: number) {
  if (!sqm) return null
  const sqftFormatted = formatSizeValue(convertToSquareFeet(sqm))
  const sqmFormatted = formatSizeValue(sqm)

  return `${sqmFormatted} sqm (${sqftFormatted} sqft)`
}

export function getBedrooms(property: EsProperty | PrivateListing | CreaListing) {
  let value: number, estimate: boolean

  if (isUnlisted(property)) {
    value = property?.bedroomsTotal
    if (!value) {
      value = property?.bedroomsTotalEst
      estimate = true
    }
  } else if (isPrivateListing(property)) {
    value = property?.bedroomsTotal
  } else if (isPublicListing(property)) {
    value = property?.building?.bedroomsTotal
  }
  return { value, estimate }
}

export function getBathrooms(property: EsProperty | PrivateListing | CreaListing) {
  let value: number, estimate: boolean

  if (isUnlisted(property)) {
    value = property?.bathroomsTotal
    if (!value) {
      value = property?.bathroomsTotalEst
      estimate = true
    }
  } else if (isPrivateListing(property)) {
    value = property?.bathroomsTotal
  } else if (isPublicListing(property)) {
    value = property?.building?.bathroomTotal
  }
  return { value, estimate }
}

export function getParking(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.garageSpaces
  }
  if (isPublicListing(property)) {
    return property?.parkingSpaceTotal
  }
}

export function getFloorSpace(property: EsProperty | PrivateListing | CreaListing) {
  let value: number, estimate: boolean

  if (isUnlisted(property)) {
    value = property?.livingArea
    if (!value) {
      value = property?.livingAreaEst
      estimate = true
    }
  } else if (isPrivateListing(property)) {
    value = property?.livingArea
  } else if (isPublicListing(property)) {
    value = getSqftFromValue(property?.building?.sizeInterior)
  }
  return { value, estimate }
}

export function getLandSpace(property: EsProperty | PrivateListing | CreaListing) {
  let value: number | string, estimate: boolean

  if (isUnlisted(property)) {
    value = property?.lotSizeArea
    if (!value) {
      value = property?.lotSizeAreaEst
      estimate = true
    }
  } else if (isPrivateListing(property)) {
    value = property?.lotSizeArea
  } else if (isPublicListing(property)) {
    value = property?.land?.sizeTotalText
  }
  return { value, estimate }
}

export function getZoning(property: EsProperty | PrivateListing | CreaListing) {
  let value: string

  if (isUnlisted(property)) {
    value = property?.zoning
  } else if (isPrivateListing(property)) {
    value = property?.houseStyle
  } else if (isPublicListing(property)) {
    value = property?.ownershipType
  }
  return { value }
}

export function getGarage(property: EsProperty | PrivateListing | CreaListing) {
  let value: number

  if (isUnlisted(property)) {
    value = property?.garageSpaces
  } else if (isPublicListing(property)) {
    value = property?.parkingSpaceTotal
  }
  return { value }
}

export function getBuilt(property: EsProperty | PrivateListing | CreaListing) {
  let value: number
  let isRounded = false

  if (isUnlisted(property)) {
    if (property.province === "British Columbia") {
      isRounded = true
    }
    value = property?.yearBuiltActual
  } else if (isPrivateListing(property)) {
    value = property?.yearBuiltActual
  } else if (isPublicListing(property)) {
    value = property?.building?.constructedDate
  }
  return { value, isRounded }
}
export function getAmenities(property: EsProperty | PrivateListing | CreaListing) {
  const { value: bedrooms } = getBedrooms(property)
  const { value: bathrooms } = getBathrooms(property)
  const parking = getParking(property)
  const { value: floorSpace } = getFloorSpace(property)
  const { value: lotSize } = getLandSpace(property)
  const floorSpaceFormatted = formatSizeValue(floorSpace)

  return { bedrooms, bathrooms, parking, floorSpace, floorSpaceFormatted, lotSize }
}
