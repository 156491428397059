import getConfig from "next/config"
const { GA_TRACKING_ID } = getConfig().publicRuntimeConfig

declare global {
  interface Window {
    gtag: any
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  })
}

/**
 * Create a gtag event
 *
 * Read more about gtag events here
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 * Read more about the naming convention in this guide
 * https://jumpstory.com/blog/how-to-name-events-in-google-analytics/#event-category-naming
 *
 * @param action The action being taken by the user. E.g "clicked left sidebar" or "opened pop up"
 * @param category The location on-page in which the action takes place. E.g "header", "footer", "right sidebar", "left sidebar", "banner", "pop up"
 * @param label Information specific to the event that provides additional insight. E.g. the address or button text
 * @param value A numeric value related to the event. Not typically required
 */
export const event = ({
  action = null,
  category = null,
  label = null,
  value = null,
}: {
  action: string
  category?: string
  label?: string
  value?: number
}) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

export default {
  pageview,
  event,
  GA_TRACKING_ID,
}
