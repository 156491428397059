import { createUrl, provinceLongToProvinceAbbr } from "~/utils"
import { formatStreet } from "~/utils/lib/string"
import {
  getPropertyType,
  isPrivateListing,
  isPublicListing,
  isUnlisted,
} from "~/utils/helpers/common.helper"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { popularCities } from "~/utils/constants/cityLinkAndFilterConstants"

export function getCoordinates(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return { latitude: property?.location?.lat, longitude: property?.location?.lon }
  }
  if (isPrivateListing(property)) {
    return { latitude: property?.latitude, longitude: property?.longitude }
  }
  if (isPublicListing(property)) {
    return { latitude: property?.location?.lat, longitude: property?.location?.lon }
  }
}

export function getAddress(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.unparsedAddress || ""
  }
  if (isPrivateListing(property)) {
    return property?.streetAddress || ""
  }
  if (isPublicListing(property)) {
    return property?.address?.streetAddress || ""
  }
}

export function getCity(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.cityName || ""
  }
  if (isPrivateListing(property)) {
    return property?.city || ""
  }
  if (isPublicListing(property)) {
    return property?.address?.city || ""
  }
}

export function getNeighbourhood(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.neighbourhoodName || ""
  }
  if (isPublicListing(property)) {
    return property?.address?.neighbourhood || ""
  }
}

export function getProvince(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.province
  }
  if (isPrivateListing(property)) {
    return property?.province
  }
  if (isPublicListing(property)) {
    return property?.address?.province
  }
}

export function getPostalCode(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.postal
  }
  if (isPrivateListing(property)) {
    return property?.postalCode
  }
  if (isPublicListing(property)) {
    return property?.address?.postalCode
  }
}

export function getAddressFormatted(
  property: EsProperty | PrivateListing | CreaListing,
  variant?: "full",
) {
  const address = getAddress(property)
  const city = getCity(property)
  const province = getProvince(property)
  const addressFormatted = formatStreet(address)
  const neighbourhood = getNeighbourhood(property)
  const provinceAbbreviated = provinceLongToProvinceAbbr(province)
  const postalCode = getPostalCode(property)

  if (variant === "full") {
    return [address, city, province, "Canada"].filter(Boolean).join(", ")
  }

  return [addressFormatted, neighbourhood, city, provinceAbbreviated, postalCode]
    .filter(Boolean)
    .join(", ")
}

export function getLocation(property: EsProperty | PrivateListing | CreaListing) {
  const neighbourhood = getNeighbourhood(property)
  const city = getCity(property)
  const province = getProvince(property)
  const provinceAbbreviated = provinceLongToProvinceAbbr(province)
  const address = getAddress(property)
  const addressFormatted = formatStreet(address)
  const addressFormattedMain = getAddressFormatted(property)
  const addressFormattedFull = getAddressFormatted(property, "full")
  const coordinates = getCoordinates(property)
  const type = getPropertyType(property)
  const postalCode = getPostalCode(property)

  return {
    address,
    addressFormatted,
    addressFormattedFull,
    addressFormattedMain,
    neighbourhood,
    city,
    province,
    provinceAbbreviated,
    coordinates,
    type,
    postalCode,
  }
}

export function createPopularCitiesLinks() {
  return popularCities.map(item => {
    const [citiName, provinceAbbr] = item.split(", ")

    return {
      label: item,
      href: createUrl({
        variant: "cities",
        city: citiName,
        provinceAbbr,
      }),
    }
  })
}
