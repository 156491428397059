const minCloseDate: number = 1902
const minYearBuiltActual: number = 1898

const max = {
  lastEstimatedPrice: 2000000,
  closePrice: 2000000,
  livingArea: 10000,
  lotSizeArea: 20000,
}

export { minCloseDate, minYearBuiltActual, max }
