import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

export enum TextareaVariants {
  ContactForm = "contactForm",
}

export default defineStyleConfig({
  defaultProps: {
    variant: "default",
  },

  baseStyle: defineStyle({
    background: "#FFFFFF",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "gray.200",
    borderRadius: "8px",

    _invalid: {
      borderColor: "red.500",
    },
  }),

  variants: {
    default: defineStyle({}),
    contactForm: defineStyle({}),
  },
})
