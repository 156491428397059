const Popover = {
  variants: {
    responsive: {
      popper: {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },
}

export default Popover
