import { NextSeoProps } from "next-seo"
import { Place } from "./types"
import startCase from "lodash/startCase"
import { createUrl } from "~/utils"

/**
 * @param provinceLongName - full name of the province
 * @returns province abbreviation, postal code style (2 letters only, PEI becomes PE for example)
 */
export const provinceLongToProvinceAbbr = (provinceLongName: string): string => {
  if (!provinceLongName) return ""

  switch (provinceLongName.toLowerCase()) {
    case "british columbia":
      return "BC"
    case "alberta":
      return "AB"
    case "saskatchewan":
      return "SK"
    case "manitoba":
      return "MB"
    case "ontario":
      return "ON"
    case "québec":
      return "QC"
    case "newfoundland and labrador":
      return "NL"
    case "newfoundland & labrador":
      return "NL"
    case "nova scotia":
      return "NS"
    case "prince edward island":
      return "PE"
    case "new brunswick":
      return "NB"
    case "yukon":
      return "YT"
    case "northwest territories":
      return "NT"
    case "nunavut":
      return "NU"
    default:
      return ""
  }
}

/**
 * @param provinceAbbr - 2-letter province abbreviation, Postal style
 * @returns full province name, capitalized and all
 */
export const provinceAbbrToProvinceLong = (provinceAbbr: string): string => {
  if (!provinceAbbr) return ""

  const switcher = provinceAbbr.toUpperCase()
  switch (switcher) {
    case "BC":
      return "British Columbia"
    case "AB":
      return "Alberta"
    case "SK":
      return "Saskatchewan"
    case "MB":
      return "Manitoba"
    case "ON":
      return "Ontario"
    case "QC":
      return "Quebec"
    case "NL":
      return "Newfoundland and Labrador"
    case "NS":
      return "Nova Scotia"
    case "PE":
      return "Prince Edward Island"
    case "NB":
      return "New Brunswick"
    case "YT":
      return "Yukon"
    case "NT":
      return "Northwest Territories"
    case "NU":
      return "Nunavut"
    default:
      return ""
  }
}

type PlaceDefaultsArgs = {
  default?: string
  city?: string
  neighbourhood?: string
  type?: "prefix" | "suffix"
  separator?: string
}

/**
 * Given a place, construct and return a string for use in a meta tag
 *
 * @param place The place for which a title is generated
 * @param defaults The strings to use if the location is a city, neighbourhood,
 * etc. As well as options to choose how the locations name is appended and a
 * separating string
 * @returns The most specific title string with the locations name as a prefix
 * or suffix
 */
export const forgePlaceTitle = (place: Place, defaults: PlaceDefaultsArgs): string => {
  if (!place) return defaults.default || ""

  // eslint-disable-next-line prefer-const
  let { city, neighbourhood, province } = place
  const { type = "suffix", separator = "-" } = defaults

  province = province?.length > 2 ? provinceLongToProvinceAbbr(province) : province

  if (city && neighbourhood) {
    const placeString = forgePlaceString({
      province,
      city,
      neighbourhood,
    })

    return [
      type === "prefix" ? defaults.neighbourhood ?? defaults.default : null,
      placeString,
      type === "suffix" ? defaults.neighbourhood ?? defaults.default : null,
    ]
      .filter(Boolean)
      .join(` ${separator} `)
  }

  if (city) {
    const placeString = forgePlaceString({
      province,
      city,
    })

    return [
      type === "prefix" ? defaults.city ?? defaults.default : null,
      placeString,
      type === "suffix" ? defaults.city ?? defaults.default : null,
    ]
      .filter(Boolean)
      .join(` ${separator} `)
  }

  if (province) {
    return [
      type === "prefix" ? defaults.default : null,
      provinceAbbrToProvinceLong(province),
      type === "suffix" ? defaults.default : null,
    ]
      .filter(Boolean)
      .join(` ${separator} `)
  }

  return defaults.default
}

/**
 * Given a place constructs and returns a human readable string of its name
 */
export const forgePlaceString = (place: Place): string => {
  if (!place) return null

  // eslint-disable-next-line prefer-const
  let { city, neighbourhood, province } = place

  province = province?.length > 2 ? provinceLongToProvinceAbbr(province) : province

  if (city && neighbourhood) {
    const placeString = [startCase(neighbourhood), startCase(city)].filter(Boolean).join(", ")
    return placeString
  }

  if (city) {
    const placeString = [startCase(city), province?.toUpperCase()].filter(Boolean).join(" ")
    return placeString
  }

  return ""
}

/**
 * Given a place and a set of strings, outputs the string pertaining most specifically to the location
 * e.g.
 * place = {province: AB} => province string
 * place = {province: AB, city: Edmonton} => city string
 *
 * @param place The place for which the string is generated
 * @param strings A set of strings, one for each level of specificity
 * @returns The most specific string applicable
 */
export const forgePlaceInformation = (
  place?: Place,
  strings?: {
    default?: string
    province?: string
    city?: string
    neighbourhood?: string
  },
): string => {
  if (!place) return strings.default || ""

  // eslint-disable-next-line prefer-const
  let { city, neighbourhood, province } = place
  province = province?.length > 2 ? provinceLongToProvinceAbbr(province) : province

  if (city && neighbourhood) {
    return strings.neighbourhood || strings.default
  }
  if (city) {
    return strings.city || strings.default
  }
  if (province) {
    return strings.province || strings.default
  }
  return strings.default
}

export const forgePlaceURL = (place: Place, url: string, shortenProvince: boolean = true) => {
  const defaultUrl = `https://www.honestdoor.com${url}`
  if (!place) return defaultUrl

  // eslint-disable-next-line prefer-const
  let { city, neighbourhood, province } = place

  const provinceAbbr = shortenProvince
    ? province?.length > 2
      ? provinceLongToProvinceAbbr(province)
      : province
    : province

  const variant = url.substring(1)

  if (["cities", "permits", "recently-sold", "real-estate", "sitemap"].includes(variant)) {
    return createUrl({
      isAbsolute: true,
      variant: variant as any,
      provinceAbbr,
      city,
      neighbourhood,
    })
  }

  return defaultUrl
}

export const getCurrentMonthYear = () => {
  return new Date().toLocaleString("en-US", { month: "long", year: "numeric" })
}

const defaultMetadata: NextSeoProps = {
  defaultTitle: "HonestDoor - Your Real Estate Starting Point",
  titleTemplate: "%s | HonestDoor",
  description:
    "Free real estate data on residential and commercial properties - sold prices, estimated value of homes, bed and bath. Exclusive houses for sale",
  canonical: "https://www.honestdoor.com",
  openGraph: {
    site_name: "HonestDoor",
    type: "website",
    title: "HonestDoor - Your Real Estate Starting Point",
    description:
      "Free real estate data on residential and commercial properties - sold prices, estimated value of homes, bed and bath. Exclusive houses for sale",
    url: "https://www.honestdoor.com",
    images: [
      {
        url: "https://d39r1nkmfgfvl8.cloudfront.net/hdOnLaptop.jpg",
        alt: "User visiting HonestDoor on a laptop",
      },
    ],
  },
  twitter: {
    site: "@honest_door",
    handle: "@honest_door",
    cardType: "summary_large_image",
  },
  additionalMetaTags: [
    {
      name: "viewport",
      content:
        "minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover",
    },
  ],
}

export { defaultMetadata }
