import { ThemeOverride } from "@chakra-ui/react"

const Stat: ThemeOverride["components"]["Stat"] = {
  baseStyle: {
    label: {
      fontWeight: "normal",
      //@ts-ignore not sure why this is complaining
      color: "hdBlack.400",
    },
    number: {
      fontWeight: "500",
      //@ts-ignore not sure why this is complaining
      color: "hdMoney.full",
    },
    helpText: {
      //@ts-ignore not sure why this is complaining
      fontSize: "12px",

      // But this isnt??
      color: "hdBlack.300",
    },
  },
}

export default Stat
