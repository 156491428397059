import { MapFiltersStore } from "~/store/mapFilters"
import { MapStore } from "~/store/map"
import { PageModalStore } from "~/store/pageModal"
import Router from "next/router"

type MapArgs = {
  state?: Partial<MapStore>
  filters?: Partial<MapFiltersStore>
  pageModal?: Partial<PageModalStore>
}

type Navigate = {
  map: (args?: MapArgs) => Promise<boolean>
}

type UseNavigationFunction = () => {
  navigate: Navigate
}

const navigate: Navigate = {
  map: args => {
    const { state, filters, pageModal } = args

    return Router.push({
      pathname: "/map",
      query: {
        ...(state && { state: JSON.stringify(state) }),
        ...(filters && { filters: JSON.stringify({ map: filters }) }),
        ...(pageModal && { pageModal: JSON.stringify(pageModal) }),
      },
    })
  },
}

export const useNavigation: UseNavigationFunction = () => {
  return { navigate }
}
