import { ReactNode } from "react"

import { Box, BoxProps, chakra } from "@chakra-ui/react"

interface IBlurProps extends BoxProps {
  overlay?: ReactNode
  blurred?: boolean
  children: ReactNode
}

const BlurOverlay = ({ children }) => (
  <chakra.div position="absolute" display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" zIndex="1">
    {children}
  </chakra.div>
)

const BlurContent = ({ children }) => (
  <chakra.div filter="blur(5px)" backgroundColor="white" width="100%" height="100%" zIndex="0">
    {children}
  </chakra.div>
)

const Blur = (props: IBlurProps) => {
  const { overlay, blurred = true, children, w, minH } = props
  if (!blurred) return <>{children}</>
  return (
    <Box position="relative" w={w} minH={minH}>
      <BlurOverlay>{overlay}</BlurOverlay>
      <BlurContent>{children}</BlurContent>
    </Box>
  )
}

export default chakra(Blur, { shouldForwardProp: () => true })
