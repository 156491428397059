import { FC } from "react"
import {
  Grid,
  GridItem,
  GridProps,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
} from "@chakra-ui/layout"
import InternalLink from "~/components/elements/InternalLink"

type TLinkListLink = {
  label: string
  href?: string
}

export type TLinkListItem = {
  category?: string
  links: TLinkListLink[]
}

export type ILinkList = {
  items: TLinkListItem[]
  headingProps?: HeadingProps
  linkProps?: LinkProps
  linkGridProps?: GridProps
  minColWidth?: number
  categoryDir?: "horizontal" | "vertical"
} & GridProps

export const LinkList: FC<ILinkList> = ({
  items,
  headingProps,
  linkProps,
  minColWidth,
  categoryDir,
  linkGridProps,
  ...rest
}) => {
  const gridTemplateColumns =
    categoryDir === "horizontal"
      ? `repeat(auto-fit, minmax(${minColWidth}px, 1fr))`
      : `repeat(auto-fit, minmax(1fr, ${minColWidth}px))`

  return (
    <Grid gridTemplateColumns={gridTemplateColumns} w="100%" data-cy="link-list" {...rest}>
      {items?.map(item => (
        <GridItem key={item.category}>
          {item.category && item.links.length > 0 && (
            <Heading
              fontSize="26px"
              color="hdBlack.500"
              fontWeight="500"
              mb="4"
              as="h3"
              {...headingProps}
            >
              {item.category}
            </Heading>
          )}
          <Grid
            gap={3}
            gridTemplateColumns={`repeat(auto-fill, minmax(${minColWidth}px, 1fr))`}
            {...linkGridProps}
          >
            {item.links.map(link => {
              //TODO: Create new "InternalLink" utilizing Chakra "Link" component
              if (link.href) {
                return (
                  <GridItem key={link.label}>
                    <InternalLink href={link.href} passHref linkProps={linkProps}>
                      {link.label}
                    </InternalLink>
                  </GridItem>
                )
              } else {
                return (
                  <GridItem key={link.label}>
                    <Link {...linkProps}>{link.label}</Link>
                  </GridItem>
                )
              }
            })}
          </Grid>
        </GridItem>
      ))}
    </Grid>
  )
}

LinkList.defaultProps = {
  minColWidth: 300,
  gap: "10px",
  rowGap: "55px",
  categoryDir: "horizontal",
}
