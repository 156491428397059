import { FC, useCallback, useEffect, useState, ReactNode, Children } from "react"
import { useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  SkeletonText,
  Text,
  useToast,
} from "@chakra-ui/react"
import debounce from "lodash/debounce"
import { useCombobox, UseComboboxStateChange } from "downshift"
import { formatStreet } from "~/utils/lib/string"
import { provinceLongToProvinceAbbr } from "~/utils"
import { MultiSearchDocument } from "~/generated/graphql"

interface IPropertyItem {
  id: string
  unparsedAddress: string
  cityName: string
  province: string
  slug: string
  accountNumber: string
  location: {
    lat: number
    lon: number
  }
  creaListing: null
  city: string
  formattedAddress: string
  provinceAbbr: string
}

interface IPropertiesAutocomplete {
  showLabel?: boolean
  onSelect?: (values: IPropertyItem) => void
  actions?: ReactNode[]
}

export const PropertiesAutocomplete: FC<IPropertiesAutocomplete> = ({
  showLabel = true,
  onSelect,
  actions = [],
}) => {
  const toast = useToast()
  const [items, setItems] = useState([])
  const [load, { loading }] = useLazyQuery(gql(MultiSearchDocument))
  const debouncedOnChange = useCallback(debounce(onInputValueChange, 500), [])
  const actionsArr = Children.toArray(actions)

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel()
    }
  }, [])

  async function onInputValueChange({ inputValue }: UseComboboxStateChange<IPropertyItem>) {
    try {
      const { data } = await load({ variables: { query: inputValue } })
      const properties = (data.multiSearch?.properties || []).map(p => {
        return {
          ...p,
          city: p.cityName,
          formattedAddress: formatStreet(p.unparsedAddress),
          provinceAbbr: provinceLongToProvinceAbbr(p.province),
        }
      })
      setItems(properties)
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    closeMenu,
    reset,
  } = useCombobox({
    id: "address",
    items: [],
    onInputValueChange: debouncedOnChange,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && onSelect) {
        onSelect(selectedItem)
        reset()
      }
      closeMenu()
    },
    itemToString: item => `${item?.formattedAddress}, ${item.city}, ${item.provinceAbbr}`,
    stateReducer({ highlightedIndex }, { changes, type }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
          return {
            ...changes,
            highlightedIndex: highlightedIndex - 1 < 0 ? items.length - 1 : highlightedIndex - 1,
          }
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
          return {
            ...changes,
            highlightedIndex: highlightedIndex + 1 > items.length - 1 ? 0 : highlightedIndex + 1,
          }
        default:
          return changes
      }
    },
  })

  return (
    <FormControl>
      {showLabel && (
        <FormLabel {...getLabelProps()} id="address" htmlFor="address">
          <HStack justifyContent="space-between">
            <Box>Search for your property</Box>
            {actionsArr.map((action, actionKey) => (
              <Box key={actionKey}>{action}</Box>
            ))}
          </HStack>
        </FormLabel>
      )}
      <Input {...getInputProps()} id="address" w="100%" placeholder="Enter address" />

      <List
        {...getMenuProps()}
        overflow="auto"
        maxHeight="200px"
        position="absolute"
        backgroundColor="white"
        zIndex="100"
        w="100%"
        borderRadius="0 0 8px 8px"
        borderStyle="solid"
        borderWidth={isOpen && (loading || items.length > 0) ? "1px" : 0}
        borderTop="none"
      >
        {isOpen && (
          <>
            <SkeletonText
              as="li"
              my={4}
              skeletonHeight="4"
              px={4}
              isLoaded={!loading}
              display={loading ? undefined : "none"}
            />

            {!loading &&
              items.map((item, index) => {
                return (
                  <ListItem
                    {...getItemProps({
                      item,
                      index,
                    })}
                    key={`${item}${index}`}
                    py={2}
                    px={4}
                    borderColor="gray.200"
                    borderStyle="solid"
                    borderTopWidth="1px"
                    backgroundColor={highlightedIndex === index ? "hdSky.faded" : null}
                    onClick={() => selectItem(item)}
                    cursor="pointer"
                  >
                    <Text as="span">{item?.formattedAddress},</Text>
                    <Text as="span" color="hdGold.full" ml={2}>
                      {item.city},
                    </Text>
                    <Text as="span" color="hdGold.full" ml={2}>
                      {item.provinceAbbr}
                    </Text>

                    {item.creaListing?.listingId && (
                      <Text
                        {...{
                          content: '"For Sale"',
                          position: "sticky",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          verticalAlign: "text-top",
                          padding: "8px",
                          marginLeft: "6px",
                          height: "16px",
                          borderRadius: "999px",
                          backgroundColor: "white",
                          border: "1px solid",
                          borderColor: "hdFire.full",
                          color: "hdFire.full",
                          fontSize: "12px",
                          lineHeight: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        For Sale
                      </Text>
                    )}
                  </ListItem>
                )
              })}
          </>
        )}
      </List>
    </FormControl>
  )
}
