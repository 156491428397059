import { FC } from "react"
import getConfig from "next/config"
import { Map, Layer, Source, MapProps } from "react-map-gl"
import { Box, BoxProps } from "@chakra-ui/react"
import { point } from "@turf/helpers"
import theme from "~/theme"
import "mapbox-gl/dist/mapbox-gl.css"

const { MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE_URL } = getConfig().publicRuntimeConfig

interface IMapboxStaticMap {
  wrapperStyle?: BoxProps
}

export const MapboxStaticMap: FC<IMapboxStaticMap & MapProps> = ({
  mapboxAccessToken = MAPBOX_ACCESS_TOKEN,
  mapStyle = MAPBOX_STYLE_URL,
  longitude,
  latitude,
  wrapperStyle,
  ...props
}) => {
  if (!latitude || !longitude) return null

  return (
    <Box
      sx={{
        "& .mapboxgl-map": { height: wrapperStyle?.height || "200px", pointerEvents: "none" },
      }}
      {...wrapperStyle}
    >
      <Map
        mapLib={import("mapbox-gl")}
        mapboxAccessToken={mapboxAccessToken}
        mapStyle={mapStyle}
        longitude={longitude}
        latitude={latitude}
        {...props}
      >
        <Source id="location-feature" type="geojson" data={point([longitude, latitude])} />

        <Layer
          id="location-circle"
          source="location-feature"
          type="circle"
          paint={{
            "circle-color": theme.colors.hdSky.full,
            "circle-radius": 10,
          }}
        />

        <Layer
          id="location-symbol"
          source="location-feature"
          type="symbol"
          layout={{
            "text-field": "•",
            "text-font": ["Lato Regular", "Arial Unicode MS Regular"],
            "text-size": 14,
          }}
          paint={{
            "text-color": "white",
          }}
        />
      </Map>
    </Box>
  )
}
