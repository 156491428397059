import { Children, FC, useRef, useState, ReactNode } from "react"
import useEmblaCarousel from "embla-carousel-react"
import { Box, Flex, HStack, IconButton, useBreakpointValue } from "@chakra-ui/react"
import { useSize } from "@chakra-ui/react-use-size"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import range from "lodash/range"

type CarouselProps = {
  children: ReactNode
  columns?: number[] | number
  showDots?: boolean
}

export const Carousel: FC<CarouselProps> = ({ columns = [1, 1, 2, 3], children, showDots }) => {
  const [index, setIndex] = useState(0)
  const leftRef = useRef<HTMLDivElement>()
  const rightRef = useRef<HTMLDivElement>()
  const { width: leftWidth } = useSize(leftRef) ?? { width: null }
  const { width: rightWidth = 0 } = useSize(rightRef) ?? { width: null }
  const containerRef = useRef()
  const isMobile = useBreakpointValue([true, true, false])

  const [emblaRef, embla] = useEmblaCarousel({
    slidesToScroll: 1,
    align: "start",
    containScroll: "trimSnaps",
    inViewThreshold: 0.5,
  })

  const columnsBreakpoint = useBreakpointValue(Array.isArray(columns) ? columns : [columns])
  const percentWidth = `${(1 / columnsBreakpoint || 1) * 100}%`

  const scrollPrevious = () => {
    embla?.scrollPrev()
  }

  const scrollNext = () => {
    embla?.scrollNext()
  }

  embla?.on("select", () => {
    setIndex(embla?.selectedScrollSnap())
  })

  return (
    <Box width="100%" position="relative">
      <Box overflow={"hidden"} ref={emblaRef} padding={4} margin={-4}>
        <Flex margin={-4} ref={containerRef}>
          {Children.map(children, child => (
            <Box flexShrink={0} width={percentWidth} padding={4} transition="all 0.2s ease-in-out">
              {child}
            </Box>
          ))}
        </Flex>
      </Box>

      <Box
        position="absolute"
        height="100%"
        top={0}
        ref={leftRef}
        left={isMobile ? "5px" : `-${leftWidth}px`}
        display="flex"
        alignItems="center"
      >
        <IconButton
          aria-label="scroll carousel previous"
          pointerEvents="auto"
          icon={<FaChevronLeft />}
          variant="ghost"
          onClick={scrollPrevious}
          mr={4}
          disabled={!embla?.canScrollPrev()}
        />
      </Box>

      <Box
        position="absolute"
        height="100%"
        top={0}
        ref={rightRef}
        right={isMobile ? "5px" : `-${rightWidth}px`}
        display="flex"
        alignItems="center"
      >
        <IconButton
          aria-label="scroll carousel next"
          pointerEvents="auto"
          icon={<FaChevronRight />}
          variant="ghost"
          onClick={scrollNext}
          ml={4}
          disabled={!embla?.canScrollNext()}
        />
      </Box>

      {showDots && (
        <HStack justify={"center"} pt={6}>
          {range(0, 1 + Children.count(children) - columnsBreakpoint).map(i => {
            return (
              <Box
                key={i}
                w={2}
                h={2}
                borderRadius={"full"}
                bgColor={i === index ? "gold.500" : "gray.400"}
                cursor={"pointer"}
                onClick={() => embla.scrollTo(i)}
              />
            )
          })}
        </HStack>
      )}
    </Box>
  )
}
