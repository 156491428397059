import { FC } from "react"
import {
  Box,
  Button,
  Flex,
  Heading,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react"
import { Modal, ModalBody, ModalContent } from "@chakra-ui/modal"
import { HonestDoorLogoSvg } from "~/components/logos"
import {
  EsProperty,
  useCreateCmaReportMutation,
  useCreateProductCheckoutSessionMutation,
  useGetProductsWithPricesQuery,
} from "~/generated/graphql"
import { useUser } from "~/components/hooks"
import { formatCurrency } from "~/utils"
import Link from "next/link"
import gtag from "~/utils/lib/gtag"

interface ICMAReportModalModal {
  property: EsProperty
  isOpen: boolean
  onClose?: () => void
}

export const CMAReportModal: FC<ICMAReportModalModal> = ({ property, isOpen, onClose }) => {
  const { user } = useUser()
  const toast = useToast()
  const { data } = useGetProductsWithPricesQuery({
    filter: { sku: "CMA_REPORT" },
  })
  const { mutateAsync: createCmaReport, isLoading: createCmaReportLoading } =
    useCreateCmaReportMutation()
  const { mutateAsync: createProductCheckoutSession, isLoading: productCheckoutSessionLoading } =
    useCreateProductCheckoutSessionMutation()
  const productPrice = data?.getProducts[0]?.prices[0]

  const handlePay = async () => {
    try {
      gtag.event({
        action: "cma_button_stripe_clicked",
      })
      const { createCmaReport: cmaReport } = await createCmaReport({
        input: {
          cityId: property.city,
          cityName: property.cityName,
          province: property.province,
          propertyId: property.id,
          slug: property.slug,
          streetAddress: property.unparsedAddress,
        },
      })
      const {
        createProductCheckoutSession: { url },
      } = await createProductCheckoutSession({
        input: {
          userId: user?.id,
          customerEmail: user?.email,
          productId: productPrice.productId,
          priceId: productPrice.id,
          productReferenceId: cmaReport.id,
          successUrl: `${window.location.href}?checkout_success=true`,
          cancelUrl: `${window.location.href}?checkout_success=false`,
        },
      })
      window.location.href = url
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top",
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="700px">
        <ModalHeader>
          <Flex w="100%" justify="center">
            <HonestDoorLogoSvg />
          </Flex>
          <Heading as="h2" fontSize="lg" textAlign="center" mt={2}>
            Market Evaluation Report
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>Receive a comparative market analysis report from our in-house REALTOR®</Box>

          <Box textAlign="center" my={6}>
            <Link
              href="https://hd-site-assests.s3.us-east-2.amazonaws.com/files/CMA_report_sample.pdf"
              target="_blank"
              onClick={() => {
                gtag.event({
                  action: "cma_sample_clicked",
                })
              }}
            >
              <Button variant="secondary" as="a">
                View Sample Report
              </Button>
            </Link>
          </Box>

          <Box mt={4}>
            Includes details about comparable / similar properties that:
            <Box>
              - Are currently on the market (days on market, listed price, property details, etc.)
            </Box>
            <Box>
              - Have recently sold (final sold price, days on market, listed price, pictures, etc.)
            </Box>
          </Box>

          <Heading as="h2" fontSize="lg" my={6} textAlign="center">
            One time payment
          </Heading>
          <Heading as="h2" textAlign="center" color="green.700">
            {formatCurrency(productPrice?.amount / 100)}
          </Heading>
          <Box textAlign="center" my={6}>
            <Button
              variant="secondary"
              onClick={handlePay}
              isLoading={createCmaReportLoading || productCheckoutSessionLoading}
            >
              Pay now
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
