import { isPrivateListing, isPublicListing } from "~/utils/helpers/common.helper"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"

export function getImages(property: EsProperty | PrivateListing | CreaListing) {
  if (isPrivateListing(property)) {
    return property.images?.map(image => image.url) || []
  }
  if (isPublicListing(property)) {
    return property.photo?.propertyPhoto?.map(image => image.largePhotoUrl) || []
  }
  return []
}

export function getPhotos(property: PrivateListing | CreaListing) {
  if (isPrivateListing(property)) {
    return property.images?.map(image => image.url) || []
  } else if (isPublicListing(property)) {
    return property.photo?.propertyPhoto?.map(image => image.largePhotoUrl) || []
  }
  return []
}
