const airdrieRates = {
  [2017]: {
    RESIDENTIAL: 0.00657126,
    CONDO: 0.00657126,
    COMMERCIAL: 0.01167945,
  },
  [2018]: {
    RESIDENTIAL: 0.00668775,
    CONDO: 0.00668775,
    COMMERCIAL: 0.01231951,
  },
  [2019]: {
    RESIDENTIAL: 0.00712538,
    CONDO: 0.00712538,
    COMMERCIAL: 0.0134154,
  },
  [2020]: {
    RESIDENTIAL: 0.00738744,
    CONDO: 0.00738744,
    COMMERCIAL: 0.01357404,
  },
  [2021]: {
    RESIDENTIAL: 0.00738744,
    CONDO: 0.00738744,
    COMMERCIAL: 0.01357404,
  },
}

const calgaryRates = {
  [2013]: {
    COMMERCIAL: 0.014296,
    CONDO: 0.0063221,
    RESIDENTIAL: 0.0063221,
    // "OTHER RESIDENTIAL": ,
  },
  [2014]: {
    COMMERCIAL: 0.0141108,
    CONDO: 0.0061029,
    RESIDENTIAL: 0.0061029,
    // "OTHER RESIDENTIAL": ,
  },
  [2015]: {
    COMMERCIAL: 0.0141942,
    CONDO: 0.0057544,
    RESIDENTIAL: 0.0057544,
    // "OTHER RESIDENTIAL": ,
  },
  [2016]: {
    COMMERCIAL: 0.0159346,
    CONDO: 0.0061738,
    RESIDENTIAL: 0.0061738,
    // "OTHER RESIDENTIAL": ,
  },
  [2017]: {
    COMMERCIAL: 0.0177445,
    CONDO: 0.0065008,
    RESIDENTIAL: 0.0065008,
    // "OTHER RESIDENTIAL": ,
  },
  [2018]: {
    COMMERCIAL: 0.0194264,
    CONDO: 0.0063573,
    RESIDENTIAL: 0.0063573,
    // "OTHER RESIDENTIAL": ,
  },
  [2019]: {
    COMMERCIAL: 0.0220217,
    CONDO: 0.006654,
    RESIDENTIAL: 0.006654,
    // "OTHER RESIDENTIAL": ,
  },
  [2020]: {
    COMMERCIAL: 0.0194073,
    CONDO: 0.0075223,
    RESIDENTIAL: 0.0075223,
    // "OTHER RESIDENTIAL": ,
  },
  [2021]: {
    COMMERCIAL: 0.0206076,
    CONDO: 0.0074068,
    RESIDENTIAL: 0.0074068,
    // "OTHER RESIDENTIAL": ,
  },
  [2022]: {
    COMMERCIAL: 0.0219346,
    CONDO: 0.0071498,
    RESIDENTIAL: 0.0071498,
    // "OTHER RESIDENTIAL": ,
  },
  [2023]: {
    COMMERCIAL: 0.0220748,
    CONDO: 0.0065718,
    RESIDENTIAL: 0.0065718,
    // "OTHER RESIDENTIAL": ,
  },
  [2024]: {
    COMMERCIAL: 0.0224569,
    CONDO: 0.0065517,
    RESIDENTIAL: 0.0065517,
    // "OTHER RESIDENTIAL": ,
  },
}

const cochraneRates = {
  [2017]: {
    RESIDENTIAL: 0.00701,
    CONDO: 0.00701,
    COMMERCIAL: 0.00986,
  },
  [2018]: {
    RESIDENTIAL: 0.00714,
    CONDO: 0.00714,
    COMMERCIAL: 0.00994,
  },
  [2019]: {
    RESIDENTIAL: 0.0072562,
    CONDO: 0.0072562,
    COMMERCIAL: 0.0102887,
  },
  [2020]: {
    RESIDENTIAL: 0.0074182,
    CONDO: 0.0074182,
    COMMERCIAL: 0.0102362,
  },
  [2021]: {
    RESIDENTIAL: 0.0074182,
    CONDO: 0.0074182,
    COMMERCIAL: 0.0102362,
  },
}

const edmontonRates = {
  [2016]: {
    COMMERCIAL: 0.0191256,
    CONDO: 0.008004,
    RESIDENTIAL: 0.008004,
    "OTHER RESIDENTIAL": 0.0088504,
  },
  [2017]: {
    COMMERCIAL: 0.0207587,
    CONDO: 0.0085087,
    RESIDENTIAL: 0.0085087,
    "OTHER RESIDENTIAL": 0.0094097,
  },
  [2018]: {
    COMMERCIAL: 0.0212187,
    CONDO: 0.0086869,
    RESIDENTIAL: 0.0086869,
    "OTHER RESIDENTIAL": 0.096166,
  },
  [2019]: {
    COMMERCIAL: 0.0218496,
    CONDO: 0.00908056945642795,
    RESIDENTIAL: 0.00908056945642795,
    "OTHER RESIDENTIAL": 0.0100517,
  },
  [2020]: {
    COMMERCIAL: 0.0222174,
    CONDO: 0.0093257,
    RESIDENTIAL: 0.0093257,
    "OTHER RESIDENTIAL": 0.0103248,
  },
  [2021]: {
    COMMERCIAL: 0.0241488,
    CONDO: 0.00958920,
    RESIDENTIAL: 0.00958920,
    "OTHER RESIDENTIAL": 0.0106408,
  },
  [2022]: {
    COMMERCIAL: 0.0251828,
    CONDO: 0.00938670,
    RESIDENTIAL: 0.00938670,
    "OTHER RESIDENTIAL": 0.0104228,
  },
  [2023]: {
    COMMERCIAL: 0.0244727,
    CONDO: 0.0094475,
    RESIDENTIAL: 0.0094475,
    "OTHER RESIDENTIAL": 0.0104987,
  },
  [2024]: {
    COMMERCIAL: 0.0246666,
    CONDO: 0.0094570,
    RESIDENTIAL: 0.0094570,
    "OTHER RESIDENTIAL": 0.0105093,
  },
}

const leducRates = {
  [2019]: {
    RESIDENTIAL: 0.010133,
    CONDO: 0.010133,
    COMMERCIAL: 0.01296,
  },
  [2020]: {
    RESIDENTIAL: 0.010111,
    CONDO: 0.010111,
    COMMERCIAL: 0.011908,
  },
  [2021]: {
    RESIDENTIAL: 0.010111,
    CONDO: 0.010111,
    COMMERCIAL: 0.011908,
  },
}

const lethbridgeRates = {
  [2017]: {
    RESIDENTIAL: 0.0105611,
    CONDO: 0.0105611,
    COMMERCIAL: 0.022807,
  },

  [2018]: {
    RESIDENTIAL: 0.011067,
    CONDO: 0.011067,
    COMMERCIAL: 0.0236852,
  },

  [2019]: {
    RESIDENTIAL: 0.0112971,
    CONDO: 0.0112971,
    COMMERCIAL: 0.0243296,
  },

  [2020]: {
    RESIDENTIAL: 0.0113977,
    CONDO: 0.0113977,
    COMMERCIAL: 0.0241984,
  },

  [2021]: {
    RESIDENTIAL: 0.0113977,
    CONDO: 0.0113977,
    COMMERCIAL: 0.0241984,
  },
}

const okotoksRates = {
  [2019]: {
    RESIDENTIAL: 0.008013,
    CONDO: 0.008013,
  },
  [2020]: {
    RESIDENTIAL: 0.00809,
    CONDO: 0.00809,
    COMMERCIAL: 0.011912,
  },
  [2021]: {
    RESIDENTIAL: 0.00809,
    CONDO: 0.00809,
    COMMERCIAL: 0.011912,
  },
}

const redDeerRates = {
  [2019]: {
    RESIDENTIAL: 0.0096947,
    CONDO: 0.0096947,
    COMMERCIAL: 0.0178162,
  },
  [2020]: {
    RESIDENTIAL: 0.0098081,
    CONDO: 0.0098081,
    COMMERCIAL: 0.0179502,
  },
  [2021]: {
    RESIDENTIAL: 0.0098081,
    CONDO: 0.0098081,
    COMMERCIAL: 0.0178162,
  },
}

const vancouverRates = {
  [2017]: { RESIDENTIAL: 0.00255489, CONDO: 0.00255489 },
  [2018]: {
    RESIDENTIAL: 0.00246826,
    CONDO: 0.00246826,
  },
  [2019]: { RESIDENTIAL: 0.00256116, CONDO: 0.00256116 },
  [2020]: { RESIDENTIAL: 0.00292568, CONDO: 0.00292568 },
  [2021]: { RESIDENTIAL: 0.0029225, CONDO: 0.0029225 },
  [2022]: { RESIDENTIAL: 0.0029225, CONDO: 0.0029225 },
}

const taxRates = {
  Airdrie: airdrieRates,
  Calgary: calgaryRates,
  Cochrane: cochraneRates,
  Edmonton: edmontonRates,
  Leduc: leducRates,
  Lethbridge: lethbridgeRates,
  Okotoks: okotoksRates,
  "Red Deer": redDeerRates,
  Vancouver: vancouverRates,
}

export { taxRates }
