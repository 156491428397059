import { motion } from "framer-motion"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react"
import { FaSearch } from "react-icons/fa"
import { IoCloseOutline } from "react-icons/io5"
import useKeypress from "react-use-keypress"

import { Input, InputGroup, InputProps, InputRightElement } from "@chakra-ui/input"
import { Box, BoxProps, Link, List, ListItem, Stack, Text } from "@chakra-ui/layout"
import { Popover, PopoverBody, PopoverContent, PopoverContentProps } from "@chakra-ui/popover"
import { Icon, IconButton, Skeleton, useOutsideClick } from "@chakra-ui/react"

const getButtonSize = (
  inputSize: InputProps["size"],
): { size: InputProps["size"]; pr: BoxProps["pr"] } => {
  switch (inputSize) {
    case "md":
      return {
        size: "sm",
        pr: "3px",
      }
    case "lg":
      return {
        size: "md",
        pr: "10px",
      }
  }
}

export type TResultItem = {
  label: string
  render?: (label?: string) => JSX.Element
  onClick?: (value: any) => void
  href?: string
  value?: any
}

export type TResults = { category: string; items: TResultItem[] }[]

export type ISearchBarProps = {
  resultOptions?: TResults
  contentProps?: PopoverContentProps
  loading?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onFocusChanged?: (focused: boolean) => void
  onIconClick?: () => void
} & InputProps

const SearchBar: FC<ISearchBarProps> = ({
  bgColor = "white",
  borderRadius = "3xl",
  size = "md",
  loading = false,
  resultOptions,
  contentProps,
  onChange,
  onFocusChanged,
  onIconClick,
  ...rest
}) => {
  const [value, setValue] = useState<string>("")
  const [focused, setFocused] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(0)
  const [resultItems, setResultItems] = useState<TResultItem[]>([])

  const searchRef = useRef<HTMLInputElement>()
  const contentRef = useRef<HTMLDivElement>()
  const router = useRouter()

  useOutsideClick({
    ref: contentRef,
    handler: () => handleOnBlur(),
  })

  const handleOnFocus = () => setFocused(true)
  const handleOnBlur = () => {
    setFocused(false)
    searchRef?.current?.blur()
  }

  useEffect(() => {
    updateSelected()
  }, [resultOptions])

  useEffect(() => {
    onFocusChanged && onFocusChanged(focused)
  }, [focused])

  const updateSelected = useCallback(() => setSelected(0), [])

  const handleClick = () => {
    if (focused) {
      if (resultItems[selected].href) {
        router.push(resultItems[selected].href)
        handleOnBlur()
      }
      if (resultItems[selected].onClick) {
        resultItems[selected].onClick(resultItems[selected].value)
        handleOnBlur()
      }
    } else {
      router.push("/map")
    }
  }

  useKeypress(["ArrowDown", "ArrowUp", "Enter"], event => {
    if (focused) {
      if (event.key === "ArrowDown") {
        if (!(selected + 1 > resultItems.length - 1)) {
          setSelected(selected + 1)
        } else {
          setSelected(0)
        }
      }

      if (event.key === "ArrowUp") {
        if (!(selected - 1 < 0)) {
          setSelected(selected - 1)
        } else {
          setSelected(resultItems.length - 1)
        }
      }

      if (event.key === "Enter") {
        setValue(resultItems[selected].label)

        if (resultItems[selected].onClick) {
          resultItems[selected].onClick(resultItems[selected].value)
        } else {
          router.push(resultItems[selected].href)
        }

        handleOnBlur()
      }
    }
  })

  const buttonSize = getButtonSize(size)
  const container = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  }
  const itemStyle = {
    hidden: { y: 10, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  const renderResults = () => {
    let index = -1
    const resultItemsArr: TResultItem[] = []

    const items = resultOptions?.map((category, i) => (
      <Box key={i}>
        <motion.div variants={container} initial="hidden" animate="show">
          {category?.category && (
            <ListItem display="flex" w="100%" p="10px 15px">
              <motion.div variants={itemStyle}>
                <Text fontWeight="medium">{category.category}</Text>
              </motion.div>
            </ListItem>
          )}

          {category?.items?.map(item => {
            index++
            resultItems.push(item)

            return (
              <ListItem w="100%" key={index}>
                <motion.div variants={itemStyle}>
                  {!item.onClick ? (
                    <NextLink prefetch={false} href={item.href} passHref>
                      <Link
                        bgColor={index === selected ? "hdSky.faded" : "initial"}
                        display="flex"
                        w="100%"
                        p="5px 20px"
                        _hover={{
                          bgColor: "hdSky.faded",
                          color: "initial",
                        }}
                        _focus={{ bgColor: "hdSky.faded" }}
                        onClick={() => {
                          setValue(item.label)
                          handleOnBlur()
                        }}
                      >
                        {(item?.render && item?.render(item?.label)) || item?.label}
                      </Link>
                    </NextLink>
                  ) : (
                    <Link
                      bgColor={index === selected ? "hdSky.faded" : "initial"}
                      display="flex"
                      w="100%"
                      p="5px 20px"
                      _hover={{
                        bgColor: "hdSky.faded",
                        color: "initial",
                      }}
                      _focus={{ bgColor: "hdSky.faded" }}
                      onClick={() => {
                        if (item.onClick) {
                          setValue(item.label)
                          item.onClick(item?.value)
                          handleOnBlur()
                        }
                      }}
                    >
                      {(item?.render && item?.render(item?.label)) || item?.label}
                    </Link>
                  )}
                </motion.div>
              </ListItem>
            )
          })}
        </motion.div>
      </Box>
    ))

    if (resultItems.length !== index + 1) {
      setResultItems(resultItemsArr)
    }

    return <List>{items}</List>
  }

  const handleChange = e => {
    setValue(e.target.value)
    onChange(e)
  }

  const renderEmpty = () => (
    <Box px="10px">
      <Text>No Results</Text>
    </Box>
  )

  const mdButtonWidth = 40
  const smButtonWidth = 32
  const buttonGap = 2
  const padding = 3

  const oneMdBtnWidth = mdButtonWidth + padding
  const twoMdBtnWidth = mdButtonWidth * 2 + padding + buttonGap
  const oneSmBtnWidth = smButtonWidth + padding
  const twoSmBtnWidth = smButtonWidth * 2 + padding + buttonGap

  const twoBtn = `${buttonSize.size == "md" ? twoMdBtnWidth : twoSmBtnWidth}px`
  const oneBtn = `${buttonSize.size == "md" ? oneMdBtnWidth : oneSmBtnWidth}px`
  return (
    <Popover isOpen={focused && value?.length > 3} autoFocus={false} variant="responsive">
      <Box ref={contentRef} w="100%" position="relative">
        <InputGroup w={rest.width}>
          <Input
            type="text"
            autoComplete="off"
            ref={searchRef}
            onFocus={handleOnFocus}
            onChange={handleChange}
            value={value}
            borderRadius={borderRadius}
            bgColor={bgColor}
            {...rest}
            pr={value.length > 0 ? [twoBtn, twoBtn, oneBtn] : [oneBtn]}
          />
          <InputRightElement
            h="100%"
            // pr={buttonSize.pr}
            // w={value.length > 0 ? [twoBtn, twoBtn, oneBtn] : [oneBtn]}
          >
            <IconButton
              bgColor="white"
              _hover={{ bgColor: "hdBlack.200" }}
              _active={{ bgColor: "hdBlack.200" }}
              color="hdBlack.300"
              size={buttonSize.size}
              borderRadius="3xl"
              aria-label="Search Icon Button"
              icon={<Icon as={IoCloseOutline} fontSize={"1.5em"} />}
              onClick={() => setValue("")}
              display={value.length > 0 ? ["block", "block", "none"] : ["none"]}
              mr={`${buttonGap}px`}
            />

            <IconButton
              bgColor="hdBlack.500"
              _hover={{ bgColor: "hdBlack.400" }}
              _active={{ bgColor: "hdBlack.400" }}
              color="white"
              size={buttonSize.size}
              borderRadius="3xl"
              aria-label="Search Icon Button"
              icon={<Icon as={FaSearch} />}
              onClick={() => {
                if(onIconClick) {
                  onIconClick()
                }
                handleClick()
              }}
            />
          </InputRightElement>
        </InputGroup>

        <PopoverContent
          zIndex="4"
          w="100%"
          py="10px"
          mt={1}
          maxH="320px"
          overflowY="scroll"
          position="absolute"
          top={searchRef?.current?.clientHeight}
          {...contentProps}
          data-cy="hd-search-results"
        >
          <PopoverBody p="0" minW="100%" maxW="fit-content">
            {loading && (
              <Stack px="10px">
                <Skeleton height="20px" w="85%" />
                <Skeleton height="20px" w="75%" />
                <Skeleton height="20px" w="85%" />
              </Stack>
            )}
            {!loading && resultOptions && renderResults()}
            {!loading && !resultOptions && renderEmpty()}
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  )
}

// SearchBar.defaultProps = {
//   bgColor: "white",
//   borderRadius: "3xl",
//   size: "md",
//   loading: false,
// } as ISearchBarProps

export default SearchBar
