import React, { FC } from "react"
import { Button, ButtonProps, useToast } from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import { PrivateLeadCreateInput, useCreatePrivateLeadMutation } from "~/generated/graphql"

interface IPrivateLeadButton {
  text: string
  input: PrivateLeadCreateInput
  afterClick?: () => void
  buttonProps?: ButtonProps
}

export const PrivateLeadButton: FC<IPrivateLeadButton> = ({
  text,
  input,
  afterClick,
  buttonProps,
}) => {
  const { data: session } = useSession()
  const toast = useToast()
  const { mutateAsync: createPrivateLead } = useCreatePrivateLeadMutation()

  const handleClick = async () => {
    try {
      if (session?.user) {
        await createPrivateLead({ input })
      }
      if (afterClick) {
        afterClick()
      }
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top",
        isClosable: true,
      })
    }
  }

  return (
    <Button onClick={handleClick} {...buttonProps}>
      {text}
    </Button>
  )
}
