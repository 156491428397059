import isEqual from "lodash/isEqual"
import { action, computed, makeAutoObservable } from "mobx"
import { enableStaticRendering } from "mobx-react-lite"
import { HomeType, RangeFilter, SortFilter, SortOrder } from "~/generated/graphql"

import { store, updateMapQueryParams } from "../StoreProvider"
import { FilterCategory } from "../mapFilters"

enableStaticRendering(typeof window === "undefined")

export type CreaFilters = {
  page: number
  beds: RangeFilter
  baths: RangeFilter
  price: RangeFilter
  homeType: HomeType[]
  sort: SortOption
}

export enum SortOption {
  MOST_RECENT = "MOST_RECENT",
  OLDEST = "OLDEST",
  MOST_EXPENSIVE = "MOST_EXPENSIVE",
  LEAST_EXPENSIVE = "LEAST_EXPENSIVE",
}

export const sortOptions: Record<SortOption, SortFilter> = {
  [SortOption.MOST_RECENT]: { field: "createdAt", order: SortOrder.Desc },
  [SortOption.OLDEST]: { field: "createdAt", order: SortOrder.Asc },
  [SortOption.MOST_EXPENSIVE]: { field: "price", order: SortOrder.Desc },
  [SortOption.LEAST_EXPENSIVE]: { field: "price", order: SortOrder.Asc },
}

export const defaultCreaFilters: CreaFilters = {
  page: 1,
  beds: { gte: 0, lte: null },
  baths: { gte: 0, lte: null },
  price: { gte: null, lte: null },
  homeType: [],
  sort: SortOption.MOST_RECENT,
}

export class CreaFiltersStore {
  page: number = defaultCreaFilters.page
  beds: RangeFilter = defaultCreaFilters.beds
  baths: RangeFilter = defaultCreaFilters.baths
  price: RangeFilter = defaultCreaFilters.price
  homeType: HomeType[] = defaultCreaFilters.homeType
  sort: SortOption = defaultCreaFilters.sort

  constructor() {
    makeAutoObservable(this, {
      setPage: action,
      setBeds: action,
      setBaths: action,
      setPrice: action,
      setHomeType: action,
      setSort: action,
      setFilters: action,
      resetFilters: action,
      resetDisabled: computed,
      filters: computed,
      isFiltered: computed,
      isDisabled: computed,
      activeFiltersCount: computed,
      hydrate: action,
    })
  }

  setPage = (page: number): void => {
    this.page = page
    updateMapQueryParams({ filters: { crea: { page } } }, { skipRouter: true, scroll: true })
  }

  setBeds = (beds: RangeFilter): void => {
    this.page = 1
    this.beds = beds
    updateMapQueryParams(
      { filters: { crea: { beds, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setBaths = (baths: RangeFilter): void => {
    this.page = 1
    this.baths = baths
    updateMapQueryParams(
      { filters: { crea: { baths, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setPrice = (price: RangeFilter): void => {
    this.page = 1
    this.price = price
    updateMapQueryParams(
      { filters: { crea: { price, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setSort = (option: SortOption): void => {
    this.page = 1
    this.sort = option
    updateMapQueryParams(
      { filters: { crea: { sort: option, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setHomeType = (homeType: HomeType[]): void => {
    this.page = 1
    this.homeType = homeType
    updateMapQueryParams(
      { filters: { crea: { homeType, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setFilters = (filters: {
    beds?: RangeFilter
    baths?: RangeFilter
    price?: RangeFilter
    homeType?: HomeType[]
  }): void | Promise<boolean> =>
    updateMapQueryParams({ filters: { crea: { ...filters, page: 1 } } })

  resetFilters = (): void => {
    this.page = 1
    this.beds = { gte: 0, lte: null }
    this.baths = { gte: 0, lte: null }
    this.price = { gte: null, lte: null }
    this.homeType = []

    updateMapQueryParams(
      {
        filters: {
          crea: {
            beds: { gte: 0, lte: null },
            baths: { gte: 0, lte: null },
            price: { gte: null, lte: null },
            homeType: [],
            page: 1,
          },
        },
      },
      { skipRouter: true, scroll: true },
    )
  }

  get filters(): Omit<CreaFilters, "sort"> & { sort: SortFilter } {
    return {
      page: this.page - 1,
      beds: this.beds,
      baths: this.baths,
      price: this.price,
      sort: sortOptions[this.sort],
      homeType: this.homeType,
    }
  }

  get isFiltered(): boolean {
    return (
      this.beds.gte !== 0 ||
      this.baths.gte !== 0 ||
      this.price.gte !== null ||
      this.price.lte !== null ||
      this.homeType.length > 0
    )
  }

  get resetDisabled(): boolean {
    return !this.isFiltered
  }

  get isDisabled(): boolean {
    return (
      (store?.recentlySoldFiltersStore.isFiltered &&
        store?.mapFiltersStore.filterCategory === FilterCategory.RecentlySold) ||
      (store?.mapFiltersStore.filterCategory === FilterCategory.Properties &&
        store?.propertyFiltersStore.isFiltered)
    )
  }

  get activeFiltersCount(): number {
    return Object.keys(this.filters).reduce((count, key) => {
      if (["sort", "page"].includes(key)) return count
      return isEqual(this.filters[key], defaultCreaFilters[key]) ? count : count + 1
    }, 0)
  }

  hydrate = (data: this): void => {
    if (!data) return

    if (data.page) {
      this.page = data.page
    }

    if (data.beds) {
      this.beds = data.beds
    }

    if (data.baths) {
      this.baths = data.baths
    }

    if (data.price) {
      this.price = data.price
    }

    if (data.sort) {
      this.sort = data.sort
    }

    if (data.homeType) {
      this.homeType = data.homeType
    }
  }
}
