import React, { FC } from "react"
import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react"
import { PrivateLeadCreateInput } from "~/generated/graphql"
import { AskAboutPropertyModal } from "~/components/modules/private-lead-button/ask-about-property.modal"

interface IAskAboutPropertyButton {
  input: PrivateLeadCreateInput
  text?: string
  buttonProps?: ButtonProps
}

export const AskAboutPropertyButton: FC<IAskAboutPropertyButton> = ({
  text = "Ask about this home",
  input,
  buttonProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} {...buttonProps}>
        {text}
      </Button>

      <AskAboutPropertyModal input={input} isOpen={isOpen} onClose={onClose} />
    </>
  )
}
