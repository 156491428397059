import lodashRound from "lodash/round"
import { MEASUREMENT_UNITS } from "~/utils/constants"

export function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
export function convertToSquareFeet(squareMeters: number) {
  return squareMeters * 10.764
}

export function convertToSquareMeters(squareFeet: number) {
  return squareFeet / 10.764
}

export function convertMeasurement(
  value: number,
  from: ValueOf<typeof MEASUREMENT_UNITS>,
  to: ValueOf<typeof MEASUREMENT_UNITS>,
) {
  // sqm -> sqft
  if (from === MEASUREMENT_UNITS.SQM && to === MEASUREMENT_UNITS.SQFT) {
    return lodashRound(value * 10.764, 2)
  }

  // sqft -> sqm
  if (from === MEASUREMENT_UNITS.SQFT && to === MEASUREMENT_UNITS.SQM) {
    return lodashRound(value / 10.764, 2)
  }

  // acre -> sqm, 1 acre = 43560 sqft
  if (from === MEASUREMENT_UNITS.ACRES && to === MEASUREMENT_UNITS.SQM) {
    return lodashRound(value * 4046.86, 2)
  }

  // sqm -> acre
  if (from === MEASUREMENT_UNITS.SQM && to === MEASUREMENT_UNITS.ACRES) {
    return lodashRound(value / 4046.86)
  }

  throw new Error("Not implemented")
}

/**
 * Returns a number rounded up to a nearest multiple.
 *
 * @param {number} n A number
 * @param {number} [m=1] A multiple rounding target
 * @return {number} A number rounded to the next multiple of m
 *
 * @example
 * roundUp(1433.35, 50) // 1450
 */
export function roundUp(n: number, m = 1): number {
  return Math.ceil(n / m) * m
}

/**
 * Returns a number rounded to a nearest multiple.
 *
 * @param {number} n A number
 * @param {number} [m=1] A multiple rounding target
 * @return {number} A number rounded to the next multiple of m
 *
 * @example
 * round(1433.35, 50) // 1450
 */
export function round(n: number, m = 1) {
  return Math.round(n / m) * m
}

/**
 * Returns a number rounded down to a nearest multiple.
 *
 * @param {number} n A number
 * @param {number} [m=1] A multiple rounding target
 * @return {number} A number rounded to the next multiple of m
 *
 * @example
 * round(1433.35, 50) // 1450
 */
export function roundDown(n: number, m = 1) {
  return Math.floor(n / m) * m
}

/**
 * Returns an average of a given array
 * @param arr
 */
export function avg(arr: number[]) {
  return arr.reduce((a, b) => a + b) / arr.length
}
