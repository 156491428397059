import { chakra } from "@chakra-ui/react"
import { default as React } from "react"
import { IconProps } from "./types"

export const IconHonestdoor = (props: IconProps): JSX.Element => {
  const localProps = { ...props }
  delete localProps.light

  return (
    <chakra.svg viewBox="0 0 37 37" {...localProps} >
      <g id="Honestdoor-Icon" fill="none" fillRule="evenodd">
        <g id="Glass" transform="translate(6)">
          <path
            d="M30.532 14.987c0 8.278-6.706 14.988-14.979 14.988-8.272 0-14.978-6.71-14.978-14.988C.575 6.71 7.28 0 15.553 0c8.273 0 14.98 6.71 14.98 14.987"
            id="Fill-1"
            fill={"#C4985F"}
          />
          <path
            d="M22.58 12.406l-6.058-5.478a1.44 1.44 0 0 0-.969-.371 1.44 1.44 0 0 0-.968.371l-6.058 5.478a1.4 1.4 0 0 0-.463 1.038v6.69c0 .78.64 1.41 1.431 1.41h3.25v-2.81a2.818 2.818 0 0 1 2.808-2.81 2.818 2.818 0 0 1 2.809 2.81v2.81h3.25c.79 0 1.43-.63 1.43-1.41v-6.69a1.4 1.4 0 0 0-.462-1.038"
            id="Fill-3"
            fill={"#FFF"}
          />
        </g>
        <g id="Handle" transform="translate(0 26.25)">
          <path id="Clip-6" d="M0 .332h9.966v9.972H0z" />
          <path
            d="M6.852.332L.92 5.56a2.711 2.711 0 0 0 0 4.068c1.122.989 2.834.88 3.823-.243l5.224-5.937A18.865 18.865 0 0 1 6.852.332"
            id="Fill-5"
            fill={"#C4985F"}
          />
        </g>
      </g>
    </chakra.svg>
  )
}
