


export function ordinalize(n: number): string {
  let suffix = "th"
  if (n < 10 || n > 20) {
    switch (n.toString().slice(-1)[0]) {
      case "1":
        suffix = "st"
        break
      case "2":
        suffix = "nd"
        break
      case "3":
        suffix = "rd"
        break
      default:
        suffix = "th"
        break
    }
  }

  return n ? `${n}${suffix}` : ""
}

/**
 * Abbreviates money to 3 digits and appends a scale symbol (i.e. B for billions,
 * M for millions, etc.)
 * @param n A number that represents a monetary value
 */
export function abbrMoney(n: number): string {
  if (n >= 1e9) {
    return (Number(n.toPrecision(3)) / 1e9).toLocaleString() + "B"
  } else if (n >= 1e6) {
    return (Number(n.toPrecision(3)) / 1e6).toLocaleString() + "M"
  } else if (n >= 1e3) {
    return (Number(n.toPrecision(3)) / 1e3).toLocaleString() + "K"
  } else {
    return Math.round(n).toLocaleString()
  }
}
