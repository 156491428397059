import { useDisclosure, UseDisclosureProps, UseDisclosureReturn } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export type useModalDisclosureProps = Partial<UseDisclosureProps>
export type useModalDisclosureReturn = Partial<UseDisclosureReturn> & { hasOpened: boolean }

export const useModalDisclosure = (_props?: useModalDisclosureProps): useModalDisclosureReturn => {
  const [hasOpened, setHasOpened] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (!hasOpened && isOpen) setHasOpened(true)
  }, [isOpen])

  return { isOpen, onOpen, onClose, hasOpened: isOpen || hasOpened }
}
