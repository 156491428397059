export const popularCities = [
  "Abbotsford, BC",
  "Airdrie, AB",
  "Ajax, ON",
  "Barrie, ON",
  "Belleville, ON",
  "Bracebridge, ON",
  "Brampton, ON",
  "Brandon, MB",
  "Brantford, ON",
  "Burlington, ON",
  "Burnaby, BC",
  "Calgary, AB",
  "Cambridge, ON",
  "Canmore, AB",
  "Cape Breton, NS",
  "Charlottetown, PE",
  "Chilliwack, BC",
  "Collingwood, ON",
  "Coquitlam, BC",
  "Delta, BC",
  "Edmonton, AB",
  "Etobicoke, ON",
  "Fort McMurray, AB",
  "Grande Prairie, AB",
  "Gravenhurst, ON",
  "Guelph, ON",
  "Halifax, NS",
  "Hamilton, ON",
  "Iqaluit, NU",
  "Kamloops, BC",
  "Kanata, ON",
  "Kawartha Lakes, ON",
  "Kelowna, BC",
  "Kingston, ON",
  "Kitchener, ON",
  "Langley, BC",
  "Lethbridge, AB",
  "London, ON",
  "Maple Ridge, BC",
  "Milton, ON",
  "Mississauga, ON",
  "Moncton, NB",
  "Montreal, QC",
  "Nanaimo, BC",
  "Niagara Falls, ON",
  "North Vancouver, BC",
  "North York, ON",
  "Oakville, ON",
  "Oshawa, ON",
  "Ottawa, ON",
  "Peterborough, ON",
  "Pickering, ON",
  "Prince George, BC",
  "Red Deer, AB",
  "Regina, SK",
  "Richmond, BC",
  "Richmond Hill, ON",
  "Saint John, NB",
  "Saskatoon, SK",
  "Sault Ste. Marie, ON",
  "Scarborough, ON",
  "Sherwood Park, AB",
  "St. Catharines, ON",
  "St. John's, NL",
  // "Sudbury, ON",  // doesn't exist in DB
  "Surrey, BC",
  "Thunder Bay, ON",
  "Toronto, ON",
  "Vancouver, BC",
  "Vaughan, ON",
  "Victoria, BC",
  "Waterloo, ON",
  "West Vancouver, BC",
  "Whistler, BC",
  "Whitby, ON",
  "Whitehorse, YT",
  "Windsor, ON",
  "Winnipeg, MB",
  "Yellowknife, NT",
  "York, ON",
]

export const citiesWeLike = [
  "Toronto",
  "Calgary",
  "Edmonton",
  "Montreal",
  "Ottawa",
  "Hamilton",
  "Mississauga",
  "Winnipeg",
  "Vancouver",
  "Kitchener",
  "London",
  "Halifax",
  "Surrey",
  "Brampton",
  "Scarborough",
  "North York",
  "Vaughan",
  "Oshawa",
  "Oakville",
  "Regina",
  "Burlington",
  "Etobicoke",
  "Guelph",
  "Kingston",
  "Richmond",
  "Burnaby",
  "Saskatoon",
  "Barrie",
  "St. Catharines",
  "Cambridge",
  "Thunder Bay",
  "Langley",
  "Milton",
  "Red Deer",
  "Cape Breton",
  "Windsor",
  "Brantford",
  "Victoria",
  "North Vancouver",
  "Kelowna",
  "Richmond Hill",
  "Coquitlam",
  "Kanata",
  "Sherwood Park",
  "St. John's",
  "York",
  "Abbotsford",
  "Niagara Falls",
  "Grande Prairie",
  "Waterloo",
  "Sault Ste. Marie",
  "Nanaimo",
  "Peterborough",
  "Airdrie",
  "Kamloops",
  "Moncton",
  "Delta",
  "Prince George",
  "Whitby",
  "Lethbridge",
  "Chilliwack",
  "Belleville",
  "Maple Ridge",
  "Pickering",
  "Ajax",
  "Sudbury",
  "Charlottetown",
  "Saint John",
  "Fort McMurray",
  "Brandon",
  "West Vancouver",
  "Collingwood",
  "Kawartha Lakes",
  "Whistler",
  "Canmore",
  "Whitehorse",
  "Bracebridge",
  "Gravenhurst",
  "Yellowknife",
  "Iqaluit",
]

export const priceValuesMin = [
  0,
  50000,
  75000,
  100000,
  125000,
  150000,
  175000,
  200000,
  225000,
  250000,
  275000,
  300000,
  325000,
  350000,
  375000,
  400000,
  425000,
  450000,
  475000,
  500000,
  550000,
  600000,
  650000,
  700000,
  750000,
  800000,
  850000,
  900000,
  950000,
  1000000,
  2000000,
  3000000,
  4000000,
  5000000,
  6000000,
  7000000,
  8000000,
  9000000,
  10000000,
]
export const priceValuesMax = [
  50000,
  75000,
  100000,
  125000,
  150000,
  175000,
  200000,
  225000,
  250000,
  275000,
  300000,
  325000,
  350000,
  375000,
  400000,
  425000,
  450000,
  475000,
  500000,
  550000,
  600000,
  650000,
  700000,
  750000,
  800000,
  850000,
  900000,
  950000,
  1000000,
  2000000,
  3000000,
  4000000,
  5000000,
  6000000,
  7000000,
  8000000,
  9000000,
  10000000,
  1000000000,
]

export const bedValuesMin = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const bedValuesMax = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 100]
export const bathValuesMin = [0, 1, 2, 3, 4, 5, 6]
export const bathValuesMax = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 100]

export const buildingTypeOptionsForDropdown = [
  "All",
  "House",
  "Manufactured Home",
  "Apartment",
  "Townhouse",
  "Duplex",
  "Mobile Home",
  "Recreational",
  "Multi-Family",
  "Two Apartment House",
  "Other",
]

export const sortOptions = {
  "createdAt,desc": "Most recent first",
  "price,desc": "Price (High to Low)",
  "price,asc": "Price (Low to High)",
}
