import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import { Button, Img, ListItem, Text, UnorderedList, VStack, Flex, Box } from "@chakra-ui/react"
import { addDays } from "date-fns/addDays"
import { ButtonVariants } from "~/theme/components"
import theme from "~/theme"
import gtag from "~/utils/lib/gtag"

const POPUP_LIST_ON_MLS = "popup-list-on-mls"

export const PopupListOnMls = () => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (
      !["/sell-with-honestdoor"].includes(router.asPath) &&
      ["/submit-listing", "/dashboard", "/auth"].every(item => !router.asPath.includes(item))
    ) {
      const closedAt = getClosedAt()
      if (!closedAt || (closedAt && new Date() > addDays(closedAt, 28))) {
        const timer = setTimeout(() => {
          setIsOpen(true)
        }, 45 * 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [])

  const getClosedAt = () => {
    try {
      const closedAtItem = localStorage.getItem(POPUP_LIST_ON_MLS)
      if (!closedAtItem) return null

      return new Date(JSON.parse(closedAtItem))
    } catch (e) {
      return null
    }
  }

  const onClose = () => {
    localStorage.setItem(POPUP_LIST_ON_MLS, JSON.stringify(new Date()))
    setIsOpen(false)
  }

  const navigateTo = () => {
    gtag.event({
      category: "MLS",
      action: "mls_banner_clicked",
    })
    setIsOpen(false)
    router.push("/sell-with-honestdoor")
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={false}
      isCentered
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor={theme.colors.hdBlack["base"]}
        fontFamily={"Roboto"}
        fontSize="16px"
        color="white"
        borderRadius="16px"
        padding="24px"
        paddingTop="32px"
      >
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems="start">
            <Flex alignItems="center" direction={["column", "column", "row"]} width="100%">
              <Text
                lineHeight="28.8px"
                fontSize="24px"
                fontWeight="600"
                marginRight={[0, 0, "8px"]}
              >
                Welcome to
              </Text>

              <Box position="relative" top="5px">
                <Img src="/icons/hd-full.icon.svg" height="34px" />
              </Box>
            </Flex>

            <Text lineHeight="24px" fontWeight="500" marginTop="8px">
              Now proudly listing Canadians homes on REALTOR.ca for $500
            </Text>

            <UnorderedList color="#C9CDD9" lineHeight="20.8px" marginTop="20px" marginBottom="20px">
              <ListItem>Save big on commission</ListItem>
              <ListItem>Go live within 24 hours</ListItem>
              <ListItem>No hidden costs</ListItem>
              <ListItem>Full transparency</ListItem>
            </UnorderedList>

            <Flex justifyContent={["center", "center", "start"]} width="100%">
              <Button
                onClick={navigateTo}
                variant={ButtonVariants.Secondary}
                _hover={{}}
                border="none"
              >
                Get Started
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
