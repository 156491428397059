import { FC, useEffect } from "react"
import { useRouter } from "next/router"
import { Button, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react"
import { useAuth, useUser } from "~/components/hooks"
import { CMAReportModal } from "~/components/modules"
import { EsProperty, useCreatePrivateLeadMutation } from "~/generated/graphql"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"
import gtag from "~/utils/lib/gtag"

interface ICmaButton {
  property: EsProperty
  buttonProps?: ButtonProps
  text?: string
}

export const CmaButton: FC<ICmaButton> = ({
  property,
  buttonProps,
  text = "Market Evaluation Report",
}) => {
  const { user } = useUser()
  const { openSignInModal } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const {
    query: { checkout_success },
  } = useRouter()
  const { mutateAsync: createPrivateLead } = useCreatePrivateLeadMutation()

  useEffect(() => {
    if (checkout_success === "true") {
      gtag.event({
        action: "cma_button_stripe_paid_success",
      })

      toast({
        status: "success",
        title: "Success!",
        description: "You have successfully paid for the CMA report!",
        position: "top-right",
        isClosable: true,
      })
    }
  }, [checkout_success])

  const handleOnClick = async () => {
    try {
      if (!user) {
        return openSignInModal()
      }
      gtag.event({
        action: "cma_button_clicked",
      })
      await createPrivateLead({
        input: {
          propertyId: property.id,
          province: property?.province,
          source: PrivateLeadSource.CmaClick,
          type: PrivateLeadType.Sell,
          cityId: property?.city,
          cityName: property?.cityName,
          streetAddress: property?.unparsedAddress,
          slug: property?.slug,
          hdPrice: property?.predictedValue,
          neighbourhoodId: property?.neighbourhood,
          neighbourhoodName: property?.neighbourhoodName,
        },
      })
      onOpen()
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top",
        isClosable: true,
      })
    }
  }

  return (
    <>
      <Button variant="secondary" onClick={handleOnClick} {...buttonProps}>
        {text}
      </Button>

      <CMAReportModal property={property} isOpen={isOpen} onClose={onClose} />
    </>
  )
}
