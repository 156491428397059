import { FC, Children, ReactNode } from "react"
import { Grid, GridItem, GridItemProps, GridProps } from "@chakra-ui/layout"

export type TMultiGridProps = {
  children: ReactNode
  itemMinW?: number
  gridItemProps?: GridItemProps
} & GridProps

export const MultiGrid: FC<TMultiGridProps> = props => {
  const { itemMinW, gridItemProps, children, ...rest } = props

  return (
    <Grid gridTemplateColumns={`repeat(auto-fill, minmax(${itemMinW}px, 1fr))`} {...rest}>
      {Children.map(children, child => (
        <GridItem {...gridItemProps}>{child}</GridItem>
      ))}
    </Grid>
  )
}

MultiGrid.defaultProps = {
  itemMinW: 345,
  gap: 8,
  w: "100%",
} as TMultiGridProps
