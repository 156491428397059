import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { isPrivateListing, isPublicListing, isUnlisted } from "~/utils/helpers/common.helper"

export function formatTaxValue(val) {
  return `$${Number(val).toLocaleString()} ($${(Number(val) % 12 == 0
    ? Number(val) / 12
    : (Number(val) / 12).toFixed(2)
  ).toLocaleString()}/month)`
}

export function getListedPrice(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return property?.predictedValue
  }
  if (isPrivateListing(property)) {
    return property?.askingPrice
  }
  if (isPublicListing(property)) {
    return property?.price
  }
}

// TODO: This naming is actually rather confusing as `estimate` refers to city assessed value
// this should be renamed to `getPredictedPrice` or something similar
export function getEstimatedPrice(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return { estimatedPrice: property?.predictedValue, estimatedPriceDate: property?.predictedDate }
  }
  return { estimatedPrice: undefined, estimatedPriceDate: undefined }
}

export function getSoldPrice(property: EsProperty | PrivateListing | CreaListing) {
  if (isUnlisted(property)) {
    return { soldPrice: property?.closePrice, soldPriceDate: property?.closeDate }
  }
  // TODO: clarify this
  // if (isPrivateListing(property)) {
  //   return { soldPrice: property?.soldPrice, soldPriceDate: property?.reportedSoldAt }
  // }
  return { soldPrice: undefined, soldPriceDate: undefined }
}

export function getPrices(property: EsProperty | PrivateListing | CreaListing) {
  const listedPrice = getListedPrice(property)
  const { estimatedPrice, estimatedPriceDate } = getEstimatedPrice(property)
  const { soldPrice, soldPriceDate } = getSoldPrice(property)
  const isSold = !!soldPrice
  const isForSale = !!listedPrice && !isSold

  return {
    listedPrice,
    estimatedPrice,
    estimatedPriceDate,
    soldPrice,
    soldPriceDate,
    isSold,
    isForSale,
  }
}

export function calculateCommission(homeValue: number, provinceCode: string, type = "buy") {
  // Define standard commission rates and thresholds for each province
  const commissionRates = {
    AB: {
      rate: 0.035,
      threshold: 100000,
      rateRemaining: 0.015,
      maxRate: 0.035,
      maxThreshold: 999999999,
    },
    BC: {
      rate: 0.035,
      threshold: 100000,
      rateRemaining: 0.0125,
      maxRate: 0.035,
      maxThreshold: 999999999,
    },
    MB: {
      rate: 0.03,
      threshold: 100000,
      rateRemaining: 0.015,
      maxRate: 0.03,
      maxThreshold: 999999999,
    },
    NB: {
      rate: 0.025,
      threshold: 100000,
      rateRemaining: 0.0125,
      maxRate: 0.025,
      maxThreshold: 999999999,
    },
    NL: {
      rate: 0.025,
      threshold: 100000,
      rateRemaining: 0.0125,
      maxRate: 0.025,
      maxThreshold: 999999999,
    },
    NS: {
      rate: 0.025,
      threshold: 100000,
      rateRemaining: 0.0125,
      maxRate: 0.025,
      maxThreshold: 999999999,
    },
    ON: {
      rate: 0.025,
      threshold: 100000,
      rateRemaining: 0.025,
      maxRate: 0.025,
      maxThreshold: 999999999,
    },
    PE: {
      rate: 0.025,
      threshold: 100000,
      rateRemaining: 0.0125,
      maxRate: 0.025,
      maxThreshold: 999999999,
    },
    QC: {
      rate: 0.025,
      threshold: 100000,
      maxRate: 0.035,
      maxThreshold: 500000,
      rateRemaining: 0.01,
    },
    SK: {
      rate: 0.025,
      threshold: 100000,
      rateRemaining: 0.0125,
      maxRate: 0.025,
      maxThreshold: 999999999,
    },
  }

  // Check if the province code is valid
  const provinceCodeUpper = provinceCode.toUpperCase()
  if (!(provinceCodeUpper in commissionRates)) {
    console.error("Invalid province code:", provinceCode)
    return { error: "Invalid province code" }
  }
  // Retrieve commission rate and threshold for the given province
  const { rate, maxRate, threshold, maxThreshold, rateRemaining } =
    commissionRates[provinceCodeUpper]
  // Validate homeValue
  if (isNaN(homeValue) || homeValue < 0) {
    console.error("Invalid home value:", homeValue)
    return { error: "Invalid home value" }
  }
  // Calculate the standard commission
  let standardCommission
  console.log("Home Value:", homeValue)
  if (homeValue <= threshold) {
    standardCommission = homeValue * rate
  } else if (homeValue <= maxThreshold) {
    standardCommission = threshold * rate + (homeValue - threshold) * rateRemaining
  } else {
    standardCommission =
      threshold * rate +
      (maxThreshold - threshold) * rateRemaining +
      (homeValue - maxThreshold) * maxRate
  }
  // Check for valid numeric result
  if (isNaN(standardCommission)) {
    console.error("Invalid standard commission result")
    return { error: "Invalid standard commission result" }
  }
  console.log("Standard Commission:", standardCommission)

  let amountSaved
  if (type === "buy") {
    amountSaved = Math.floor(standardCommission - standardCommission * (30 / 100))
  } else {
    amountSaved = Math.floor(standardCommission)
  }

  console.log("Amount Saved:", amountSaved)

  return {
    amountSaved,
    // standardCommissionRate: `*${(rate * 100).toFixed(2)}% on the first $${threshold.toLocaleString()} of the sale price, and ${(rateRemaining * 100).toFixed(2)}% on the remaining sale price`,
    rate,
    threshold,
    rateRemaining,
  }
}
