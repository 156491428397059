import { FC } from "react"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { observer } from "mobx-react-lite"
import { useDisclosure } from "@chakra-ui/hooks"
import { Icon } from "@chakra-ui/icon"
import { FaMapMarkedAlt } from "react-icons/fa"
import Navbar from "../Navbar"
import { useAuth, useUser } from "~/components/hooks"
import gtag from "~/utils/lib/gtag"
import { useCreatePrivateLeadMutation } from "~/generated/graphql"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"

const DrawerMenu = dynamic(() => import("../drawer-menu/drawer-menu").then(mod => mod.DrawerMenu), {
  ssr: false,
})

const Nav: FC = () => {
  const { signOut, openSignInModal } = useAuth()
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const { user } = useUser()
  const router = useRouter()
  const transparent = router.pathname === "/"
  const { mutateAsync: createPrivateLead } = useCreatePrivateLeadMutation()

  const navBarItems = [
    {
      key: "/map",
      label: "Map",
      href: "/map",
      buttonProps: {
        leftIcon: <Icon as={FaMapMarkedAlt} />,
      },
    },
    {
      key: "buy",
      label: "Buy",
      subMenuItems: [
        {
          key: "/cashback",
          label: "Cashback",
          description: "Buy with HonestDoor to receive cashback",
          href: "/buy-with-honestdoor",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
        {
          key: "/real-estate",
          label: "MLS® Listings",
          description: "Browse all listings across Canada on REALTOR.ca",
          href: "/real-estate",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
        {
          key: "/exclusive-listings",
          label: "HonestDoor Listings",
          description: "Browse HonestDoor listings and negotiate directly with the seller",
          href: "/exclusive-listings",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
      ],
    },
    {
      key: "list-on-mls",
      href: "/sell-with-honestdoor",
      label: "Sell",
      buttonProps: {
        onClick: () => {
          gtag.event({
            action: "Menu_bar_list_on_mls",
          })
        },
      },
    },

    {
      key: "/recently-sold",
      label: "Recently Sold",
      href: "/recently-sold"
    },
    {
      key: "/browse",
      label: "Browse",
      subMenuItems: [
        {
          key: "/cities",
          label: "Cities",
          href: "/cities",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
        {
          key: "/permits",
          label: "Permits",
          href: "/permits",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
      ],
    },
    {
      key: "tools",
      label: "Tools",
      subMenuItems: [
        {
          key: "tools",
          label: "Home Valuation Tool for Real Estate Professionals",
          description: "Generate and Nurture Leads",
          href: "/home-valuation-tool",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
        {
          key: "/products/market-evaluation-report",
          label: "Comprehensive Market Evaluation Report",
          description: "Custom reports for properties including solds & photos",
          href: "/products/market-evaluation-report",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
        {
          key: "/homebuilders-and-commercial",
          label: "Businesses with Multiple Listings",
          description: "List your properties on MLS® & REALTOR.ca for maximum exposure",
          href: "/homebuilders-and-commercial",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
          },
        },
        {
          key: "/for-business",
          label: "Business Solutions",
          description: "Property data API, advertising, reports",
          href: "/for-business",
          linkProps: {
            color: "hdGold.full",
            fontWeight: "bold",
            onClick: async () => {
              if (user) {
                await createPrivateLead({
                  input: {
                    source: PrivateLeadSource.BusinessPage,
                    type: PrivateLeadType.Business,
                  },
                })
              }
            },
          },
        },
      ],
    },
  ]

  return (
    <>
      <Navbar
        user={user}
        onDrawerBtnClick={onToggle}
        transparent={transparent}
        menuItems={navBarItems}
        activeMenuItem={router.pathname}
        onSignInBtnClick={openSignInModal}
      />
      <DrawerMenu
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        onLogin={openSignInModal}
        onLogout={signOut}
      />
    </>
  )
}

export default observer(Nav)
