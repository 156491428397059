import isNil from "lodash/isNil"

export function formatStreet(str: string): string {
  if (isNil(str)) return ""

  return str
    .toLowerCase()
    .trim()
    .replace(/\s+(e|n|ne|nw|s|se|sw|w)$/, substr => substr.toUpperCase())
    .replace(/[^\s]+/g, substr => substr[0].toUpperCase() + substr.slice(1))
    .replace(/^\d+[a-z]+\s/, substr => substr.toUpperCase());
}

/**
 * Replaces numbers with Xs, strips out non-letters, trims and removes duplicate
 * spaces. Primarily used to normalize GTM actions.
 * @param str a string
 */
export function genericizeText(str: string): string {
  if (isNil(str)) return
  if (typeof str !== "string") {
    throw new TypeError(`@str must be a "string"`)
  }

  return str
    ?.replace(/\d+/g, "XX")
    .replace(/[^A-Za-z\s]/g, "")
    .replace(/\s+/g, " ")
    .trim();
}

/**
 * Simple regex validation of email string.
 * @param str an email
 */
export function isEmailValid(str: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}
