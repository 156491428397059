const Tabs = {
  variants: {
    unstyled: {
      tab: {
        _focus: { boxShadow: "none" },
      },
    },

    // custom
    v2: {
      tab: {
        color: "hdBlack.faded100",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "30px",
        pt:0,
        px: 0,
        _selected: {
          color: "#282F45",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "24px",
        },
        _focus: { boxShadow: "none" },
      },
      tabpanel: {
        px: 0,
        mt: 2,
      },

      tablist: {
        display: "flex",
        gridGap: 15,
      },
    },
  },
}

export default Tabs
