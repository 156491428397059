export const IconGooglyEyes = (): JSX.Element => (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0647 10C11.0647 12.547 10.4353 14.8289 9.44399 16.4575C8.44728 18.0951 7.13802 19 5.78235 19C4.42668 19 3.11742 18.0951 2.12072 16.4575C1.12941 14.8289 0.5 12.547 0.5 10C0.5 7.45296 1.12941 5.1711 2.12072 3.54245C3.11742 1.90493 4.42668 1 5.78235 1C7.13802 1 8.44728 1.90493 9.44399 3.54245C10.4353 5.1711 11.0647 7.45296 11.0647 10Z"
      fill="white"
      stroke="#B08950"
    />
    <path
      d="M3.3917 14.2056C5.21634 14.2056 6.6955 12.6657 6.6955 10.7661C6.6955 8.86657 5.21634 7.32666 3.3917 7.32666C1.56706 7.32666 0.0878906 8.86657 0.0878906 10.7661C0.0878906 12.6657 1.56706 14.2056 3.3917 14.2056Z"
      fill="#B08950"
    />
    <path
      d="M3.39279 13.1982C4.683 13.1982 5.72893 12.1093 5.72893 10.7661C5.72893 9.42287 4.683 8.33398 3.39279 8.33398C2.10257 8.33398 1.05664 9.42287 1.05664 10.7661C1.05664 12.1093 2.10257 13.1982 3.39279 13.1982Z"
      fill="#B08950"
    />
    <path
      d="M24.5002 10C24.5002 12.547 23.8708 14.8289 22.8795 16.4575C21.8828 18.0951 20.5736 19 19.2179 19C17.8622 19 16.553 18.0951 15.5563 16.4575C14.565 14.8289 13.9355 12.547 13.9355 10C13.9355 7.45296 14.565 5.1711 15.5563 3.54245C16.553 1.90493 17.8622 1 19.2179 1C20.5736 1 21.8828 1.90493 22.8795 3.54245C23.8708 5.1711 24.5002 7.45296 24.5002 10Z"
      fill="white"
      stroke="#B08950"
    />
    <path
      d="M16.8272 14.2056C18.6519 14.2056 20.1311 12.6657 20.1311 10.7661C20.1311 8.86657 18.6519 7.32666 16.8272 7.32666C15.0026 7.32666 13.5234 8.86657 13.5234 10.7661C13.5234 12.6657 15.0026 14.2056 16.8272 14.2056Z"
      fill="#B08950"
    />
    <path
      d="M16.8264 13.1982C18.1166 13.1982 19.1625 12.1093 19.1625 10.7661C19.1625 9.42287 18.1166 8.33398 16.8264 8.33398C15.5362 8.33398 14.4902 9.42287 14.4902 10.7661C14.4902 12.1093 15.5362 13.1982 16.8264 13.1982Z"
      fill="#B08950"
    />
  </svg>
)
