import { FC } from "react"
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { IStreetViewStatic, StreetViewStatic } from "~/components/modules"
import { colors } from "~/theme/colors"

interface IStreetViewModalProps
  extends Pick<IStreetViewStatic, "address" | "onClose" | "onHasStreetView" | "onNoStreetView"> {
  coordinates?: {
    latitude: number
    longitude: number
  }
  isOpen: boolean
}

export const StreetViewModal: FC<IStreetViewModalProps> = ({
  isOpen,
  onClose,
  address,
  coordinates,
  onHasStreetView,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent h="100%" maxH={{ base: "100%", md: "600px" }} m={0}>
        <ModalCloseButton
          zIndex="100"
          borderRadius="full"
          backgroundColor={colors.hdBlack.base}
          color={"white"}
          _focus={{}}
          _hover={{}}
        />

        <StreetViewStatic
          address={address}
          coordinates={{ lat: coordinates?.latitude, lng: coordinates?.longitude }}
          onHasStreetView={onHasStreetView}
        />
      </ModalContent>
    </Modal>
  )
}
