import { Assessment, Property } from "~/types/core"
import { EsProperty, PrivateListing } from "~/generated/graphql"
import { PropertyPresenter, fullAddress } from "~/utils/lib/Property"
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns"
import { ImageLoader } from "next/legacy/image"
import capitalize from "lodash/capitalize"
import { formatStreet } from "~/utils/lib/string"
import getConfig from "next/config"
import isEqual from "lodash/isEqual"
import isObject from "lodash/isObject"
import { taxRates } from "~/utils/constants/propertyTaxRates"
import transform from "lodash/transform"
import { v4 as uuid } from "uuid"
import zones from "~/utils/constants/zones"
import { calcAreaFromSqft, roundUp, round } from "~/utils/helpers"
import { provinces } from "~/utils/constants"
import slugify from "slugify"

export const formatPropertyURI = (property: EsProperty | PrivateListing): string => {
  if (property?.slug) {
    return `/property/${property.slug}`
  } else {
    return `/property`
  }
}

export const formatCurrency = (num: number): string => {
  if (num >= 1e9) {
    return "$" + (round(num, 1e7) / 1e9).toLocaleString() + "B"
  } else if (num >= 1e6) {
    return "$" + (round(num, 1e4) / 1e6).toLocaleString() + "M"
  } else {
    return "$" + Math.round(num).toLocaleString()
  }
}

export const formatCurrency2 = (num: number): string => {
  if (num >= 1e9) {
    return "$" + (round(num, 1e7) / 1e9).toLocaleString() + "B"
  } else if (num >= 1e6) {
    return "$" + (round(num, 1e4) / 1e6).toLocaleString() + "M"
  } else {
    const newNum = (round(num, 1e1) / 1e3).toFixed(0)
    if (newNum === "1000") {
      return "$" + (round(num, 1e4) / 1e6).toLocaleString() + "M"
    } else {
      return "$" + (round(num, 1e1) / 1e3).toFixed(0).toLocaleString() + "K"
    }
  }
}

export const formatPhoneNumber = (phoneNumber: string): string | null => {
  const cleaned = String(phoneNumber).replace(/\D/g, "")
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  return match ? `${match[1]}-${match[2]}-${match[3]}` : cleaned
}

export const wait = (ms = 0): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

export const delimited = (arg: number): string => (arg ? arg.toLocaleString() : "")

export const roundAndDelimit = (arg: number, multiple = 1): string =>
  arg ? delimited(roundUp(arg, multiple)) : ""

export const toLongName = (arg: string): string =>
  arg && zones[arg] ? `${zones[arg] ?? ""} (${arg})` : `${arg}`

export const toDecade = (arg: string, province: string): string => {
  const decade = Math.floor(Number(arg) / 10) * 10
  return province == "British Columbia" ? `${decade}s` : arg
}

export const calcTax = ({
  lastEstimatedPrice,
  assessmentClass,
  assessments,
  cityName,
}: EsProperty): { date: string; value: string }[] | null => {
  if (!(assessmentClass && lastEstimatedPrice)) {
    return null
  }
  if (
    !["COMMERCIAL", "CONDO", "RESIDENTIAL", "OTHER RESIDENTIAL"].some(
      elem => elem === assessmentClass.toUpperCase(),
    )
  ) {
    return null
  }

  if (!Object.keys(taxRates).some(cityKey => cityKey == cityName)) {
    return null
  }

  const taxes = Object.keys(taxRates[cityName]).map(key => {
    const tax = Math.round(
      taxRates[cityName][key][assessmentClass.toUpperCase()] *
        assessments.find(elem => elem.year.toString() === key)?.value,
    ).toString()
    return { date: key, value: tax }
  })

  return taxes
}

export const calcYearlyAppreciations = (assessments: Assessment[]): number[] =>
  assessments
    .map((v, i, a) => [v, a[i + 1]])
    .slice(0, -1)
    .map(([left, right]) => (right.value - left.value) / left.value)

/** if there are assessments whose change year over year is over the threshold value,
 * and if the property is less than 10 years old, declare property new
 * @param property - property to be assessed
 * @returns true if the property fits HonestDoor's interpretation of a "new property", otherwise false
 */
export const isNewBuild = (property: Property): boolean =>
  calcYearlyAppreciations(property.assessments).some(v => v > 0.55) &&
  new Date().getFullYear() - property.yearBuiltActual < 10

/** Splits a string on spaces, capitalizes each substring, and returns joined result */
export const capitalizeAll = (s: string): string => {
  // TODO: handle Mcnab/braeside => McNab/Braeside when safe to do so
  // TODO: handle splitting on / when safe to do so

  if (!s) {
    return ""
  }
  if (s.toLowerCase() == "fort mcmurray") {
    return "Fort McMurray"
  }

  if (s.toLowerCase() == "fort mckay") {
    return "Fort McKay"
  }

  const correctedOnHyphen = s
    .split("-")
    .map(str => (str ? `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}` : str))
    .join("-")

  return correctedOnHyphen
    .split(" ")
    .map(c => (isNaN(Number(c)) ? `${c[0].toUpperCase()}${c.slice(1)}` : c))
    .join(" ")
}

export const timeAgo = (date: string | Date, suffix?: string): string => {
  suffix = suffix ?? "on market"
  const createdAt = new Date(date)
  const now = new Date()

  const daysAgo = differenceInDays(now, createdAt)
  const hoursAgo = differenceInHours(now, createdAt)
  const minAgo = differenceInMinutes(now, createdAt)
  const secAgo = differenceInSeconds(now, createdAt)

  if (secAgo < 60) {
    if (secAgo === 1) {
      return `a second ${suffix}`
    }
    return `${secAgo} seconds ${suffix}`
  }

  if (minAgo < 60) {
    if (minAgo === 1) {
      return `${minAgo} min ${suffix}`
    }
    return `${minAgo} min ${suffix}`
  }

  if (hoursAgo <= 24) {
    if (hoursAgo === 1) {
      return `${hoursAgo} hour ${suffix}`
    }
    return `${hoursAgo} hours ${suffix}`
  }

  if (daysAgo >= 1) {
    if (daysAgo === 1) {
      return `${daysAgo} day ${suffix}`
    }
    return `${daysAgo} days ${suffix}`
  }
}

export const hideEmail = (email: string): string => {
  let sEmail = ""
  let atFound = false

  ;[...email].map(char => {
    if (char !== "@" && !atFound) {
      return (sEmail += "x")
    }
    if (char === "@") {
      atFound = true
      return (sEmail += char)
    }

    return (sEmail += char)
  })

  return sEmail
}

export const hidePhone = (phone: string): string => {
  let sPhone = ""

  ;[...phone].map((num, i) => {
    if (i !== 1 && i !== 2 && i !== 3 && num !== "(" && num !== ")" && num !== "-" && num !== " ") {
      return (sPhone += "X")
    }
    return (sPhone += num)
  })

  return sPhone
}

export const toProperCase = (s: string): string => {
  const split = s.split(" ")
  let words = []

  split.map(w => {
    w = w.charAt(0).toUpperCase() + w.slice(1)

    words = [...words, w]
  })

  return words.join(" ")
}

type TPropertyPageMetadata = {
  title: string
  description: string
  canonicalUrl: string
  imageUrl?: string
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(
  object: Record<string, any>,
  base: Record<string, any>,
): Record<string, any> {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

/**
 * @param property <Property> object
 * @param privateListing <PrivateListing> object
 * @returns {}
 */
export const forgePropertyPageMetadata = (
  property: EsProperty,
  privateListing: PrivateListing,
): TPropertyPageMetadata => {
  const { HOST } = getConfig().publicRuntimeConfig

  const metadata: TPropertyPageMetadata = {
    title: null,
    description: null,
    canonicalUrl: null,
    imageUrl: null,
  }

  if (privateListing) {
    metadata.title =
      fullAddress({
        ...privateListing,
        unparsedAddress: formatStreet(privateListing.streetAddress),
      }) || "Unknown Property"

    metadata.canonicalUrl = `${HOST.substring(0, HOST.length - 1)}${formatPropertyURI(
      privateListing,
    )}`

    metadata.description = [
      { name: "", value: privateListing.askingPrice?.toLocaleString(), prefix: "$" },
      { name: "", value: capitalize(privateListing.houseStyle) },
      { name: "Beds", value: privateListing.bedroomsTotal },
      { name: "Baths", value: privateListing.bathroomsTotal },
      { name: "", value: calcAreaFromSqft(privateListing.livingArea) },
      { name: "", value: privateListing.yearBuiltActual, prefix: "Built " },
      { name: "", value: privateListing.description },
    ]
      .filter(obj => (obj?.value ? obj?.value?.toString() != "" && obj?.value != 0 : false))
      .map(obj => (obj?.prefix ? obj?.prefix : "") + obj.value + (obj.name && ` ${obj.name}`))
      .join("・")

    metadata.imageUrl = privateListing?.images?.sort((a, b) => a.order - b.order)[0]?.url
  }

  if (property && !privateListing) {
    const propertyPresenter = new PropertyPresenter(property)

    metadata.title = propertyPresenter.fullAddress || "Unknown Property"

    metadata.canonicalUrl = `${HOST.substring(0, HOST.length - 1)}${formatPropertyURI(property)}`

    metadata.description = `Property located at ${propertyPresenter.fullAddress}. View transaction history, assessments, permits, home value estimates and more.`
  }

  return metadata
}

export const excludedZoningCodes = [
  "AG",
  "RA7",
  "RA8",
  "RA9",
  "DC2",
  "DC1",
  "CSC",
  "IB",
  "CO",
  "IH",
  "UM",
  "C-H",
  "IB-3 Heavy Industrial Employment District",
  "A-AGRICULTURAL",
]

export type ProvinceAbbr = keyof typeof provinces
export type ProvinceName = (typeof provinces)[ProvinceAbbr]

/**
 * @param provinceLongName - full name of the province
 * @returns province abbreviation, postal code style (2 letters only, PEI becomes PE for example)
 */
export const provinceLongToProvinceAbbr = (provinceLongName: string): ProvinceAbbr | "" => {
  if (!provinceLongName) return ""

  switch (provinceLongName.toLowerCase()) {
    case "british columbia":
      return "BC"
    case "alberta":
      return "AB"
    case "saskatchewan":
      return "SK"
    case "manitoba":
      return "MB"
    case "ontario":
      return "ON"
    case "quebec":
    case "québec":
      return "QC"
    case "newfoundland and labrador":
    case "newfoundland & labrador":
      return "NL"
    case "nova scotia":
      return "NS"
    case "prince edward island":
      return "PE"
    case "new brunswick":
      return "NB"
    case "yukon":
      return "YT"
    case "northwest territories":
      return "NT"
    case "nunavut":
      return "NU"
    default:
      return ""
  }
}

/**
 * @param provinceAbbr - 2-letter province abbreviation, Postal style
 * @returns full province name, capitalized and all
 */
export const provinceAbbrToProvinceLong = (provinceAbbr: string): ProvinceName | "" => {
  if (!provinceAbbr) return ""

  const switcher = provinceAbbr.toUpperCase()
  switch (switcher) {
    case "BC":
      return "British Columbia"
    case "AB":
      return "Alberta"
    case "SK":
      return "Saskatchewan"
    case "MB":
      return "Manitoba"
    case "ON":
      return "Ontario"
    case "QC":
      return "Québec"
    case "NL":
      return "Newfoundland and Labrador"
    case "NS":
      return "Nova Scotia"
    case "PE":
      return "Prince Edward Island"
    case "NB":
      return "New Brunswick"
    case "YT":
      return "Yukon"
    case "NT":
      return "Northwest Territories"
    case "NU":
      return "Nunavut"
    default:
      return ""
  }
}

export const getCreateUserUUID = (): string => {
  if (!process.browser) {
    return null
  }
  const UUID = localStorage.getItem("hd-uuid")

  if (UUID) {
    return UUID
  }

  const newUUID = uuid()
  localStorage.setItem("hd-uuid", newUUID)

  return newUUID
}

export const SIHLoader: ImageLoader = ({ src, width }): string => {
  const { HD_SIH_CDN } = getConfig().publicRuntimeConfig

  const requestBody = {
    bucket: "hd-site-assests",
    key: src,
    edits: {
      resize: {
        width,
        fit: "contain",
      },
    },
  }

  const requestString = JSON.stringify(requestBody)

  return `${HD_SIH_CDN}/${Buffer.from(requestString).toString("base64")}?width=${width}`
}

export const SIHLoader2 = ({ src, width, height, fit }): string => {
  const { HD_SIH_CDN } = getConfig().publicRuntimeConfig

  const requestBody = {
    bucket: "hd-site-assests",
    key: src,
    edits: {
      resize: {
        width,
        height,
        fit,
      },
    },
  }

  const requestString = JSON.stringify(requestBody)

  return `${HD_SIH_CDN}/${Buffer.from(requestString).toString(
    "base64",
  )}?width=${width}&height=${height}`
}

export const buildJobDescriptionParts = (
  jobDescription: string,
  cutAtLength = 200,
  firstHalfLenght = 150,
): { firstHalf: string; secondHalf?: string } => {
  // defensively return correctly structured output if job description is null/undefined
  if (!jobDescription) {
    return {
      firstHalf: "",
    }
  }

  // clears out all instances of more than one space
  // while loop is due to lack of replaceAll in node below 15
  let jd = jobDescription
  while (jd.match(/\s{2,}/g)) {
    jd = jd.replace(/\s{2,}/g, " ")
  }
  // if we ever upgrade to node > 15, above  whileloopery can be replaced with the below one-liner
  // const jd = jobDescription?.replaceAll(/(\s+)/g, " ")

  // if the job description is 'cutAtLength' (200) chars or less, just show it, and no accordion
  if (jd.length <= cutAtLength) {
    return {
      firstHalf: jd,
    }
  }

  // doing it this way so that the two halves contain full words.
  const jdArr = jd?.split(" ")

  let firstHalf = ""
  let i = 0
  // firstHalf is not guaranteed to have 'firstHalfLenght' (150) characters, but will be close
  while (firstHalf.length < firstHalfLenght && jdArr[i]) {
    firstHalf = `${firstHalf} ${jdArr[i]}`
    i++
  }

  const secondHalf = jd.slice(firstHalf.length)

  return {
    firstHalf: firstHalf.trim(),
    secondHalf: secondHalf.trim(),
  }
}

export function createOriginalLocation(str: string) {
  if (!str) return undefined

  const specialCasesMap = {
    // Neighborhoods
    "bayshore-belltown": "Bayshore - Belltown",
    "borden-farm-stewart-farm-parkwood-hills-fisher-glen":
      "Borden Farm - Stewart Farm - Parkwood Hills - Fisher Glen",
    "bridlewood-emerald-meadows": "Bridlewood - Emerald Meadows",
    "carleton-heights-rideauview": "Carleton Heights - Rideauview",
    "carlingwood-west-glabar-park-mckellar-heights":
      "Carlingwood West - Glabar Park - Mckellar Heights",
    "carson-grove-carson-meadows": "Carson Grove - Carson Meadows",
    "glen-cairn-kanata-south-business-park": "Glen Cairn - Kanata South Business Park",
    "hunt-club-ottawa-airport": "Hunt Club - Ottawa Airport",
    "ledbury-heron-gate-ridgemont": "Ledbury - Heron Gate - Ridgemont",
    "north-gower-kars": "North Gower - Kars",
    "osgoode-vernon": "Osgoode - Vernon",
    "playfair-park-lynda-park-guildwood-estates": "Playfair Park - Lynda Park - Guildwood Estates",
    "south-keys-greenboro-west": "South Keys - Greenboro West",
    "whitehaven-queensway-terrace-north": "Whitehaven - Queensway Terrace North",
    "billings-bridge-alta-vista": "Billings Bridge - Alta Vista",
    "braemar-park-bel-air-heights-copeland-park": "Braemar Park - Bel Air Heights - Copeland Park",
    "cityview-crestview-meadowlands": "Cityview - Crestview - Meadowlands",
    "elmvale-eastway-riverview-riverview-park-west":
      "Elmvale - Eastway - Riverview - Riverview Park West",
    "hawthorne-meadows-sheffield-glen": "Hawthorne Meadows - Sheffield Glen",
    "hunt-club-upper-blossom-park-timbermill": "Hunt Club Upper - Blossom Park - Timbermill",
    "kanata-lakes-marchwood-lakeside-morgans-grant-kanata-n-business-park":
      "Kanata Lakes-Marchwood Lakeside-Morgan's Grant-Kanata N Business Park",
    "lindenlea-new-edinburgh": "Lindenlea - New Edinburgh",
    "munster-ashton": "Munster - Ashton",
    "overbrook-mcarthur": "Overbrook - Mcarthur",
    "qualicum-redwood-park": "Qualicum - Redwood Park",
    "rothwell-heights-beacon-hill-north": "Rothwell Heights - Beacon Hill North",
    "trend-arlington": "Trend - Arlington",
    "woodroffe-lincoln-heights": "Woodroffe - Lincoln Heights",
    "beacon-hill-south-cardinal-heights": "Beacon Hill South - Cardinal Heights",
    "briar-green-leslie-park": "Briar Green - Leslie Park",
    "chapman-mills-rideau-crest-davidson-heights":
      "Chapman Mills - Rideau Crest - Davidson Heights",
    "civic-hospital-central-park": "Civic Hospital - Central Park",
    "crystal-bay-lakeview-park": "Crystal Bay - Lakeview Park",
    "emerald-woods-sawmill-creek": "Emerald Woods - Sawmill Creek",
    "glebe-dows-lake": "Glebe - Dows Lake",
    "hintonburg-mechanicsville": "Hintonburg - Mechanicsville",
    "hunt-club-woods-quintarra-revelstoke": "Hunt Club Woods - Quintarra - Revelstoke",
    "katimavik-hazeldean": "Katimavik - Hazeldean",
    "merivale-gardens-grenfell-glen-pineglen-country-place":
      "Merivale Gardens - Grenfell Glen - Pineglen - Country Place",
    "navan-carlsbad-springs": "Navan - Carlsbad Springs",
    "orleans-avalon-notting-gate-fallingbrook-gardenway-south":
      "Orléans Avalon - Notting Gate - Fallingbrook - Gardenway South",
    "orleans-village-chateauneuf": "Orléans Village - Chateauneuf",
    "riverside-south-leitrim": "Riverside South - Leitrim",
    "russell-edwards": "Russell - Edwards",
    "skyline-fisher-heights": "Skyline - Fisher Heights",
    "stonebridge-halfmoon-bay-hearts-desire": "Stonebridge - Halfmoon Bay - Heart's Desire",
    "woodvale-craig-henry-manordale-estates-of-arlington-woods":
      "Woodvale - Craig Henry - Manordale - Estates Of Arlington Woods",
    // "": "",

    // Cities
    "greater-sudbury-grand-sudbury": "Greater Sudbury / Grand Sudbury",
    "killarney-turtle-mountain": "Killarney - Turtle Mountain",
    // "": "",
  }

  if (specialCasesMap[str]) {
    return specialCasesMap[str]
  }

  return str
    .split("-")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ")
}

export function createUrl({
  variant,
  provinceAbbr,
  city,
  neighbourhood,
  isAbsolute = false,
}: {
  variant: "cities" | "real-estate" | "recently-sold" | "permits" | "private-listing" | "sitemap" | "sitemapd"
  provinceAbbr?: string
  city?: string
  neighbourhood?: string
  isAbsolute?: boolean
}) {
  const location = [provinceAbbr, city, neighbourhood]
    .filter(Boolean)
    .map(str => {
      slugify.extend({ "/": "-" })
      return slugify(str, {
        lower: true,
        strict: true,
      })
    })
    .join("/")

  let url = ""

  if (["cities", "real-estate", "recently-sold"].includes(variant)) {
    // if (!provinceAbbr) throw new Error()
    url = `/${variant}/${location}`
  }

  if (["permits", "sitemap", "sitemapd"].includes(variant)) {
    url = `/${variant}/${location}`
  }

  if (["private-privateListing"].includes(variant)) {
    if (!city) throw new Error()
    url = `/listings${location ? `?city=${location}` : null}`
  }

  return isAbsolute ? `https://www.honestdoor.com${url}` : url
}

/** formats fractional bath value into "n full + m half" format if float, doesn't change the integer values
 *
 * @param baths number of baths, as we currently store it (int or float)
 * @returns formatted value if baths is float, otherwise no change
 */
export const formatBaths = (baths: number, joinStr = "+"): string | number => {
  const fullBaths = Math.floor(baths)
  const halfBaths = (baths * 10) % 10

  if (halfBaths == 0) {
    return fullBaths
  } else {
    return `${fullBaths} full ${joinStr} ${halfBaths} half`
  }
}

/** calculates the total bath number from fractional bath values such as 1.1 for 1 full and 1 half
 * @param baths number of baths, as we currently store it (int or float)
 * @returns total number of bathrooms, regardless of whether it's a full bath or a half bath
 */
export const totalBaths = (baths: number): number => {
  const fullBaths = Math.floor(baths)
  const halfBaths = (baths * 10) % 10

  return fullBaths + halfBaths
}

/* This will take a value and will statically check if the value is never.
 * Useful for checking for unreachable (by design) code, and catching errors
 * if the code that was supposed to be unreachable suddenly becomes reachable.
 */
export const assertIsNever = (val: never): never => val

export const massageValuationValueText = (
  valuation: number,
  floor: number = 50_000,
  ceiling: number = 10_000_000,
) => {
  if (!valuation || valuation < floor) {
    return "Coming soon"
  }
  if (valuation > ceiling) {
    return `$${ceiling.toLocaleString()}+`
  }
  return `$${valuation?.toLocaleString()}`
}
