import React, { FC } from "react";
import { Box, Button, Card, Flex, Heading, Img, Link, Text } from "@chakra-ui/react";
import { MdOutlineMonetizationOn } from "react-icons/md";
import { ButtonVariants, CardVariants } from "~/theme/components";
import gtag from "~/utils/lib/gtag";
import { useRouter } from "next/router";
import { useAuth, useUser } from "~/components/hooks";
import { Icon } from "@chakra-ui/icon";
import { CommissionCalculator } from "~/components/modules/commission-calculator";
import { useCreatePrivateLeadMutation } from "~/generated/graphql";
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead";

const VARIANTS = {
  LIST: "list",
  HOMEPAGE: "homepage",
}

const PROS = {
  [VARIANTS.LIST]: [
    {
      title: "Save big on commission fees",
      desc: "",
    },
    {
      title: "No hidden costs",
      desc: "",
    },
    {
      title: "Advertised everywhere online",
      desc: "",
    },
    {
      title: "Full transparency",
      desc: "",
    },
  ],
  [VARIANTS.HOMEPAGE]: [
    {
      title: "Save big",
      desc: "On commission fees",
    },
    {
      title: "$500 Flat Rate",
      desc: "No hidden costs",
    },
    {
      title: "Go live in 24hrs",
      desc: "On 100’s of websites",
    },
  ],
}

interface IListOnMlsPromo {
  variant: ValueOf<typeof VARIANTS>
  onScroll?: (el: string) => void
}

export const ListOnMlsPromo: FC<IListOnMlsPromo> = ({ variant, onScroll }) => {
  const router = useRouter()
  const { openSignInModal } = useAuth()
  const { user } = useUser()
  const { mutateAsync: createPrivateLead } = useCreatePrivateLeadMutation()

  const onGetStartedClick = async () => {
    gtag.event({
      category: "MLS",
      action:
        variant === VARIANTS.HOMEPAGE
          ? "homepage_list_on_mls_get_started"
          : "list_on_mls_get_started",
    })

    if ((variant === VARIANTS.HOMEPAGE || variant === VARIANTS.LIST) && user) {
      await createPrivateLead({
        input: {
          source: variant === VARIANTS.HOMEPAGE ? PrivateLeadSource.SellHomepage : PrivateLeadSource.SellPageGetStarted,
          type: PrivateLeadType.Sell,
        },
      })
    }

    // if (variant === VARIANTS.LIST) {
    //   window.rdt("track", "ViewContent", {
    //     products: [
    //       {
    //         id: "get started id 1",
    //         name: "get started 1",
    //         category: "list on mls 1",
    //       },
    //       // additional products can be added here
    //     ],
    //   })
    // }

    // @ts-ignore
    if (window.$crisp) {
      // @ts-ignore
      window.$crisp.push(["set", "session:segments", [["list", "list-warmlead"]]])
    }

    if (user) {
      router.push("/submit-listing")
    } else {
      openSignInModal({
        callbackUrl: "/submit-listing",
      })
    }
  }

  const onFaqClick = () => {
    gtag.event({
      category: "MLS",
      action: "list_on_mls_faq",
    })
    onScroll("faq")
  }

  const onLearnMoreClick = () => {
    router.push("/buy-with-honestdoor")
  }

  if (variant === VARIANTS.HOMEPAGE) {
    return (
      <Card p={[0]} backgroundColor={"transparent"} color="white" border="none" boxShadow="none">
        <Flex
          direction={["column", "column", "row"]}
          justifyContent="space-between"
          flexGrow={1}
          alignItems={["center", "center", "center", "start"]}
        >
          <Flex
            direction="column"
            order={[1]}
            mr={[0, 0, "16px"]}
            p={["32px", "32px", 0]}
            flexGrow={1}
          >
            <Heading
              as="h1"
              fontSize="34px"
              lineHeight="41px"
              fontWeight="600"
              textAlign={["center", "center", "left"]}
            >
              List your home on REALTOR.ca
            </Heading>

            <Flex direction="column">
              <Box
                mt={["16px", "16px", "24px"]}
                order={[2, 2, 1, 1]}
                textAlign={["center", "center", "left"]}
              >
                <Text>
                  Call or Text{" "}
                  <Link href="tel:780-860-8400" textDecoration="underline">
                    780-860-8400
                  </Link>{" "}
                  or email{" "}
                  <Link href="mailto:sales@honestdoor.com" textDecoration="underline">
                    sales@honestdoor.com
                  </Link>{" "}
                  to learn more.
                </Text>
              </Box>

              <Box mt={["24px", "24px", "40px"]} order={[1, 1, 2, 2]}>
                <Flex wrap="wrap" mt="-16px">
                  {PROS[VARIANTS.HOMEPAGE].map((item, i) => (
                    <Flex
                      key={i}
                      direction="column"
                      mr={[0, 0, "24px"]}
                      width={["calc(100% / 3)", "calc(100% / 3)", "auto"]}
                      mt="16px"
                    >
                      <Flex
                        direction={["column", "column", "row"]}
                        alignItems={["center", "center", "start"]}
                      >
                        <Img
                          src="/icons/verified.svg"
                          h={["35px", "35px", "24px"]}
                          w={["36px", "36px", "24px"]}
                        />
                        <Text
                          mt={["8px", "8px", 0]}
                          ml={[0, 0, "8px"]}
                          fontSize={["18px", "18px", "20px"]}
                          lineHeight={["22px", "22px", "24px"]}
                          fontWeight="600"
                          textAlign={["center", "center", "left"]}
                        >
                          {item.title}
                        </Text>
                      </Flex>
                      <Text mt="6px" display={["none", "none", "block"]}>
                        {item.desc}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            </Flex>

            <Flex marginTop="24px" justifyContent={["center", "center", "start"]}>
              <Button
                onClick={onGetStartedClick}
                variant={ButtonVariants.Secondary}
                _hover={{}}
                size="lg"
                border="none"
              >
                Get Started
              </Button>
            </Flex>
          </Flex>

          <Flex order={[2]} shrink={0} maxW="360px">
            <Card
              variant={CardVariants.Property}
              p={["32px"]}
              borderTopRadius={["lg"]}
              backgroundColor={"white"}
              color={"hdBlack.base"}
            >
              <Box>
                <Icon as={MdOutlineMonetizationOn} color="#1F8855" fontSize="32px" />
                <Icon as={MdOutlineMonetizationOn} color="#1F8855" fontSize="32px" />
                <Icon as={MdOutlineMonetizationOn} color="#1F8855" fontSize="32px" />
              </Box>
              <Box>
                <Heading as="h3" fontSize="26px" lineHeight="29px">
                  Buy your next home with us and receive{" "}
                  <Text as="span" color="#1F8855">
                    cashback
                  </Text>
                </Heading>
              </Box>
              <Box mt="16px">
                We offer a 70% cashback on commission, on average our clients have received $9,200
              </Box>

              <Flex marginTop="9px" justifyContent={["center", "center", "start"]}>
                <Button
                  onClick={onLearnMoreClick}
                  variant={ButtonVariants.Secondary}
                  _hover={{}}
                  size="lg"
                  border="none"
                >
                  Learn More
                </Button>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Card>
    )
  }

  if (variant === VARIANTS.LIST) {
    return (
      <Card
        variant={CardVariants.Property}
        borderTopRadius={["40px", "40px", "lg"]}
        backgroundColor={"hdBlack.base"}
        color="white"
        overflow="initial"
      >
        <Flex direction={["column", "column", "row"]} justifyContent="space-between">
          <Box mr={[0, 0, "16px"]} p={["32px", "32px", "32px"]}>
            <Heading as="h1" fontSize="34px" lineHeight="41px" fontWeight="600">
              $500 flat fee listing on the MLS® / REALTOR.ca
            </Heading>

            <Box mt={["16px", "16px", "24px"]}>
              Call or Text{" "}
              <Link href="tel:780-860-8400" textDecoration="underline">
                780-860-8400
              </Link>{" "}
              or email{" "}
              <Link href="mailto:sales@honestdoor.com" textDecoration="underline">
                sales@honestdoor.com
              </Link>{" "}
              to learn more.
            </Box>

            <Flex marginTop="24px" gap="4" justifyContent={["center", "center", "start"]}>
              <Button
                onClick={onGetStartedClick}
                variant={ButtonVariants.Secondary}
                fontSize="16px"
                _hover={{}}
                size="lg"
                border="none"
              >
                Get Started
              </Button>

              <Button
                onClick={onFaqClick}
                variant={ButtonVariants.Secondary}
                fontSize="16px"
                _hover={{}}
                size="lg"
                border="none"
              >
                FAQ
              </Button>
            </Flex>

            <Box mt={["24px", "24px", "40px"]}>
              <Flex wrap="wrap" mt="-16px">
                {PROS[VARIANTS.LIST].map((item, i) => (
                  <Flex
                    key={i}
                    direction="column"
                    mr={[0, 0, "24px"]}
                    width={["50%", "50%", "auto"]}
                    mt="16px"
                  >
                    <Flex
                      direction={["column", "column", "row"]}
                      alignItems={["center", "center", "start"]}
                    >
                      <Img
                        src="/icons/verified.svg"
                        h={["52px", "52px", "24px"]}
                        w={["52px", "52px", "24px"]}
                      />
                      <Text
                        mt={["8px", "8px", 0]}
                        ml={[0, 0, "8px"]}
                        fontSize={["18px", "18px", "18px"]}
                        lineHeight={["22px", "22px", "24px"]}
                        fontWeight={["600", "600", "400"]}
                        textAlign={["center", "center", "left"]}
                      >
                        {item.title}
                      </Text>
                    </Flex>
                    <Text mt="6px" display={["none", "none", "block"]}>
                      {item.desc}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Box>
          </Box>

          <Box
            flexGrow={1}
            p={["32px", "32px", "32px"]}
            width={["100%", "100%", "40%"]}
            background="url('/images/calculators/image-20240218-222001.png')"
          >
            <Card h={"100%"} position="relative" overflow="initial">
              <Heading as="h3" fontSize="22px" mb={6}>
                Calculate your savings if you sell with HonestDoor
              </Heading>
              <CommissionCalculator />
            </Card>
          </Box>
        </Flex>
      </Card>
    )
  }

  return null
}
