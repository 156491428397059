import isEqual from "lodash/isEqual"
import { action, computed, makeAutoObservable } from "mobx"
import { enableStaticRendering } from "mobx-react-lite"
import { AssessmentClass, RangeFilter, SortFilter, SortOrder } from "~/generated/graphql"
import { FilterCategory } from "../mapFilters"
import { store, updateMapQueryParams } from "../StoreProvider"

enableStaticRendering(typeof window === "undefined")

export type PropertyFilters = {
  page: number
  beds: RangeFilter
  baths: RangeFilter
  assessmentClass: AssessmentClass[]
  sort: SortOption
}
export enum SortOption {
  MOST_RECENT = "MOST_RECENT",
  OLDEST = "OLDEST",
  MOST_EXPENSIVE = "MOST_EXPENSIVE",
  LEAST_EXPENSIVE = "LEAST_EXPENSIVE",
}

export const sortOptions: Record<SortOption, SortFilter> = {
  [SortOption.MOST_RECENT]: { field: "updatedAt", order: SortOrder.Desc },
  [SortOption.OLDEST]: { field: "updatedAt", order: SortOrder.Asc },
  [SortOption.MOST_EXPENSIVE]: { field: "predictedValue", order: SortOrder.Desc },
  [SortOption.LEAST_EXPENSIVE]: { field: "predictedValue", order: SortOrder.Asc },
}

export const defaultPropertyFilters: PropertyFilters = {
  page: 1,
  beds: { gte: 0, lte: null },
  baths: { gte: 0, lte: null },
  assessmentClass: [],
  sort: SortOption.MOST_RECENT,
}

export class PropertyFiltersStore {
  page: number = defaultPropertyFilters.page
  beds: RangeFilter = defaultPropertyFilters.beds
  baths: RangeFilter = defaultPropertyFilters.baths
  assessmentClass: AssessmentClass[] = defaultPropertyFilters.assessmentClass
  sort: SortOption = defaultPropertyFilters.sort

  constructor() {
    makeAutoObservable(this, {
      setPage: action,
      setBeds: action,
      setBaths: action,
      setAssessmentClass: action,
      setSort: action,
      resetFilters: action,
      resetDisabled: computed,
      filters: computed,
      isFiltered: computed,
      isDisabled: computed,
      activeFiltersCount: computed,
      hydrate: action,
    })
  }

  setPage = (page: number): void => {
    this.page = page
    updateMapQueryParams({ filters: { property: { page } } }, { skipRouter: true, scroll: true })
  }

  setBeds = (beds: RangeFilter): void => {
    this.page = 1
    this.beds = beds
    updateMapQueryParams(
      { filters: { property: { beds, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setBaths = (baths: RangeFilter): void => {
    this.page = 1
    this.baths = baths
    updateMapQueryParams(
      { filters: { property: { baths, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setAssessmentClass = (assessmentClass: AssessmentClass[]): void => {
    this.page = 1
    this.assessmentClass = assessmentClass
    updateMapQueryParams(
      { filters: { property: { assessmentClass, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  setSort = (sort: SortOption): void => {
    this.page = 1
    this.sort = sort
    updateMapQueryParams(
      { filters: { property: { sort, page: 1 } } },
      { skipRouter: true, scroll: true },
    )
  }

  resetFilters = (): void => {
    this.page = 1
    this.beds = { gte: 0, lte: null }
    this.baths = { gte: 0, lte: null }
    this.assessmentClass = []

    updateMapQueryParams(
      {
        filters: {
          property: {
            beds: { gte: 0, lte: null },
            baths: { gte: 0, lte: null },
            assessmentClass: [],
            page: 1,
          },
        },
      },
      { skipRouter: true, scroll: true },
    )
  }

  get filters(): Omit<PropertyFilters, "sort"> & {
    sort: SortFilter
  } {
    return {
      page: this.page - 1,
      beds: this.beds,
      baths: this.baths,
      assessmentClass: this.assessmentClass,
      sort: sortOptions[this.sort],
    }
  }

  get isFiltered(): boolean {
    return this.beds.gte !== 0 || this.baths.gte !== 0 || this.assessmentClass.length > 0
  }

  get resetDisabled(): boolean {
    return !this.isFiltered
  }

  get isDisabled(): boolean {
    return (
      store?.creaFiltersStore.isFiltered &&
      store?.mapFiltersStore.filterCategory === FilterCategory.ForSale
    )
  }

  get activeFiltersCount(): number {
    return Object.keys(this.filters).reduce((count, key) => {
      if (["sort", "page"].includes(key)) return count
      return isEqual(this.filters[key], defaultPropertyFilters[key]) ? count : count + 1
    }, 0)
  }

  hydrate = (data: this): void => {
    if (!data) return

    if (data.page) {
      this.page = data.page
    }

    if (data.beds) {
      this.beds = data.beds
    }

    if (data.baths) {
      this.baths = data.baths
    }

    if (data.assessmentClass) {
      this.assessmentClass = data.assessmentClass
    }

    if (data.sort) {
      this.sort = data.sort
    }
  }
}
