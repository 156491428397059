export enum PrivateLeadSource {
  SellPropPage = 'SELL_PROP_PAGE',
  BuyListPage = 'BUY_LIST_PAGE',
  BuyCashbackCalc = 'BUY_CASHBACK_CALC',
  SellCashbackCalc = 'SELL_CASHBACK_CALC',
  BuyHomepageContactUs = 'BUY_HOMEPAGE_CONTACT_US',
  SellHomepage = 'SELL_HOMEPAGE',
  SellPageGetStarted = 'SELL_PAGE_GET_STARTED',
  BusinessPage= 'BUSINESS_PAGE',
  SellPropertyBanner = 'SELL_PROPERTY_BANNER',
  WidgetSignUp = 'WIDGET_SIGN_UP',
  WidgetDemo = 'WIDGET_DEMO',
  WidgetPageViewed = 'WIDGET_PAGE_VIEWED',
  BusinessHomebuilderCommercial = 'BUSINESS_HOMEBUILDER_COMMERCIAL',
  CmaClick = 'CMA_CLICK',
  CmaSearch = 'CMA SEARCH',
}

export enum PrivateLeadType {
  Sell = 'SELL',
  Buy = 'BUY',
  Business = 'BUSINESS',
}
