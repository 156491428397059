import { MapStore, MapView } from "./map"
import { createContext, useContext } from "react"

import { CreaFiltersStore } from "./creaFilters"
import { FeatureFlagsStore } from "./featureFlags"
import { MapFiltersStore } from "./mapFilters"
import { PageModalStore } from "./pageModal"
import { PropertyFiltersStore } from "./propertyFilters"
import { PropertyPageStore } from "./propertyPage"
import { RecentlySoldFiltersStore } from "./recentlySoldFilters"
import Router from "next/router"

export type Store = {
  mapStore: MapStore
  mapFiltersStore: MapFiltersStore
  creaFiltersStore: CreaFiltersStore
  recentlySoldFiltersStore: RecentlySoldFiltersStore
  propertyFiltersStore: PropertyFiltersStore
  pageModalStore: PageModalStore
  featureFlagsStore: FeatureFlagsStore
  propertyPageStore: PropertyPageStore
}

export let store: Store

export const StoreContext = createContext<Store>(store)

export function StoreProvider({
  children,
  initialState: initialData,
}: {
  children: JSX.Element[] | JSX.Element
  initialState: any
}): JSX.Element {
  const store = initializeStore(initialData)

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

function initializeStore(initialData: Store = null) {
  const _store = store ?? {
    mapStore: new MapStore(),
    mapFiltersStore: new MapFiltersStore(),
    creaFiltersStore: new CreaFiltersStore(),
    recentlySoldFiltersStore: new RecentlySoldFiltersStore(),
    propertyFiltersStore: new PropertyFiltersStore(),
    pageModalStore: new PageModalStore(),
    featureFlagsStore: new FeatureFlagsStore(),
    propertyPageStore: new PropertyPageStore(),
  }

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData?.mapStore) {
    _store.mapStore.hydrate(initialData.mapStore)
  }

  if (initialData?.mapFiltersStore) {
    _store.mapFiltersStore.hydrate(initialData.mapFiltersStore)
  }

  if (initialData?.creaFiltersStore) {
    _store.creaFiltersStore.hydrate(initialData.creaFiltersStore)
  }

  if (initialData?.recentlySoldFiltersStore) {
    _store.recentlySoldFiltersStore.hydrate(initialData.recentlySoldFiltersStore)
  }

  if (initialData?.propertyFiltersStore) {
    _store.propertyFiltersStore.hydrate(initialData.propertyFiltersStore)
  }

  if (initialData?.featureFlagsStore) {
    _store.featureFlagsStore.hydrate(initialData.featureFlagsStore)
  }

  _store.pageModalStore.hydrate(initialData?.pageModalStore)

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(): Store {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error("useStore must be used within StoreProvider")
  }

  return context
}

export const useMapStore = (): MapStore => useStore().mapStore
export const useMapFiltersStore = (): MapFiltersStore => useStore().mapFiltersStore
export const useCreaFiltersStore = (): CreaFiltersStore => useStore().creaFiltersStore
export const useRecentlySoldFilterStore = (): RecentlySoldFiltersStore =>
  useStore().recentlySoldFiltersStore
export const usePropertyFilterStore = (): PropertyFiltersStore => useStore().propertyFiltersStore
export const usePageModalStore = (): PageModalStore => useStore().pageModalStore
export const useFeatureFlagsStore = (): FeatureFlagsStore => useStore().featureFlagsStore
export const usePropertyPageStore = (): PropertyPageStore => useStore().propertyPageStore

export type UpdateMapQueryParamsArgs = {
  state?: Partial<MapStore>
  filters?: {
    map?: Partial<MapFiltersStore>
    crea?: Partial<CreaFiltersStore>
    recentlySold?: Partial<RecentlySoldFiltersStore>
    property?: Partial<PropertyFiltersStore>
  }
  pageModal?: Partial<PageModalStore>
}

export type UpdateMapQueryParamsOpts = {
  scroll?: boolean
  skipRouter?: boolean
}

export const updateMapQueryParams = (
  params: UpdateMapQueryParamsArgs,
  opts: UpdateMapQueryParamsOpts = { scroll: true, skipRouter: false },
): void | Promise<boolean> => {
  const state = {
    viewport: params.state?.viewport ?? store.mapStore.viewport,
    view: params.state?.view ?? store.mapStore.view,
    activeLayers: params.state?.activeLayers ?? store.mapStore.activeLayers,
    drawerOpen: params.state?.drawerOpen ?? store.mapStore.drawerOpen,
    highlightedFeatures: params.state?.highlightedFeatures ?? store.mapStore.highlightedFeatures,
  }

  const filters = {
    map: {
      bbox: params.filters?.map?.bbox ?? store.mapFiltersStore.bbox,
      place: params.filters?.map?.place ?? store.mapFiltersStore.place,
      filterCategory: params.filters?.map?.filterCategory ?? store.mapFiltersStore.filterCategory,
    },
    crea: {
      page: params.filters?.crea?.page ?? store.creaFiltersStore.page,
      beds: params.filters?.crea?.beds ?? store.creaFiltersStore.beds,
      baths: params.filters?.crea?.baths ?? store.creaFiltersStore.baths,
      price: params.filters?.crea?.price ?? store.creaFiltersStore.price,
      sort: params.filters?.crea?.sort ?? store.creaFiltersStore.sort,
      homeType: params.filters?.crea?.homeType ?? store.creaFiltersStore.homeType,
    },
    recentlySold: {
      page: params.filters?.recentlySold?.page ?? store.recentlySoldFiltersStore.page,
      soldDate: params.filters?.recentlySold?.soldDate ?? store.recentlySoldFiltersStore.soldDate,
      soldPrice:
        params.filters?.recentlySold?.soldPrice ?? store.recentlySoldFiltersStore.soldPrice,
      sort: params.filters?.recentlySold?.sort ?? store.recentlySoldFiltersStore.sort,
    },
    property: {
      page: params.filters?.property?.page ?? store.propertyFiltersStore.page,
      beds: params.filters?.property?.beds ?? store.propertyFiltersStore.beds,
      baths: params.filters?.property?.baths ?? store.propertyFiltersStore.baths,
      assessmentClass:
        params.filters?.property?.assessmentClass ?? store.propertyFiltersStore.assessmentClass,
      sort: params.filters?.property?.sort ?? store.propertyFiltersStore.sort,
    },
    pageModal: {
      isOpen: params.pageModal?.isOpen ?? store.pageModalStore.isOpen,
      type: params.pageModal?.type ?? store.pageModalStore.type,
      id: params.pageModal?.id ?? store.pageModalStore.id,
      slug: params.pageModal?.slug ?? store.pageModalStore.slug,
    },
  }

  const queryParams = {
    state: JSON.stringify(state),
    filters: JSON.stringify(filters),
  }

  if (opts.skipRouter) {
    // @TODO: implement this path
    // const path = window.location.pathname
    const newUrl = `/map?${new URLSearchParams(queryParams).toString()}`

    if (opts.scroll) {
      window.scrollTo({
        top: 0,
      })
    }

    return window.history.pushState(
      { ...window.history.state, as: newUrl, url: newUrl },
      "",
      newUrl,
    )
  }

  return Router.push(
    {
      pathname: "/map",
      query: queryParams,
    },
    undefined,
    { scroll: opts.scroll },
  )
}

export const getInitialStateFromQueryParams = (query: Record<any, any>) => {
  const { state, filters, pageModal } = query

  const initialState: any = {
    mapStore: {
      view: MapView.MAP,
    },
    creaFiltersStore: {
      page: 1,
    },
    recentlySoldFiltersStore: {
      page: 1,
    },
    propertyFiltersStore: {
      page: 1,
    },
  }

  try {
    if (state) {
      initialState.mapStore = JSON.parse(state)
    }

    if (pageModal) {
      initialState.pageModalStore = JSON.parse(pageModal)
    }

    if (filters) {
      const parsedFilters = JSON.parse(filters)

      if (parsedFilters.map) {
        initialState.mapFiltersStore = parsedFilters.map
      }

      if (parsedFilters.crea) {
        initialState.creaFiltersStore = parsedFilters.crea
      }

      if (parsedFilters.recentlySold) {
        initialState.recentlySoldFiltersStore = parsedFilters.recentlySold
      }

      if (parsedFilters.property) {
        initialState.propertyFiltersStore = parsedFilters.property
      }
    }

    return initialState
  } catch (e) {
    return initialState
  }
}
