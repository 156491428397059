// From next example https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
import merge from "deepmerge"
import isEqual from "lodash/isEqual"
import getConfig from "next/config"
import { useMemo } from "react"
import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { HDFilterState, HDListState, HDListingStepsState, HDMapState } from "./cache/store"
import { typeDefs } from "./cache/typeDefs"

const { API_ENDPOINT } = getConfig().publicRuntimeConfig

const authLink = setContext((_, { headers }) => {
  if (typeof window !== "undefined") {
    const accessToken: string = localStorage.getItem("accessToken")

    return {
      headers: {
        ...headers,
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    }
  }
})

const httpLink = new HttpLink({
  uri: API_ENDPOINT,
  credentials: "include",
})

let apolloClient: ApolloClient<any>

const cache = new InMemoryCache({
  resultCaching: false,
  typePolicies: {
    Query: {
      fields: {
        HDMap: {
          read() {
            return HDMapState()
          },
        },
        HDFilter: {
          read() {
            return HDFilterState()
          },
        },
        HDList: {
          read() {
            return HDListState()
          },
        },
        HDListingSteps: {
          read() {
            return HDListingStepsState()
          },
        },
      },
    },
  },
})

function createApolloClient() {
  cache.gc({ resetResultCache: true, resetResultIdentities: true })

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([authLink, httpLink]),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
    },
    cache,
    typeDefs,
  })
}

export function initializeApollo(initialState = null): ApolloClient<any> {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo() {
  // const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(), [])
  return store
}
