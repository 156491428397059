import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import { MdMail } from "react-icons/md"
import styles from "./SocialLinks.module.css"

const NETWORKS = {
  facebook: {
    name: "Facebook",
    // icon: IconFacebook,
    icon: FaFacebookF,
    url: "https://www.facebook.com/HonestDoor-325163728148807/",
  },
  linkedin: {
    name: "LinkedIn",
    icon: FaLinkedinIn,
    url: "https://www.linkedin.com/company/honestdoor/",
  },
  instagram: {
    name: "Instagram",
    icon: FaInstagram,
    url: "https://www.instagram.com/honest_door/",
  },
  twitter: {
    name: "Twitter",
    icon: FaTwitter,
    url: "https://twitter.com/honest_door",
  },
  mail: {
    name: "Mail",
    icon: MdMail,
    url: "mailto:info@honestdoor.com",
  },
}

export type NetworkName = "facebook" | "instagram" | "linkedin" | "twitter" | "mail"

export type ISocialLinkProps = {
  network: NetworkName
  url?: string
  dark?: boolean
  [x: string]: any
}

export const SocialLink = (props: ISocialLinkProps): JSX.Element => {
  const { network, url, dark } = props
  const { icon: Icon, name } = NETWORKS[network]
  return (
    <a
      target="_new"
      className={`${styles.root} ${styles[network]}`}
      href={url ?? NETWORKS[network].url}
      aria-label={name}
      style={
        dark
          ? {
              fill: "black",
              color: "black",
              stroke: "black",
            }
          : {}
      }
    >
      <Icon key={"top"} stroke={dark ? "black" : ""} />
      <Icon key={"bottom"} stroke={dark ? "black" : ""} />
    </a>
  )
}

export type ISocialLinksProps = {
  networkNames: NetworkName[]
  urls?: Record<string, string>
  dark?: boolean
}

export const SocialLinks = (props: ISocialLinksProps): JSX.Element => {
  const { networkNames, dark = false, urls } = props
  return (
    <ul className={styles.sociallinks} data-cy="social-links">
      {networkNames.map(network => (
        <li key={network}>
          <SocialLink
            key={network}
            network={network}
            dark={dark}
            url={urls ? urls[network] : null}
          />
        </li>
      ))}
    </ul>
  )
}
