export const colors = {
  hdGold: {
    600: "#A8885A",
    500: "#BD9A67",
    350: "#D6CDBF",
    full: "#BD9A67",
    faded: "#E9DDCC",
    100: "#E9DDCC",
    50: "#F3ECE2",
  },
  hdMoney: {
    full: "#7EAD5F",
    faded: "#D8E6CF",
    50: "#edf8e6",
    100: "#d4e6c8",
    200: "#bad4a9",
    300: "#9fc388",
    400: "#85b167",
    500: "#6b984e",
    600: "#52763b",
    700: "#3b5429",
    800: "#223417",
    900: "#061300",
  },
  hdSky: {
    full: "#6EB1DB",
    faded: "#D6ECF4",
    50: "#EBF4FA",
    100: "#C6E0F1",
    200: "#A1CCE8",
    300: "#7CB9DF",
    400: "#57A5D5",
    500: "#3391CC",
    600: "#2974A3",
    700: "#1E577B",
    800: "#143A52",
    900: "#0A1D29",
  },
  hdFire: {
    full: "#D96543",
    faded: "#F4D1C7",
    50: "#FBEEEA",
    100: "#F3CEC4",
    200: "#EBAF9D",
    300: "#E49077",
    400: "#DC7151",
    500: "#D4512B",
    600: "#AA4122",
    700: "#7F311A",
    800: "#552011",
    900: "#2A1009",
  },
  hdBlack: {
    500: "#202020",
    400: "#757575",
    300: "#9E9E9E",
    200: "#C4C4C4",
    100: "#E0E0E0",
    light: "#DBE2EA",
    50: "#EEF2F6",
    faded: "#B0B5C4",
    faded100: "#797E92",
    base: "#282F45",
  },
  hdPurple: {
    50: "#F3EAFB",
    100: "#DEC3F3",
    200: "#C99DEC",
    300: "#B477E4",
    400: "#9F50DD",
    500: "#8A2AD5",
    600: "#6F21AB",
    700: "#531980",
    800: "#371155",
    900: "#1C082B",
  },
  gold: {
    100: "#FBE8CF",
    200: "#F5DEC1",
    300: "#F5DEC1",
    400: "#E0C097",
    500: "#C4985F",
    600: "#A58050",
    700: "#8A6536",
    800: "#785526",
    900: "#654516",
  },
  green: {
    100: "#D3F1DD",
    500: "#43B77E",
    700: "#1F8855",
    900: "#3B6751",
  },
  red: {
    100: "#FDD8D8",
    500: "#DA6B64",
    700: "#B04739",
    900: "#873635",
  },
  blue: {
    100: "#D5EFF7",
    500: "#50B6D5",
    700: "#3C90AC",
    900: "#164756",
  },
}
