import { useState } from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

import { Text, VStack } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/react"
import { Button, ButtonGroup, Card } from "@chakra-ui/react"

const PredictedContent = ({
  currentPredictedValue,
  pricePercentDifference,
}: {
  currentPredictedValue: number
  pricePercentDifference: number
}): JSX.Element => {
  const ppdIsPos = pricePercentDifference > 0

  const color = ppdIsPos ? "hdMoney.full" : "hdFire.full"

  const arrow = ppdIsPos ? <FaArrowUp fontSize={24} /> : <FaArrowDown fontSize={24} />

  const direction = ppdIsPos ? "Up" : "Down"

  const subText = `${direction} ${Math.abs(pricePercentDifference)}% from Previous Month`

  return (
    <VStack>
      {currentPredictedValue ? (
        <Text
          display={"flex"}
          alignItems={"baseline"}
          fontWeight={"600"}
          fontSize={"28px"}
          color={color}
        >
          {`$${Math.round(currentPredictedValue).toLocaleString()}`}
          {arrow}
        </Text>
      ) : (
        <Text>No data currently available</Text>
      )}
      {pricePercentDifference ? <Text>{subText}</Text> : null}
    </VStack>
  )
}

const RentalContent = ({ rentalEstimate }: { rentalEstimate: number }): JSX.Element => {
  return rentalEstimate ? (
    <Text fontWeight={"600"} fontSize={"28px"} color={"hdGold.full"}>{`$${Math.round(
      rentalEstimate,
    ).toLocaleString()}`}</Text>
  ) : (
    <Text>No data currently available</Text>
  )
}

const DolSqftContent = ({ dollarPerSqft }: { dollarPerSqft: number }): JSX.Element => {
  const subText = ` / sqft`

  return dollarPerSqft ? (
    <Text fontWeight={"600"} fontSize={"28px"} color={"hdGold.full"}>
      {`$${Math.round(dollarPerSqft).toLocaleString()}`}
      {subText}
    </Text>
  ) : (
    <Text>No data currently available</Text>
  )
}

interface TopMiniStatProps {
  title: string
  data: any
}

export const TopMiniStat = (props: TopMiniStatProps): JSX.Element => {
  const { title, data } = props

  const [selectedPropertyClass, setSelectedPropertyClass] = useState("Residential")

  if (!data) {
    return null
  }

  const isRental =
    Object.keys(data["Residential"]).includes("rentalEstimate") ||
    Object.keys(data["Condo"]).includes("rentalEstimate")

  const isDolSqft =
    Object.keys(data["Residential"]).includes("dollarPerSqft") ||
    Object.keys(data["Condo"]).includes("dollarPerSqft")

  const isPredicted =
    Object.keys(data["Residential"]).includes("currentPredictedValue") ||
    Object.keys(data["Condo"]).includes("currentPredictedValue")

  return (
    <Flex justify={"center"} h={"100%"} data-cy="topministat">
      <Card w={"320px"} h={"100%"} boxShadow="sm">
        <VStack spacing={4} h={"100%"} justify={"space-between"}>
          <VStack>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {title}
            </Text>
            <Text>Current Month</Text>
          </VStack>

          {isRental ? (
            <RentalContent rentalEstimate={data[selectedPropertyClass].rentalEstimate} />
          ) : null}

          {isDolSqft ? (
            <DolSqftContent dollarPerSqft={data[selectedPropertyClass].dollarPerSqft} />
          ) : null}

          {isPredicted ? (
            <PredictedContent
              currentPredictedValue={data[selectedPropertyClass].currentPredictedValue}
              pricePercentDifference={data[selectedPropertyClass].pricePercentDifference}
            />
          ) : null}

          <ButtonGroup size="sm" isAttached variant="outline">
            <Button
              size={"lg"}
              borderRadius="3xl"
              backgroundColor={selectedPropertyClass == "Residential" ? "hdGold.full" : "white"}
              color={selectedPropertyClass == "Residential" ? "white" : "hdGold.full"}
              _hover={{ backgroundColor: "hdGold.faded" }}
              onClick={() => setSelectedPropertyClass("Residential")}
            >
              House
            </Button>
            <Button
              size={"lg"}
              borderRadius="3xl"
              backgroundColor={selectedPropertyClass == "Condo" ? "hdGold.full" : "white"}
              color={selectedPropertyClass == "Condo" ? "white" : "hdGold.full"}
              _hover={{ backgroundColor: "hdGold.faded" }}
              onClick={() => setSelectedPropertyClass("Condo")}
            >
              Condo
            </Button>
          </ButtonGroup>
        </VStack>
      </Card>
    </Flex>
  )
}
