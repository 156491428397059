import { FC, useState } from "react"
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  useToast,
  Center,
} from "@chakra-ui/react"
import { Form, Formik, FormikHelpers } from "formik"
import { Yup } from "~/utils/validation"
import { provinces } from "~/utils/constants"
import { formatCurrency } from "~/utils"
import { ButtonVariants } from "~/theme/components"
import { calculateCommission } from "~/utils/helpers"
import { useCreatePrivateLeadMutation } from "~/generated/graphql"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"
import { useUser } from "~/components/hooks"
import gtag from "~/utils/lib/gtag"

const validationSchema = Yup.object().shape({
  province: Yup.string().required("Province is required"),
})

interface ICashBackCalculator {}

export const CashBackCalculator: FC<ICashBackCalculator> = () => {
  const { user } = useUser()
  const toast = useToast()
  const [sliderValue, setSliderValue] = useState(500000)
  const [calculatedValue, setCalculatedValue] = useState(null)
  const { mutateAsync: createPrivateLead, isLoading } = useCreatePrivateLeadMutation()

  const initialValues = {
    province: "",
  }

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    try {
      setSubmitting(true)
      gtag.event({
        action: "cashback_page_calculate",
      })
      const commission = calculateCommission(sliderValue, values.province, "buy")

      if (user) {
        await createPrivateLead({
          input: {
            source: PrivateLeadSource.BuyCashbackCalc,
            type: PrivateLeadType.Buy,
            province: values.province,
            cashback: Math.ceil(+commission.amountSaved),
          },
        })
      }

      setCalculatedValue(commission)
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ handleSubmit, getFieldProps, errors, touched, isSubmitting, isValid, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <FormControl label="province" isInvalid={errors.province && touched.province}>
                  <FormLabel textAlign="center">Select your province</FormLabel>
                  <Center>
                    <Select
                      {...getFieldProps("province")}
                      id="province"
                      placeholder="- Select option -"
                      color="initial"
                      maxW="250px"
                    >
                      {Object.keys(provinces)
                        .filter(key => ["AB", "BC", "NB", "NS", "ON", "PE"].includes(key))
                        .sort()
                        .map(key => (
                          <option key={key} value={key}>
                            {provinces[key]}
                          </option>
                        ))}
                    </Select>
                  </Center>

                  <FormErrorMessage justifyContent="center">{errors.province}</FormErrorMessage>
                </FormControl>

                <Box>
                  <Box textAlign="center" fontWeight="bold">
                    What is your expected purchase price?{" "}
                    <Text
                      as="span"
                      color="hdMoney.full"
                      ml={3}
                      w={70}
                      display="inline-block"
                      textAlign="left"
                    >{`${formatCurrency(sliderValue)}`}</Text>
                  </Box>

                  <Slider
                    defaultValue={500_000}
                    min={200_000}
                    max={3_000_000}
                    step={50_000}
                    onChange={v => setSliderValue(v)}
                    onChangeEnd={val => console.log(val)}
                  >
                    <SliderMark value={200_000} mt="1" fontSize="sm">
                      $200K
                    </SliderMark>
                    <SliderMark value={3_000_000} mt="1" ml="-7" fontSize="sm">
                      $3M
                    </SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box>

                <Box textAlign="center">
                  <Button
                    type="submit"
                    variant={ButtonVariants.BlackRounded}
                    backgroundColor="hdMoney.full"
                    isLoading={isSubmitting || isLoading}
                    disabled={!isValid}
                    px="32px"
                  >
                    Calculate Cash Back
                  </Button>
                </Box>

                {calculatedValue && (
                  <Stack direction="column" spacing={3} textAlign="center">
                    <Heading as="h3" fontSize="22px" color="gold.500">
                      {formatCurrency(calculatedValue.amountSaved)}* estimated savings
                    </Heading>
                    <Box fontSize="12px">
                      {["ON"].includes(values?.province) ? (
                        <>
                          *${(calculatedValue.rate * 100).toFixed(2)}% on the sale price and takes
                          into account the 30% paid to HonestDoor.
                        </>
                      ) : (
                        <>
                          *${(calculatedValue.rate * 100).toFixed(2)}% on the first $
                          {calculatedValue.threshold.toLocaleString()} of the sale price, and $
                          {(calculatedValue.rateRemaining * 100).toFixed(2)}% on the remaining sale
                          price and takes into account the 30% paid to HonestDoor.
                        </>
                      )}
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
