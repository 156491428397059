import isNil from "lodash/isNil"
import { action, computed, makeAutoObservable } from "mobx"
import { enableStaticRendering } from "mobx-react-lite"
import { MapLayers } from "~/generated/graphql"

import { UpdateMapQueryParamsArgs, store, updateMapQueryParams } from "../StoreProvider"
import { FilterCategory } from "../mapFilters"
import { Bbox } from "../types"

enableStaticRendering(typeof window === "undefined")

export enum MapView {
  MAP = "MAP",
  LIST = "LIST",
}

export type Viewport = {
  center: [number, number]
  zoom: [number]
}

export type UserLocation = {
  isLoading?: boolean
  coords?: [number, number] | null
}

export type MapTooltip = {
  isActive: boolean
  coordinates: [number, number]
  featureId: string | number
  propertyIds: string[]
  listingIds: string[]
}

export const mapStoreDefaults: Partial<MapStore> = {
  viewport: {
    center: [-101.01651362796643, 54.433364269612376],
    zoom: [4],
  },
  view: MapView.MAP,
  drawerOpen: true,
  activeLayers: [MapLayers.Properties, MapLayers.Listings],
  userLocation: {
    isLoading: false,
    coords: null,
  },
  tooltip: {
    isActive: false,
    coordinates: null,
    featureId: null,
    propertyIds: null,
    listingIds: null,
  },
  showSatellite: false,
  setViewport: undefined,
  setView: undefined,
  toggleView: undefined,
  setActiveLayers: undefined,
  setUserLocation: undefined,
  setTooltip: undefined,
  toggleShowSatellite: undefined,
  hydrate: undefined,
  highlightedFeatures: null,
}

export class MapStore {
  viewport: Viewport = mapStoreDefaults.viewport
  view: MapView = mapStoreDefaults.view
  activeLayers: MapLayers[] = mapStoreDefaults.activeLayers
  userLocation: UserLocation = mapStoreDefaults.userLocation
  tooltip: MapTooltip = mapStoreDefaults.tooltip
  showSatellite: boolean = mapStoreDefaults.showSatellite
  drawerOpen: boolean = mapStoreDefaults.drawerOpen
  map: mapboxgl.Map | null = null
  highlightedFeatures: string[] = mapStoreDefaults.highlightedFeatures

  constructor() {
    makeAutoObservable(this, {
      setViewport: action,
      setView: action,
      toggleView: action,
      toggleDrawer: action,
      setActiveLayers: action,
      setTooltip: action,
      toggleShowSatellite: action,
      setMap: action,
      getActiveLayers: computed,
      hydrate: action,
    })
  }

  setViewport = (viewport: Viewport, bbox?: Bbox) => {
    const { center: prevCenter, zoom: prevZoom } = this.viewport
    const { center: nextCenter, zoom: nextZoom } = viewport

    /**
     * Only update the viewport if the center or zoom has changed.
     * This prevents the map onMoveEnd event from firing multiple times.
     */
    if (
      (prevCenter[0] !== nextCenter[0] && prevCenter[1] !== nextCenter[1]) ||
      prevZoom[0] !== nextZoom[0]
    ) {
      /**
       * Instead of setting the viewport directly, we use the router to
       * update the query string, and {@link hydrate} the viewport from the
       * getServerSideProps. This way, the user can use the back button
       * to go back to the previous viewport as well as an entry point
       * for the map position.
       */
      const args: UpdateMapQueryParamsArgs = { state: { viewport }, filters: { map: { bbox } } }

      if (bbox) {
        args.filters.map.bbox = bbox
      }

      this.viewport = args.state.viewport

      if (bbox) {
        store.mapFiltersStore.setBbox(args.filters.map.bbox)
      }

      return updateMapQueryParams(args, { skipRouter: true })
    } else {
      if (bbox) {
        store.mapFiltersStore.setBbox(bbox)
      }
    }
  }

  setView = (view: MapView) => {
    updateMapQueryParams({
      state: { view },
      filters: { crea: { page: 1 }, recentlySold: { page: 1 }, property: { page: 1 } },
    })
  }

  toggleView = () => {
    const view = this.view === MapView.MAP ? MapView.LIST : MapView.MAP
    updateMapQueryParams({
      state: { view },
      filters: { crea: { page: 1 }, recentlySold: { page: 1 }, property: { page: 1 } },
    })
  }

  toggleDrawer = () => {
    this.drawerOpen = !this.drawerOpen
    updateMapQueryParams({ state: { drawerOpen: this.drawerOpen } }, { skipRouter: true })
  }

  setActiveLayers = (activeLayers: MapLayers[]) => {
    this.activeLayers = activeLayers
    updateMapQueryParams({ state: { activeLayers } }, { skipRouter: true })
  }

  setUserLocation = (userLocation: UserLocation) => {
    this.userLocation = {
      ...this.userLocation,
      ...userLocation,
    }
  }

  setTooltip = (tooltip: MapTooltip) => {
    this.tooltip = tooltip
  }

  toggleShowSatellite = () => {
    this.showSatellite = !this.showSatellite
  }

  setMap = (map: mapboxgl.Map) => {
    this.map = map
  }

  get getActiveLayers() {
    const { isFiltered: isListingsFiltered } = store.creaFiltersStore
    const { isFiltered: isPropertiesFiltered } = store.propertyFiltersStore

    const filterCategory = store?.mapFiltersStore?.filterCategory
    const activeLayers = [...this.activeLayers]

    if (filterCategory === FilterCategory.RecentlySold) {
      activeLayers.push(MapLayers.Properties)
      activeLayers.splice(activeLayers.indexOf(MapLayers.Listings), 1)
    }

    if (filterCategory === FilterCategory.Properties && isPropertiesFiltered) {
      activeLayers.push(MapLayers.Properties)
      activeLayers.splice(activeLayers.indexOf(MapLayers.Listings), 1)
    }

    if (filterCategory === FilterCategory.ForSale && isListingsFiltered) {
      activeLayers.push(MapLayers.Listings)
      activeLayers.splice(activeLayers.indexOf(MapLayers.Properties), 1)
    }

    return activeLayers
  }

  hydrate = (data: this) => {
    if (!data) return

    if (data.viewport) {
      this.viewport = data.viewport
    }

    if (data.activeLayers) {
      this.activeLayers = data.activeLayers
    }

    if (data.view) {
      this.view = data.view
    }

    if (!isNil(data.drawerOpen)) {
      this.drawerOpen = data.drawerOpen
    }

    if (data.highlightedFeatures) {
      this.highlightedFeatures = data.highlightedFeatures
    }
  }
}
