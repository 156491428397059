export default {
  A: "Metropolitan Recreational Zone",
  AED: "Area & Entertainment District Zone",
  AG: "Agricultural Zone",
  AGI: "Industrial Reserve Zone",
  AGU: "Urban Reserve Zone",
  AJ: "Alternative Jurisdiction Zone",
  AN: "River Valley Activity Node Zone",
  AP: "Public Parks Zone",
  CB1: "Low Intensity Business Zone",
  CB2: "General Business Zone",
  CB3: "Commercial Mixed Business Zone",
  CCA: "Core Commercial Arts Zone",
  CCHD: "Clareview Campus High Density Residential Zone",
  CCLD: "Clareview Campus Low Density Residential Zone",
  CCMD: "Clareview Campus Medium Density Residential Zone",
  CCSF: "Clareview Campus Single Family Residential Zone",
  CHY: "Highway Corridor Zone",
  CMU: "Commercial Mixed Use Zone",
  CNC: "Neighbourhood Convenience Commercial Zone",
  CO: "Commercial Office Zone",
  CS1: "Community Services 1 Zone",
  CS2: "Community Services 2 Zone",
  CS3: "Community Services 3 Zone",
  CS4: "Community Services 4 Zone",
  CSC: "Shopping Centre Zone",
  CSCa: "Ambleside Shopping Centre Zone",
  DC1: "Direct Development Control Provision",
  DC2: "Site Specific Development Control Provision",
  EETL: "Edmonton Energy and Technology Park Logistics Zone",
  EIM: "Ellerslie Medium Industrial Zone",
  GHLD: "SPECIAL AREA Graydon Hill Low Density Residential Zone",
  GLD: "Griesbach Low Density Residential Zone",
  GLG: "Griesbach Low Density Residential with Garden Suites Zone",
  HA: "Heritage Area Zone",
  HDR: "High Density Residential Zone",
  HVLD: "Special Area Heritage Valley Low Density Zone",
  IB: "Industrial Business Zone",
  IH: "Heavy Industrial Zone",
  IL: "Light Industrial Zone",
  IM: "Medium Industrial Zone",
  JAMSC: "Jasper Avenue Main Street Commercial Zone",
  MA: "Municipal Airport Zone",
  MA2: "Municipal Airport Business Industrial Zone",
  MA3: "Municipal Airport General Business Zone",
  NA: "Natural Areas Protection Zone",
  PU: "Public Utility Zone",
  RA7: "Low Rise Apartment Zone",
  RA7a: "Ambleside Low-Rise Apartment Zone",
  RA7g: "Griesbach Low Rise Apartment Zone",
  RA8: "Medium Rise Apartment Zone",
  RA9: "High Rise Apartment Zone",
  RF1: "Single Detached Residential Zone",
  RF2: "Low Density Infill Zone",
  RF3: "Small Scale Infill Development Zone",
  RF4: "Semi-detached Residential Zone",
  RF4t: "Terwillegar Semi-detached Residential Zone",
  RF5: "Row Housing Zone",
  RF5g: "Griesbach Row Housing Zone",
  RF5t: "Terwillegar Row Housing Zone",
  RF6: "Medium Density Multiple Family Zone",
  RMD: "Residential Mixed Dwelling Zone",
  RMH: "Mobile Home Zone",
  RMU: "Residential Mixed Use Zone",
  RPL: "Planned Lot Residential Zone",
  RPLt: "Terwillegar Planned Lot Residential Zone",
  RR: "Rural Residential Zone",
  RSL: "Residential Small Lot Zone",
  SLD: "Stillwater Low Density Residential Zone",
  TMU: "Terwillegar Mixed Use Zone",
  TSDR: "Terwillegar Single Detached Residential",
  TSLR: "Terwillegar Small Lot Residential Zone",
  UCRH: "Urban Character Row Housing Zone",
  UI: "Urban Institutional Zone",
  US: "Urban Services Zone",
  UVCa: "Ambleside Urban Village Commercial Zone",
  UW: "Urban Warehouse Zone",
}

export const topZones = [
  "A",
  "AED",
  "AG",
  "AGI",
  "AGU",
  "AJ",
  "AN",
  "AP",
  "CB1",
  "CB2",
  "CB3",
  "CCA",
  "CCHD",
  "CCLD",
  "CCMD",
  "CCNC",
  "CCSF",
  "CHY",
  "CMU",
  "CMX*",
  "CNC",
  "CO",
  "CS1",
  "CS2",
  "CS3",
  "CS4",
  "CSC",
  "CSCa",
  "DC1",
  "DC2",
  "DC5",
  "EETM",
  "EETR",
  "EIB",
  "EIM",
  "GHLD",
  "GLD",
  "GLG",
  "GVC",
  "HA",
  "HDR",
  "HVLD",
  "IB",
  "IC",
  "IH",
  "IL",
  "IM",
  "JAMSC",
  "MA",
  "MA2",
  "MA3",
  "NA",
  "PU",
  "RA7",
  "RA7*",
  "RA7a",
  "RA7g",
  "RA8",
  "RA8h",
  "RA9",
  "RA9*",
  "RF1",
  "RF2",
  "RF3",
  "RF4",
  "RF4t",
  "RF5",
  "RF5g",
  "RF5t",
  "RF6",
  "RF6*",
  "RMD",
  "RMH",
  "RMU",
  "RPL",
  "RPLt",
  "RR",
  "RSL",
  "TC-C",
  "TMU",
  "TSDR",
  "TSLR",
  "SLD",
  "UCRH",
  "UI",
  "US",
  "UVCa",
  "UW",
]
