import Button from "./Button"
import Card from "./Card"
import Divider from "./Divider"
import Popover from "./Popover"
import Stat from "./Stat"
import Text from "./Text"
import Tabs from "./Tabs"
import Input from "./Input"
import Textarea from "./Textarea"
import Select from "./Select"

export * from "./Button"
export * from "./Text"
export * from "./Card"

export const components = {
  Button,
  Card,
  Divider,
  Popover,
  Stat,
  Text,
  Tabs,
  Input,
  Textarea,
  Select
}
