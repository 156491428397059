import { inputAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

export enum SelectVariants {}

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
)

export default defineMultiStyleConfig({
  defaultProps: {
    variant: "default",
  },

  baseStyle: definePartsStyle({
    field: {
      background: "#FFFFFF",
      borderRadius: "8px",
      borderWidth: "1px",
      borderColor: "gray.200",

      _invalid: {
        borderColor: "red.500",
      },
    },
  }),

  variants: {
    default: definePartsStyle({
      field: {},
    }),
  },
})
