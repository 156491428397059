export enum TextVariants {
  heading1 = "h1",
  heading2 = "h2",
  heading3 = "h3",
  heading4 = "h4",
  heading5 = "h5",
  body1 = "b1",
  body2 = "b2",
  body3 = "b3",
  body4 = "b4",
  body5 = "b5",
  body6 = "b6",
}

const Text = {
  variants: {
    h1: {
      fontSize: "34px",
      lineHeight: "38px",
      fontWeight: "600",
    },
    h2: {
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: "700",
    },
    h3: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "700",
    },
    h4: {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
    },
    h5: {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: "600",
    },
    b1: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "700",
    },
    b2: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "400",
    },
    b3: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "700",
    },
    b4: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "500",
    },
    b5: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "700",
    },
    b6: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "500",
    },
  },
}

export default Text
