export const provinces = {
  BC: "British Columbia",
  AB: "Alberta",
  SK: "Saskatchewan",
  MB: "Manitoba",
  ON: "Ontario",
  QC: "Quebec",
  NL: "Newfoundland and Labrador",
  NS: "Nova scotia",
  PE: "Prince Edward Island",
  NB: "New Brunswick",
  YT: "Yukon",
  NT: "Northwest Territories",
  NU: "Nunavut",
}
