import { provinceLongToProvinceAbbr } from "~/utils"
import { formatStreet } from "~/utils/lib/string"
import {
  EsProperty,
  NotificationSubscriptionEvent,
  NotificationSubscriptionFrequency,
  useCreateNotificationSubscriptionMutation,
} from "~/generated/graphql"

interface IHookParams {
  property: EsProperty
  email: string
  onSuccess?: (s?: string) => void
  onFailed?: (s?: string) => void
}

export const useWatchProperty = ({ property, email, onSuccess, onFailed }: IHookParams) => {
  const { mutateAsync: createNotificationSubscription, isLoading } = useCreateNotificationSubscriptionMutation({
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: () => {
      onFailed && onFailed(property?.id)
    },
  })

  return {
    watch: () => {
      return createNotificationSubscription({
        input: {
          name: [
            formatStreet(property?.unparsedAddress),
            property?.cityName,
            provinceLongToProvinceAbbr(property?.province),
          ].join(", "),
          email,
          eventType: NotificationSubscriptionEvent.PropertyUpdated,
          frequency: NotificationSubscriptionFrequency.RealTime,
          propertyId: property.id,
        },
      })
    },
    loading: isLoading,
  }
}
