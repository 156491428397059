import { FC, useEffect, useState } from "react"
import { Button } from "@chakra-ui/button"
import { Input } from "@chakra-ui/input"
import { HStack, StackDivider, VStack } from "@chakra-ui/layout"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal"
import { Spinner } from "@chakra-ui/react"
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"
import { useStorage } from "~/components/hooks"
import theme from "~/theme"
import { isEmailValid } from "~/utils/lib/string"

interface IWatchPropertyModal {
  isOpen: boolean
  email: string
  setEmail: (email: string) => void
  onClose: () => void
  onSignUp: () => void
  onSubmit: () => void
  loading: boolean
  title?: string
  buttonLabel?: string
}

export const WatchPropertyModal: FC<IWatchPropertyModal> = ({
  email,
  setEmail,
  isOpen,
  onClose,
  onSubmit,
  loading,
  title = "Get monthly alerts when your HonestDoor Price changes",
  buttonLabel = "Watch",
}) => {
  const [isEmailWarningVisible, setIsEmailWarningVisible] = useState(false)

  useEffect(() => {
    setEmail(email)
  }, [email])

  const handleInputChange = event => {
    setEmail(event.target.value)
    if (isEmailValid(email)) {
      setIsEmailWarningVisible(false)
    }
  }

  const { setItem } = useStorage()

  const handleWatch = () => {
    onSubmit()
    setItem("email", email)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader maxW="90%" fontWeight="medium" fontSize="lg">
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={"2em"}>
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            w={"100%"}
            align={"flex-start"}
            spacing={4}
          >
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <HStack w={"100%"} align={"flex-start"}>
                <Input
                  value={email}
                  disabled={loading}
                  onChange={handleInputChange}
                  type="email"
                  placeholder="mail@example.com"
                  _focus={{
                    borderColor: isEmailValid(email)
                      ? theme.colors.hdMoney["full"]
                      : theme.colors.hdFire["full"],
                  }}
                  autoFocus
                />
                <Button
                  disabled={loading || !isEmailValid(email)}
                  isLoading={loading}
                  colorScheme="hdGold"
                  onClick={() =>
                    isEmailValid(email) ? handleWatch() : setIsEmailWarningVisible(true)
                  }
                >
                  {loading ? <Spinner /> : buttonLabel}
                </Button>
              </HStack>
              <FormErrorMessage display={isEmailWarningVisible ? "initial" : "none"}>
                Please enter a valid email.
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
