import { FC } from "react"
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { colors } from "~/theme/colors"
import { MapboxStaticMap } from "~/components/modules"

interface IMapModalProps {
  isOpen: boolean
  coordinates?: {
    latitude: number
    longitude: number
  }
  onClose: () => void
}

export const MapModal: FC<IMapModalProps> = ({ isOpen, onClose, coordinates }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalCloseButton
          zIndex="100"
          borderRadius="full"
          backgroundColor={colors.hdBlack.base}
          color={"white"}
          _focus={{}}
          _hover={{}}
        />

        {coordinates.latitude && coordinates.longitude && (
          <MapboxStaticMap
            longitude={coordinates.longitude}
            latitude={coordinates.latitude}
            zoom={16}
            interactive={false}
            wrapperStyle={{
              height: 400,
            }}
          />
        )}
      </ModalContent>
    </Modal>
  )
}
