import { FC, useState } from "react"
import { useRouter } from "next/router"
import { FaQuestionCircle } from "react-icons/fa"
import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/layout"
import {
  Flex,
  Icon,
  Select,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import InternalLink from "~/components/elements/InternalLink"
import { createUrl } from "~/utils"
import { useGetCityStatsQuery, useGetNeighbourhoodStatsQuery } from "~/generated/graphql"
import { Tooltip } from "~/components/modules"

const rankToStat = {
  avgAssessedRank: "avgAssessedValue",
  growthRateRank: "growthRate",
  transactionCountRank: "totalTransactionCount",
}

const rankToMapper = {
  avgAssessedRank: val => `$${val.toLocaleString()}`,
  growthRateRank: val => `${Number(val * 100).toFixed(2)}%`,
  transactionCountRank: val => `${val}`,
}

export interface ITopList {
  title: string
  statTitle: string
  tooltipText: string
  displayStat: string
  cityId?: string
}

export const TopList: FC<ITopList> = ({ title, statTitle, tooltipText, displayStat, cityId }) => {
  const router = useRouter()
  const { city, province } = router.query
  const currentYear = new Date().getFullYear()
  const availableYears = Array(5)
    .fill(true)
    .map((_, i) => currentYear - i)

  const [propertyTypeFilter, setPropertyTypeFilter] = useState("Both")
  const [yearFilter, setYearFilter] = useState(currentYear)
  const useStatQuery = cityId ? useGetNeighbourhoodStatsQuery : useGetCityStatsQuery
  const attribute = cityId ? "getNeighbourhoodStats" : "getCityStats"
  const queryVars = cityId
    ? {
        filter: {
          cityId,
          year: yearFilter,
          filterStat: displayStat,
          assessmentClass: propertyTypeFilter,
        },
      }
    : {
        filter: {
          year: yearFilter,
          filterStat: displayStat,
          assessmentClass: propertyTypeFilter,
        },
      }

  const { isLoading, data } = useStatQuery(queryVars)

  const linkTextBuilder = city
    ? d => d.neighbourhoodName
    : d => `${d.cityName}, ${d.city.provinceAbbr}`

  return (
    <Box
      data-cy="toplist"
      borderWidth="1px"
      borderRadius={"md"}
      boxShadow={"sm"}
      w="100%"
      h="100%"
      bgColor={"white"}
    >
      <VStack w="100%">
        <HStack w="100%" align="center" justify="center" padding="1em" pb="0px">
          <Tooltip label={tooltipText}>
            <Heading fontSize="1.5em">
              {title}&nbsp;
              <Icon as={FaQuestionCircle} fontSize={"0.6em"} color={"hdBlack.300"} />
            </Heading>
          </Tooltip>
        </HStack>
        <HStack w="100%" justify="space-between" padding="1em" pb="0px">
          <Text>Property Type: </Text>
          <Select
            variant="flushed"
            size="sm"
            maxW="50%"
            onChange={event => setPropertyTypeFilter(event.target.value)}
          >
            <option value="Both">House + Condo</option>
            <option value="Residential">Houses</option>
            <option value="Condo">Condos</option>
          </Select>
        </HStack>
        <HStack w="100%" justify="space-between" padding="1em" pb="0px">
          <Text>Year: </Text>
          <Select
            variant="flushed"
            size="sm"
            maxW="50%"
            value={yearFilter}
            onChange={event => setYearFilter(Number(event.target.value))}
          >
            {availableYears.map((year, i) => {
              return (
                <option key={i} value={year}>
                  {year}
                </option>
              )
            })}
          </Select>
        </HStack>
        <Table variant="simple" size="sm" padding={0}>
          <Thead>
            <Tr>
              <Th w="2em" />
              <Th w="50%">Name</Th>
              <Th>{statTitle}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              Array(10)
                .fill(true)
                .map((_, i) => (
                  <Tr key={i}>
                    <Td>
                      <Skeleton height="15px" />
                    </Td>
                    <Td>
                      <Skeleton height="15px" />
                    </Td>
                    <Td>
                      <Skeleton height="15px" />
                    </Td>
                  </Tr>
                ))
            ) : data?.[attribute]?.slice(0, 10)?.length > 0 ? (
              data?.[attribute]?.slice(0, 10)?.map((datum, i) => {
                return (
                  <Tr key={i}>
                    <Td isNumeric w="2em">
                      {i + 1}.
                    </Td>
                    <Td w="50%">
                      <InternalLink
                        linkProps={{
                          color: "hdMoney.full",
                          _hover: { color: "hdMoney.500", textDecoration: "underline" },
                        }}
                        href={createUrl({
                          variant: "cities",
                          city: datum.cityName,
                          provinceAbbr: city ? province : datum.city.provinceAbbr,
                          neighbourhood: datum.neighbourhoodName,
                        })}
                      >
                        {linkTextBuilder(datum)}
                      </InternalLink>
                    </Td>
                    <Td>{rankToMapper[displayStat](datum[rankToStat[displayStat]])}</Td>
                  </Tr>
                )
              })
            ) : (
              <Tr>
                <Td colSpan={3} h={"100%"}>
                  <Flex w={"100%"} justify={"center"}>
                    No items to show for selected year/property type
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  )
}
