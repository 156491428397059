import { FC, useEffect, useState, CSSProperties } from "react"
import getConfig from "next/config"
import gtag from "~/utils/lib/gtag"
import { Alert, AlertIcon, AlertTitle, Flex, FlexProps, Image, Spinner } from "@chakra-ui/react"
const { GOOGLE_API_KEY } = getConfig().publicRuntimeConfig

export interface IStreetViewStatic {
  coordinates: { lat: number; lng: number }
  address: string
  watermarks?: boolean
  onClose?: () => void
  /**
   * Callback function if street view is not available for the address
   */
  onNoStreetView?: () => void
  /**
   * Callback function if street view is available for the address
   */
  onHasStreetView?: () => void
  style?: CSSProperties
  containerProps?: FlexProps
}

export const StreetViewStatic: FC<IStreetViewStatic> = ({
  address,
  watermarks,
  style,
  containerProps,
  onHasStreetView,
  onNoStreetView,
}) => {
  const [loading, setLoading] = useState(true)
  const [imageAvailable, setImageAvailable] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)

  const location = encodeURI(address)

  const checkImageAvailability = async () => {
    const req = `https://maps.googleapis.com/maps/api/streetview/metadata?key=${GOOGLE_API_KEY}&location=${location}`

    try {
      const res = await fetch(req)
      const blob = await res.blob()
      const metadata = await blob.text()
      return JSON.parse(metadata)
    } catch {
      return null
    }
  }

  useEffect(() => {
    setImageSrc(null)
    setLoading(true)
    checkImageAvailability().then(metadata => {
      if (metadata.status == "OK") {
        setImageAvailable(true)
        showStaticImage()
        if (onHasStreetView) {
          onHasStreetView()
        }
      } else {
        setImageAvailable(false)
        if (onNoStreetView) {
          onNoStreetView()
        }
      }
      setLoading(false)
    })
  }, [address])

  const showStaticImage = async () => {
    gtag.event({
      category: "Street View",
      action: `Show`,
      label: `Address: ${address}`,
    })

    const container = document.getElementById("street-view-static")
    const size = `${container.offsetWidth}x${container.offsetHeight}`
    const req = `https://maps.googleapis.com/maps/api/streetview?key=${GOOGLE_API_KEY}&size=${size}&location=${location}&fov=100` // 'zoom', 0-120deg, default 90

    try {
      const res = await fetch(req)
      const blob = await res?.blob()
      const imgSrc = URL.createObjectURL(blob)
      setImageSrc(imgSrc)
    } catch {
      return null
    }
  }

  return (
    <Flex id="street-view-static" style={style} h={"100%"} {...containerProps}>
      <Flex
        position={"absolute"}
        zIndex={10}
        top={0}
        right={0}
        bottom={0}
        left={0}
        align={"center"}
        justify={"center"}
        background={
          imageSrc
            ? `url(${imageSrc})`
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.7),
                rgba(0, 0, 0, 0.8)),
                url("/images/streetviewhouseplaceholder.jpg")`
        }
        backgroundSize={"cover"}
        backgroundPosition={"center"}
        backgroundRepeat={"no-repeat"}
      >
        {loading && <Spinner size={"xl"} color={"hdSky.full"} />}
        {!loading && !imageAvailable && (
          <Alert w={"fit-content"} status={"info"} rounded={"md"}>
            <AlertIcon />
            <AlertTitle>Street View unavailable here</AlertTitle>
          </Alert>
        )}
      </Flex>
      {watermarks && imageSrc && (
        <Flex alignSelf="flex-end" w="100%" h="20px" justifyContent="space-between" zIndex="100">
          <Image src="/images/googlelogo_light_62x24_with_2_stroke_color_66x26dp.png" ml="5px" />
          <Flex
            color="hdBlack.200"
            bgColor="rgba(0, 0, 0, 0.7)"
            px="5px"
            fontSize="10px"
          >{`© Google`}</Flex>
        </Flex>
      )}
    </Flex>
  )
}
