import { useState } from "react"
import { FaQuestionCircle } from "react-icons/fa"
import SpartaStat from "~/components/elements/SpartaStat"
import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/layout"
import { Icon, Select, Tooltip } from "@chakra-ui/react"

export interface TopStatProps {
  title?: string
  subText?: string
  tooltipText?: string
  data?: any
}

export const TopStat = ({ title, tooltipText, subText, data }: TopStatProps): JSX.Element => {
  const availableYears = Object.keys(data)
    .map(y => Number(y))
    .filter(y => !isNaN(y))
    .sort((a, b) => b - a)

  const currentYear = availableYears[0]

  const [propertyTypeFilter, setPropertyTypeFilter] = useState("Both")
  const [yearFilter, setYearFilter] = useState(currentYear)

  if (data.availablePropertyTypes.length == 0) {
    return null
  }

  const propertyTypeToLabelMappings = {
    Both: "House + Condo",
    Residential: "House",
    Condo: "Condo",
  }

  return (
    <Box
      borderWidth="1px"
      borderRadius={"md"}
      w="100%"
      h="100%"
      data-cy="topstat"
      boxShadow={"sm"}
      bgColor={"white"}
    >
      <VStack w="100%" pb={"20px"}>
        <HStack w="100%" align="center" justify="center" padding="1em" pb="0px">
          <Tooltip label={tooltipText}>
            <Heading
              display={"inline"}
              fontSize="1.5em"
              textAlign={"center"}
              _hover={{ cursor: "help" }}
            >
              {title}&nbsp;
              <Icon as={FaQuestionCircle} fontSize={"0.6em"} color={"hdBlack.300"} />
            </Heading>
          </Tooltip>
        </HStack>
        <HStack w="100%" justify="space-between" padding="1em" pb="0px">
          <Text>Property Type: </Text>
          <Select
            variant="flushed"
            size="sm"
            maxW="50%"
            onChange={event => setPropertyTypeFilter(event.target.value)}
          >
            {data.availablePropertyTypes.sort().map((propertyType, i) => {
              return (
                <option value={propertyType} key={i}>
                  {propertyTypeToLabelMappings[propertyType]}
                </option>
              )
            })}
          </Select>
        </HStack>
        <HStack w="100%" justify="space-between" padding="1em">
          <Text>Year: </Text>
          <Select
            variant="flushed"
            size="sm"
            maxW="50%"
            onChange={event => setYearFilter(Number(event.target.value))}
          >
            {availableYears.map((year, i) => {
              return (
                <option key={i} value={year}>
                  {year}
                </option>
              )
            })}
          </Select>
        </HStack>
        <Box>{data[yearFilter]?.bonus[propertyTypeFilter]}</Box>
        <SpartaStat value={data[yearFilter]?.rank[propertyTypeFilter]} subText={subText} />
      </VStack>
    </Box>
  )
}
