import { FC, useState } from "react"
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/opacity.css"

interface ImageProps extends LazyLoadImageProps {
  fallbackSrc?: string
}

export const Image: FC<ImageProps> = ({
  src,
  fallbackSrc = "/images/placeholders/no-image.jpg",
  placeholderSrc = "/images/placeholders/no-image.jpg",
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(src)

  return (
    <LazyLoadImage
      src={imgSrc || placeholderSrc}
      loading="lazy"
      effect="opacity"
      onError={() => setImgSrc(fallbackSrc)}
      {...rest}
    />
  )
}
