import { FC } from "react"
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { Modal, ModalBody, ModalContent } from "@chakra-ui/modal"
import { Form, Formik, FormikHelpers } from "formik"
import { Yup } from "~/utils/validation"
import { ButtonVariants } from "~/theme/components"
import { useUser } from "~/components/hooks"
import { HonestDoorLogoSvg } from "~/components/logos"
import { PrivateLeadCreateInput, useCreatePrivateLeadMutation } from "~/generated/graphql"
import { PrivateLeadSource } from "~/utils/constants/private-lead"
import gtag from "~/utils/lib/gtag"

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().when('email', {
    is: (v: string) => !v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  message: Yup.string().max(5000).required(),
})

interface IAskAboutPropertyModal {
  input: PrivateLeadCreateInput
  isOpen: boolean
  onClose?: () => void
}

export const AskAboutPropertyModal: FC<IAskAboutPropertyModal> = ({ input, isOpen, onClose }) => {
  const { user } = useUser()
  const toast = useToast()
  const { mutateAsync: createPrivateLead } = useCreatePrivateLeadMutation()

  const initialValues = {
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    message:
      input.message === undefined
        ? `I would like more information regarding the property at ${[input.streetAddress, input.cityName, input.province].filter(Boolean).join(", ")}`
        : input.message,
  }
  const onSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    try {
      if(input.source === PrivateLeadSource.BuyHomepageContactUs) {
        gtag.event({
          action: "cashback_page_contact_us_submitted",
        })
      }

      setSubmitting(true)
      await createPrivateLead({
        input: { ...input, ...values },
      })
      toast({
        status: "success",
        title: "Success!",
        description: "Your message has been sent!",
        position: "top",
        isClosable: true,
      })
      setSubmitting(false)
      if (onClose) {
        onClose()
      }
    } catch (e) {
      setSubmitting(false)
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top",
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex w="100%" justify="center">
            <HonestDoorLogoSvg />
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleSubmit, errors, touched, getFieldProps, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                  <FormControl label="name" isInvalid={errors.name && touched.name}>
                    <FormLabel>Name</FormLabel>
                    <Input {...getFieldProps("name")} id="name" />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    label="name"
                    isInvalid={errors.email && touched.email}
                    isDisabled={!!user}
                  >
                    <FormLabel>Email</FormLabel>
                    <Input {...getFieldProps("email")} id="email" />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl label="phone" isInvalid={errors.phone && touched.phone}>
                    <FormLabel>Phone</FormLabel>
                    <Input {...getFieldProps("phone")} id="phone" />
                    <FormErrorMessage>{errors.phone}</FormErrorMessage>
                  </FormControl>
                </Stack>

                <FormControl label="message" isInvalid={errors.message && touched.message} my={4}>
                  <FormLabel>Message</FormLabel>
                  <Textarea {...getFieldProps("message")} id="notes" />
                  <FormErrorMessage>{errors.message}</FormErrorMessage>
                </FormControl>

                <Flex mb={3}>
                  <Button
                    type="submit"
                    variant={ButtonVariants.BlackRounded}
                    backgroundColor="hdMoney.full"
                    isLoading={isSubmitting}
                    px="32px"
                  >
                    Submit
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
