import { useEffect, useState } from "react"
import { useSession, signIn, SignInOptions } from "next-auth/react"
import getConfig from "next/config"

interface IUseGoogleOneTapSignIn {
  parentContainerId?: string
}

export const useGoogleOneTapSignIn = (
  opt?: IUseGoogleOneTapSignIn & Pick<SignInOptions, "redirect" | "callbackUrl">,
) => {
  const { status } = useSession()
  const { parentContainerId } = opt || {}
  const [isLoading, setIsLoading] = useState(false)
  const { GOOGLE_CLIENT_ID } = getConfig().publicRuntimeConfig
  useEffect(() => {
    if (status === "unauthenticated") {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: async (response: any) => {
            setIsLoading(true)
            await signIn("googleonetap", {
              credential: response.credential,
              redirect: true,
              ...opt,
            })
            setIsLoading(false)
          },
          prompt_parent_id: parentContainerId,
          cancel_on_tap_outside: false,
        })
        window.google.accounts.id.prompt((notification: any) => {
          if (notification.isNotDisplayed()) {
            console.debug(notification.getNotDisplayedReason())
          } else if (notification.isSkippedMoment()) {
            console.debug(notification.getSkippedReason())
          } else if (notification.isDismissedMoment()) {
            console.debug(notification.getDismissedReason())
          }
        })
      }
    }
  }, [status, parentContainerId])

  return { isLoading }
}
