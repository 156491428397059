import { useEffect, useRef } from "react"

/** Yoinked from https://dev.to/hnrq/useupdateeffect-useeffect-that-doesn-t-run-on-mount-8l6
 *  Same as useEffect, except for not running on the first load, only on subsequent updates
 */

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isFirstMount = useRef(true)

  useEffect(() => {
    if (!isFirstMount.current) effect()
    else isFirstMount.current = false
  }, deps)
}
