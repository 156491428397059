import { FC } from "react"
import { Form, Formik } from "formik"
import { Yup } from "~/utils/validation"
import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  ModalProps,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { ContactFormSubmissionType } from "~/generated/graphql"
import { useCreateContactFormSubmissionMutation } from "~/generated/graphql"

export const ListOnMlsForm: FC<Pick<ModalProps, "onClose">> = ({ onClose }) => {
  const toast = useToast()
  const { mutateAsync: sendContactSubmission } = useCreateContactFormSubmissionMutation()

  return (
    <Formik
      initialValues={{
        email: "",
        phone: "",
        address: "",
        message: "",
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true)
          await sendContactSubmission({
            input: {
              email: values.email,
              name: "",
              message: values.message,
              type: ContactFormSubmissionType.ListOnMls,
              additionalData: {
                address: values.address,
                phone: values.phone,
              },
            },
          })

          setSubmitting(false)
          toast({
            status: "success",
            title: "Success!",
            description: "Your request has been sent!",
            position: "top",
            isClosable: true,
          })
          resetForm()
          onClose()
        } catch (e) {
          setSubmitting(false)
          toast({
            status: "error",
            title: "Oops!",
            description: "Something went wrong. Please try again later...",
            position: "top",
            isClosable: true,
          })
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
        phone: Yup.string().required(),
        address: Yup.string().required(),
        message: Yup.string().required(),
      })}
      enableReinitialize={true}
    >
      {({ handleSubmit, getFieldProps, errors, touched, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <FormControl isInvalid={errors.email && touched.email}>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                {...getFieldProps("email")}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.phone && touched.phone}>
              <Input
                id="phone"
                name="phone"
                type="text"
                placeholder="Phone Number"
                {...getFieldProps("phone")}
              />
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.address && touched.address}>
              <Input
                id="address"
                name="address"
                type="text"
                placeholder="Address"
                {...getFieldProps("address")}
              />
              <FormErrorMessage>{errors.address}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.message && touched.message}>
              <Textarea
                id="message"
                name="message"
                placeholder="Leave a comment or ask a question"
                {...getFieldProps("message")}
                rows={3}
              />
              <FormErrorMessage>{errors.message}</FormErrorMessage>
            </FormControl>

            <HStack justifyContent="end">
              <Button
                variant="white-rounded"
                mr={3}
                onClick={onClose}
                border="1px solid"
                borderColor="hdBlack.200"
              >
                Cancel
              </Button>
              <Button type="submit" variant="green-rounded" isLoading={isSubmitting}>
                Send
              </Button>
            </HStack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
