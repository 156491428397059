// yoinked from https://github.com/vercel/next.js/discussions/14810
import { useCallback, useEffect, useState } from "react"

export const useMediaQuerySSR = (width: number): boolean => {
  const [targetReached, setTargetReached] = useState(false)

  const updateTarget = useCallback(e => {
    if (e.matches) {
      setTargetReached(true)
    } else {
      setTargetReached(false)
    }
  }, [])

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener("change", e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    // @TODO: R18
    // return () => media.removeEventListener("change", e => updateTarget(e))
    return () => {
      media.removeEventListener("change", e => updateTarget(e))
    }
  }, [])

  return targetReached
}
