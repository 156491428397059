import { gql } from "@apollo/client"

export const typeDefs = gql`
  scalar Option
  scalar Bounds
  scalar Properties
  scalar Listings

  extend type Query {
    HDSearch: HDSearch
    HDMap: HDMap
  }

  type HDSearch {
    query: String
    option: Option
    isInvalid: Boolean
  }

  type HDMap {
    style: String
    center: [[Float]]
    zoom: [Int]
    bounds: Bounds
    properties: [Properties]
    listingsData: [Listings]
    listingsCategory: String
    isLoading: Boolean
    showSatellite: Boolean
    userCoords: [[Float]]
    activeMultiPin: Int
  }
`
