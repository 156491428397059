export const locations = {
  allCities: ["Alberta", "Manitoba", "British Columbia"],

  provinces: [
    {
      name: "Alberta",
      shortCode: "CA-AB",
      boundingBox: [48.99, 60.0, -120.0, -109.99],
    },
    {
      name: "Manitoba",
      shortCode: "CA-MB",
      boundingBox: [48.99, 60.0, -102.03, -88.94],
    },
    {
      name: "British Columbia",
      shortCode: "CA-BC",
      boundingBox: [48.3, 60.0, -139.06, -114.03],
    },
  ],
  cities: [
    {
      province: "Alberta",
      name: "Airdrie",
      boundingBox: [51.2414635, 51.3293542, -114.0881057, -113.9001546],
    },
    {
      province: "Alberta",
      name: "Banff",
      boundingBox: [51.1610267219846, 51.1935405717245, -115.585291688812, -115.545128055099],
    },
    {
      province: "Alberta",
      name: "Beaumont",
      boundingBox: [53.3306871, 53.3812741, -113.464481, -113.390644],
    },
    {
      province: "Alberta",
      name: "Calgary",
      boundingBox: [50.842526, 51.2125013, -114.3157587, -113.8600018],
    },
    {
      province: "Alberta",
      name: "Canmore",
      boundingBox: [51.0014659745327, 51.1335464057442, -115.432271341059, -115.222375278238],
    },
    {
      province: "Alberta",
      name: "Cochrane",
      boundingBox: [51.1541942, 51.225017, -114.5249218, -114.4200581],
    },
    {
      province: "Alberta",
      name: "Edmonton",
      boundingBox: [53.3372558, 53.7162646, -113.7138411, -113.2714783],
    },
    {
      province: "Alberta",
      name: "Fort McMurray",
      boundingBox: [56.6437458, 56.8044658, -111.5109382, -111.2070503],
    },
    {
      province: "Alberta",
      name: "Jasper",
      boundingBox: [
        52.755412106022746,
        53.026348273933436,
        -118.50952148437499,
        -117.91488647460936,
      ],
    },
    {
      province: "Alberta",
      name: "Leduc",
      boundingBox: [53.2360565, 53.3085756, -113.6104054, -113.488416],
    },
    {
      province: "Alberta",
      name: "Leduc County",
      boundingBox: [53.0467121, 53.4290338, -114.562397, -113.0011125],
    },
    {
      province: "Alberta",
      name: "Lethbridge",
      boundingBox: [49.6188534, 49.7565104, -112.9496675, -112.7566288],
    },
    {
      province: "Alberta",
      name: "Medicine Hat",
      boundingBox: [49.9895477, 50.1135772, -110.8009864, -110.617509],
    },
    {
      province: "Alberta",
      name: "Okotoks",
      boundingBox: [50.6958142260562, 50.7612914582537, -114.026825933936, -113.905842371923],
    },
    {
      province: "Alberta",
      name: "Red Deer",
      boundingBox: [52.215729, 52.3429183, -113.8977285, -113.718502],
    },
    {
      province: "Alberta",
      name: "Sherwood Park",
      boundingBox: [53.5047493, 53.599496, -113.4016099, -113.2465251],
    },
    {
      province: "Alberta",
      name: "Spruce Grove",
      boundingBox: [53.5193489, 53.5702629, -113.9591034, -113.8483079],
    },
    {
      province: "Alberta",
      name: "St. Albert",
      boundingBox: [53.5995676, 53.6827654, -113.7083009, -113.5656265],
    },
    {
      province: "Manitoba",
      name: "Winnipeg",
      boundingBox: [49.7135538, 49.9940075, -97.3491505, -96.9565293],
    },
  ],
}
