import { useEffect } from "react"

export const useLockBodyScroll = (isOpen: boolean) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow

    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = originalStyle
    }

    // @TODO: R18
    // return () => (document.body.style.overflow = originalStyle) as null

    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [isOpen])
}
