import { useState } from "react"
import { BiEnvelope } from "react-icons/bi"
import gtag from "~/utils/lib/gtag"
import { isEmailValid } from "~/utils/lib/string"

import { Button, ButtonProps } from "@chakra-ui/button"
import { Input, InputGroup, InputProps } from "@chakra-ui/input"
import { BoxProps, Stack } from "@chakra-ui/layout"
import { Icon, InputLeftElement } from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"

type TMailChimpProps = {
  size?: InputProps["size"]
  maxW?: BoxProps["maxW"]
  inputProps?: InputProps
  buttonProps?: ButtonProps
}

const MailChimpForm = ({
  size = "md",
  inputProps,
  buttonProps,
  maxW = "100%",
}: TMailChimpProps): JSX.Element => {
  const [email, setEmail] = useState("")
  const [failed, setFailed] = useState(false)
  const emailCheck = !(email === "" || isEmailValid(email))

  const handleChange = e => {
    if (failed) setFailed(false)
    setEmail(e.currentTarget.value)
  }

  const handleKeyPress = e => {
    const { which } = e

    if (which === 13 /* Enter */) {
      if (isEmailValid(email)) {
        e.currentTarget.closest("form").submit()
      } else {
        setFailed(true)
      }
    }
  }

  const handleSubmit = e => {
    if (isEmailValid(email)) {
      gtag.event({
        category: "Contact",
        action: `Subscribe to Newsletter`,
      })

      e.currentTarget.closest("form").submit()
    } else {
      setFailed(true)
    }
  }

  return (
    <chakra.form
      action="https://gmail.us20.list-manage.com/subscribe/post?u=08eddaaf727a4757b033afd4a&amp;id=284672f744"
      method="POST"
      w="100%"
      maxW={maxW}
    >
      <Stack spacing="4" direction={{ base: "column", md: "row" }} maxW={{ md: "400px" }}>
        <InputGroup
          aria-errormessage="Invalid email address!"
          aria-invalid={emailCheck || failed}
          size={size}
        >
          <InputLeftElement>
            <Icon as={BiEnvelope} color={"gray.500"} />
          </InputLeftElement>
          <Input
            placeholder="Enter your email"
            type="email"
            name="EMAIL"
            required
            variant={"solid"}
            maxW={"full"}
            isInvalid={failed}
            isRequired={true}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            {...inputProps}
          />
        </InputGroup>
        <Button
          variant="solid"
          type="submit"
          bgColor={"hdGold.faded"}
          _hover={{ bgColor: "hdGold.full", color: "white" }}
          flexShrink={0}
          onClick={handleSubmit}
          {...buttonProps}
        >
          Subscribe
        </Button>
      </Stack>
    </chakra.form>
  )
}

export default MailChimpForm
