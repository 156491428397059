import { FC, useMemo } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbLinkProps } from "@chakra-ui/react"
import { useMapFiltersStore } from "~/store/StoreProvider";
import { observer } from "mobx-react-lite";
import { createUrl } from "~/utils";

interface IBreadcrumbItem extends Pick<BreadcrumbLinkProps, "href"> {
  label: string
}

interface IBreadcrumbs {
  hrefVariant?: "cities" | "real-estate" | "recently-sold" | "permits" | "private-listing" | "sitemap" | "sitemapd"
  labelVariant?: string
  items?: IBreadcrumbItem[]
}

export const _Breadcrumbs: FC<IBreadcrumbs> = ({ hrefVariant, labelVariant, items }) => {
  const { place } = useMapFiltersStore()

  const realItems = useMemo(() => {
    return !hrefVariant ? items : [
      {
        href: `/${hrefVariant}`,
        label: labelVariant || hrefVariant,
      },
      ...(place.province ? [{
        href: createUrl({
          variant: hrefVariant,
          provinceAbbr: place.province.abbr,
        }),
        label: place.province.name,
      }] : []),
      ...(place.province && place.city ? [{
        href: createUrl({
          variant: hrefVariant,
          provinceAbbr: place.province.abbr,
          city: place.city,
        }),
        label: place.city,
      }] : []),
      ...(place.neighbourhood ? [{ label: place.neighbourhood }] : []),
    ]
  }, [hrefVariant, place.province?.name, place.city, place.neighbourhood, items])

  return (
    <Breadcrumb marginBottom={3}>
      <BreadcrumbItem>
        <BreadcrumbLink href="/">Home</BreadcrumbLink>
      </BreadcrumbItem>

      {(realItems || []).map((item, i) => {
        return (
          <BreadcrumbItem key={i}>
            {item.href ? (
              <BreadcrumbLink href={item.href}>{item.label}</BreadcrumbLink>
            ) : (
              <BreadcrumbLink
                as="div"
                _hover={{ textDecoration: "none" }}
                style={{ cursor: "initial" }}
              >
                {item.label}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}

export const Breadcrumbs = observer(_Breadcrumbs)
