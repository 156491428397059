import { FC, useMemo, useState } from "react"
import getConfig from "next/config"
import { FormControl, FormLabel, Input, List, ListItem } from "@chakra-ui/react"
import debounce from "lodash/debounce"
import { useCombobox, UseComboboxStateChange } from "downshift"
import { locations } from "~/utils/locations"

interface IMapBoxFeature {
  id: string
  text: string
  place_name: string
  city: string
  address?: string
  center: Array<number>
  context: Array<Record<string, any>>
}

interface IMapboxPlacesAutocomplete {
  showLabel?: boolean
  country?: "CA"
  limit?: number
  region?: string
  onSelect?: (values: {
    region: string
    city: string
    street: string
    unit?: string
    fullAddress: string
    unparsedAddress: string
    latitude: number
    longitude: number
  }) => void
}
const access_token = getConfig().publicRuntimeConfig.MAPBOX_ACCESS_TOKEN
const baseUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places`

export const MapboxPlacesAutocomplete: FC<IMapboxPlacesAutocomplete> = ({
  showLabel = true,
  country = "CA",
  limit = 10,
  region,
  onSelect,
}) => {
  const [items, setItems] = useState([])
  const debouncedOnChange = debounce(onInputValueChange, 500)

  const bbox = useMemo(() => {
    if (!region) return undefined
    const provIndex = locations.provinces.findIndex(p => p.name === region)
    if (provIndex !== -1 && locations.provinces[provIndex].boundingBox) {
      const bboxArray = locations.provinces[provIndex].boundingBox
      return `${bboxArray[2]},${bboxArray[0]},${bboxArray[3]},${bboxArray[1]}`
    }
  }, [region])

  function onInputValueChange({ inputValue }: UseComboboxStateChange<IMapBoxFeature>) {
    const queryParams = {
      access_token,
      country,
      bbox,
      limit: limit.toString(),
    }
    fetch(`${baseUrl}/${inputValue}.json?${new URLSearchParams(queryParams)}`)
      .then(res => res.json())
      .then(data => {
        const filtered = (data?.features || []).filter(item => {
          if (!region) return true
          return item.context.some(i => {
            return i.id.split(".").shift() === "region" && i.text === region
          })
        })

        setItems(filtered)
      })
  }

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    closeMenu,
  } = useCombobox({
    items: [],
    onInputValueChange: debouncedOnChange,
    onSelectedItemChange: ({ selectedItem }) => {
      if (onSelect) {
        onSelect(transformSelected(selectedItem))
      }

      closeMenu()
    },
    itemToString: item => item?.place_name,
  })

  function transformSelected(selectedItem: IMapBoxFeature) {
    const { city, region } = selectedItem.context.reduce(
      (acc, curr) => {
        if (curr.id.includes("place")) {
          return { ...acc, city: curr.text }
        }

        if (curr.id.includes("region")) {
          return { ...acc, region: curr.text }
        }

        return acc
      },
      { city: null, region: null },
    )

    const unparsedAddress = `${selectedItem.address ? selectedItem.address + " " : ""}${
      selectedItem.text
    }`

    return {
      region,
      city,
      unparsedAddress,
      fullAddress: selectedItem.place_name,
      latitude: selectedItem.center[1],
      longitude: selectedItem.center[0],
      ...(selectedItem.address && { street: selectedItem.address }),
    }
  }

  return (
    <FormControl>
      {showLabel && (
        <FormLabel {...getLabelProps()} id="address" htmlFor="address">
          Address
        </FormLabel>
      )}
      <Input {...getInputProps()} w="100%" id="address" aria-label="mapbox-address" />
      <List
        {...getMenuProps()}
        aria-label="mapbox-address"
        overflow="auto"
        maxHeight="200px"
        position="absolute"
        backgroundColor="white"
        zIndex="100"
      >
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              key={`${item}${index}`}
              py={2}
              px={4}
              borderColor="gray.200"
              borderStyle="solid"
              borderWidth="1px"
              backgroundColor={highlightedIndex === index ? "gray.200" : null}
              {...getItemProps({
                item,
                index,
              })}
              onClick={() => selectItem(item)}
            >
              {item?.place_name}
            </ListItem>
          ))}
      </List>
    </FormControl>
  )
}
