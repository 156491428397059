import { EsProperty, Listing } from "~/generated/graphql"
import { Property } from "~/types/core"
import { Presenter } from "~/utils/lib/Presenter"

import { formatStreet } from "./string"

export class PropertyPresenter extends Presenter<EsProperty> {
  get city(): string {
    return this.object.cityName
  }

  get assessmentClass(): string {
    switch (this.object.assessmentClass) {
      case "Other Residential":
      case "Residential":
        return "house"
      case "Commercial":
        return "commercial building"
      case "Condo":
        return "condo"
      default:
        return this.object.assessmentClass
    }
  }

  get fullAddress(): string {
    return [this?.address, this?.object.cityName, this?.object.province, "Canada"].join(", ")
  }

  get lastSoldDelta(): number | null {
    const { predictedValue, closePrice } = this.object

    if (typeof predictedValue !== "number" || typeof closePrice !== "number") {
      return null
    }

    return predictedValue - closePrice
  }

  get lastSoldGrowth(): number | null {
    const { predictedValue, closePrice } = this.object

    if (typeof predictedValue !== "number" || typeof closePrice !== "number") {
      return null
    }

    return (predictedValue - closePrice) / closePrice
  }

  get latLng(): { lat: number; lng: number } {
    return { lat: this.object.location?.lat, lng: this.object.location?.lon }
  }

  get address(): string | null {
    const { unparsedAddress } = this?.object ?? {}

    if (unparsedAddress) {
      return formatStreet(unparsedAddress)
    }
  }
}

export function fullAddress(property: Property | Listing): string {
  return [property.unparsedAddress, property.city, property.province, "Canada"].join(", ")
}
