import { action, makeAutoObservable } from "mobx"

import { enableStaticRendering } from "mobx-react-lite"
import getConfig from "next/config"

const { ENV } = getConfig().publicRuntimeConfig

enableStaticRendering(typeof window === "undefined")

export enum Env {
  DEV = "development",
  STAG = "staging",
  PROD = "production",
}

export type FeatureFlag = {
  enabled: boolean
}

export type FeatureFlags = {
  settings?: boolean
  crea?: boolean
  propertyFilters?: boolean
  listingNotifications?: boolean
  rocketMortgage?: boolean
}

const requireEnvs = (envs: Env[] | Env) => {
  if (typeof envs === "string") {
    return envs === ENV
  }

  return envs.includes(ENV)
}

export class FeatureFlagsStore {
  flags: FeatureFlags = {
    settings: requireEnvs([Env.DEV, Env.STAG]),
    crea: requireEnvs([Env.DEV, Env.STAG, Env.PROD]),
    propertyFilters: requireEnvs([]),
    listingNotifications: requireEnvs([Env.DEV, Env.STAG, Env.PROD]),
    rocketMortgage: requireEnvs([Env.DEV, Env.STAG, Env.PROD]),
  }

  constructor() {
    makeAutoObservable(this, {
      setFlags: action,
      hydrate: action,
    })
  }

  setFlags = (flags: FeatureFlags) => {
    this.flags = {
      ...this.flags,
      ...flags,
    }
  }

  hydrate = (data: this) => {
    if (!data) return
  }
}
