import { FC, useEffect, useState } from "react"
import { useSession } from "next-auth/react"
import { Button, ButtonProps, Spinner, useToast, UseToastOptions } from "@chakra-ui/react"
import { FiArrowUpRight } from "react-icons/fi"
import { IoMdCheckmark } from "react-icons/io"
import { IoMailOutline } from "react-icons/io5"
import { useAuth, useModalDisclosure, useStorage } from "~/components/hooks"
import { WatchPropertyModal } from "~/components/modules"
import { PROPERTY_BOARDS } from "~/utils/constants"
import gtag from "~/utils/lib/gtag"
import {
  NotificationSubscriptionEvent,
  NotificationSubscriptionFrequency,
  useCreateNotificationSubscriptionMutation,
} from "~/generated/graphql"

interface ITrackNeighbourhoodButton {
  board: ValueOf<typeof PROPERTY_BOARDS>
  neighbourhoodId?: string
  neighbourhoodName?: string
  text?: string
  textTracked?: string
  buttonProps?: ButtonProps
}

export const TrackNeighbourhoodButton: FC<ITrackNeighbourhoodButton> = ({
  neighbourhoodId,
  neighbourhoodName,
  board,
  text = "Track Neighbourhood",
  textTracked = "Neighbourhood is Tracked",
  buttonProps,
}) => {
  const { signIn } = useAuth()
  const { isOpen, onOpen, onClose, hasOpened } = useModalDisclosure()
  const { getItem, setItem } = useStorage()
  const { data, status } = useSession()
  const toast = useToast()
  const { mutateAsync: watch, isLoading, isSuccess } = useCreateNotificationSubscriptionMutation()
  const [email, setEmail] = useState(getItem("email") || "")

  useEffect(() => {
    setEmail(getItem("email"))
  }, [getItem("email")])

  if (!neighbourhoodId || !neighbourhoodName) {
    return null
  }

  const successToastOptions: UseToastOptions = {
    title: (
      <>
        <span>You will now receive listing alerts for</span>
        <span>{` ${neighbourhoodName}`}</span>
      </>
    ),
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top",
  }

  const failureToastOptions: UseToastOptions = {
    title: "Something went wrong, please try again",
    status: "error",
    duration: 5000,
    position: "top",
  }

  const handleLoggedInCreateSubscription = async () => {
    const { createNotificationSubscription } = await watch({
      input: {
        name: `${neighbourhoodName} | New Listings`,
        email: data?.user?.email,
        eventType: NotificationSubscriptionEvent.ListingsCreated,
        frequency: NotificationSubscriptionFrequency.RealTime,
        neighbourhoodId,
      },
    })
    if (createNotificationSubscription?.id) {
      toast(successToastOptions)
    } else {
      toast(failureToastOptions)
    }
  }

  const handleCreateSubscription = async () => {
    const { createNotificationSubscription } = await watch({
      input: {
        name: `${neighbourhoodName} | New Listings`,
        email: data?.user?.email ?? email ?? "",
        eventType: NotificationSubscriptionEvent.ListingsCreated,
        frequency: NotificationSubscriptionFrequency.RealTime,
        neighbourhoodId,
      },
    })

    if (createNotificationSubscription?.id) {
      toast(successToastOptions)
      setItem("email", email)
      onClose()
    } else {
      toast(failureToastOptions)
    }
  }

  return (
    <>
      <Button
        variant="outline"
        _hover={{ color: "inherit" }}
        color="inherit"
        rightIcon={isSuccess || isLoading ? undefined : <FiArrowUpRight size={20} />}
        leftIcon={
          isSuccess ? (
            <IoMdCheckmark fontSize={"24px"} />
          ) : !isLoading ? (
            <IoMailOutline fontSize={"24px"} />
          ) : (
            <Spinner />
          )
        }
        onClick={e => {
          e.stopPropagation()
          if (board === PROPERTY_BOARDS.UNLISTED) {
            gtag.event({
              action: "Property_watch_listing",
            })
          }
          status == "authenticated" ? handleLoggedInCreateSubscription() : onOpen()
        }}
        {...buttonProps}
      >
        {isSuccess ? textTracked : text}
      </Button>

      {hasOpened && (
        <WatchPropertyModal
          {...{
            email,
            setEmail,
            isOpen,
            onClose,
            onSubmit: handleCreateSubscription,
            onSignUp: signIn,
            loading: isLoading,
            title: `Get notified when a new listing hits the market in ${neighbourhoodName}`,
          }}
        />
      )}
    </>
  )
}
