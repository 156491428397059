import { action, makeAutoObservable } from "mobx"

import { enableStaticRendering } from "mobx-react-lite"

enableStaticRendering(typeof window === "undefined")

// @TODO clarify naming and move to constants
export enum PageType {
  PROPERTY = "PROPERTY",
  CREA_LISTING = "CREA_LISTING",
  BUILDING = "BUILDING",
  PRIVATE_LISTING = "PRIVATE_LISTING",
}

export type PageModalState = {
  type: PageType
  isOpen: boolean
  id?: string
  slug?: string
}

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

type OpenPageModalOpts = {
  type: PageType
  id: string
  slug: string
  geohash: string
}

export class PageModalStore {
  isOpen: boolean = false
  type: PageType | null = null
  id: string | null = null
  slug: string | null = null
  geohash: string | null = null
  prevRoute: string | null = null

  constructor() {
    makeAutoObservable(this, {
      onOpen: action,
      onClose: action,
      toggleOpen: action,
      openPageModal: action,
      setContextualRoute: action,
      hydrate: action,
    })
  }

  onOpen = () => {
    this.isOpen = true
  }

  onClose = (pushState: boolean = true) => {
    this.isOpen = false

    if (pushState) {
      return window.history.pushState(
        { ...window.history.state, as: this.prevRoute, url: this.prevRoute },
        "",
        this.prevRoute,
      )
    }

    return
  }

  toggleOpen = () => {
    this.isOpen = !this.isOpen
  }

  openPageModal = (opts: RequireOnlyOne<OpenPageModalOpts, "id" | "slug" | "geohash">) => {
    const { type, geohash, id, slug } = opts

    this.type = type

    if (slug) {
      this.slug = slug
    }

    if (geohash) {
      this.geohash = geohash
    }

    if (id) {
      this.id = id
    }

    this.isOpen = true
  }

  setContextualRoute = (pathname: string) => {
    if (window.location.pathname === pathname) return

    this.prevRoute = `${window.location.pathname}${window.location.search}`

    return window.history.pushState(
      { ...window.history.state, as: pathname, url: pathname },
      "",
      pathname,
    )
  }

  hydrate = (data: Partial<this>) => {
    if (data?.isOpen !== undefined) {
      this.isOpen = data.isOpen
    } else {
      this.isOpen = false
    }

    if (data?.type) {
      this.type = data.type
    }

    if (data?.id) {
      this.id = data.id
    }

    if (data?.slug) {
      this.slug = data.slug
    }
  }
}
