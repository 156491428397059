import { action, computed, makeAutoObservable } from "mobx"
import { enableStaticRendering } from "mobx-react-lite"
import { Maybe } from "~/generated/graphql"

import { store, updateMapQueryParams } from "../StoreProvider"
import { MapView } from "../map"
import { Bbox } from "../types"

enableStaticRendering(typeof window === "undefined")

export enum FilterCategory {
  ForSale = "For Sale",
  Properties = "Off-Market Properties",
  RecentlySold = "Recently Sold",
  // ExclusiveListings = "Exclusive Listings",
}

export const categorySuffixes = {
  [FilterCategory.ForSale]: "Homes for Sale",
  [FilterCategory.RecentlySold]: "Recently Sold Properties",
  [FilterCategory.Properties]: "Off-Market Properties",
  // [FilterCategory.ExclusiveListings]: "Exclusive Listings",
}

export type Place = {
  province: Maybe<{name: string; abbr: string}>
  city: Maybe<string>
  neighbourhood?: Maybe<string>
}
const defaultMapFilters: MapFilters = {
  filterCategory: FilterCategory.ForSale,
  bbox: null,
  place: { province: null, city: null, neighbourhood: null },
}

type MapFilters = {
  filterCategory: FilterCategory
  bbox: Bbox | null
  place: Place
}

export class MapFiltersStore {
  bbox: Bbox = defaultMapFilters.bbox
  place: Place = defaultMapFilters.place
  filterCategory: FilterCategory = defaultMapFilters.filterCategory

  constructor() {
    makeAutoObservable(this, {
      setBbox: action,
      setPlace: action,
      setFilterCategory: action,
      resetCategoryFilters: action,
      resetCategoryFiltersDisabled: computed,
      activeCategoryFiltersCount: computed,
      activeCategoryPageTitle: computed,
      hydrate: action,
    })
  }

  setBbox = (bbox: Bbox): void => {
    this.bbox = bbox
    this.place = defaultMapFilters.place
  }

  setFilterCategory = (filterCategory: FilterCategory): void => {
    this.filterCategory = filterCategory
    store.creaFiltersStore.setPage(1)
    store.recentlySoldFiltersStore.setPage(1)
    store.propertyFiltersStore.setPage(1)

    updateMapQueryParams(
      {
        filters: {
          map: { filterCategory },
          crea: { page: 1 },
          recentlySold: { page: 1 },
          property: { page: 1 },
        },
      },
      { skipRouter: true },
    )
  }

  resetCategoryFilters = (category: FilterCategory): void => {
    if (category === FilterCategory.RecentlySold) {
      store.recentlySoldFiltersStore.resetFilters()
    } else if (category === FilterCategory.Properties) {
      store.propertyFiltersStore.resetFilters()
    } else if (category === FilterCategory.ForSale) {
      store.creaFiltersStore.resetFilters()
    }
  }

  get resetCategoryFiltersDisabled(): boolean {
    return (
      (this.filterCategory === FilterCategory.RecentlySold &&
        !store?.recentlySoldFiltersStore.isFiltered) ||
      (this.filterCategory === FilterCategory.Properties &&
        !store?.propertyFiltersStore.isFiltered) ||
      (this.filterCategory === FilterCategory.ForSale && !store?.creaFiltersStore.isFiltered)
    )
  }

  get activeCategoryFiltersCount(): number {
    if (this.filterCategory === FilterCategory.RecentlySold) {
      return store?.recentlySoldFiltersStore.activeFiltersCount ?? 0
    } else if (this.filterCategory === FilterCategory.Properties) {
      return store?.propertyFiltersStore.activeFiltersCount ?? 0
    } else if (this.filterCategory === FilterCategory.ForSale) {
      return store?.creaFiltersStore.activeFiltersCount ?? 0
    }

    return 0
  }

  get activeCategoryPageTitle(): string {
    const { city, neighbourhood, province } = this.place

    const suffix = categorySuffixes[this.filterCategory]

    const title = [
      [neighbourhood, city, !neighbourhood && province?.name]
        .filter(Boolean)
        .join(", "),
      suffix,
    ]
      .filter(Boolean)
      .join(" ")

    return store?.mapStore.view === MapView.LIST ? title : categorySuffixes[this.filterCategory]
  }

  setPlace = (place: Place): void => {
    this.place = place

    updateMapQueryParams({ filters: { map: { place } } }, { skipRouter: true })
  }

  hydrate = (data: this): void => {
    if (!data) return

    if (data.bbox !== undefined) {
      this.bbox = data.bbox
    }

    if (data.filterCategory) {
      this.filterCategory = data.filterCategory
    }

    if (data.place) {
      this.place = data.place
    }
  }
}
