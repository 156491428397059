import { FC, useEffect, useState } from "react"
import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { gql, useLazyQuery } from "@apollo/client"
import { useAuth, useUser } from "~/components/hooks"
import Blur from "~/components/helpers/Blur"
import { PropertiesAutocomplete } from "~/components/modules"
import {
  PropertyFullDocument,
  PropertyFullFragment,
  useCreateCmaReportMutation,
  useCreatePrivateLeadMutation,
  useCreateProductCheckoutSessionMutation,
  useGetProductsWithPricesQuery,
} from "~/generated/graphql"
import { provinces } from "~/utils/constants"
import { ButtonVariants } from "~/theme/components"
import { FaSearch } from "react-icons/fa"
import { formatCurrency, provinceLongToProvinceAbbr } from "~/utils"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"
import gtag from "~/utils/lib/gtag"
import { useRouter } from "next/router"
import MailChimpForm from "~/components/modules/MailChimpForm"

interface ICmaReportWidget {}

export const CmaReportWidget: FC<ICmaReportWidget> = () => {
  const toast = useToast()
  const { user } = useUser()
  const { openSignInModal } = useAuth()
  const {
    query: { checkout_success },
  } = useRouter()
  const [property, setProperty] = useState(null)

  const { data } = useGetProductsWithPricesQuery({
    filter: { sku: "CMA_REPORT" },
  })
  const [loadProperty, { loading: isPropertyLoading }] = useLazyQuery(gql(PropertyFullDocument))
  const { mutateAsync: createPrivateLead, isLoading: isPrivateLeadCreateLoading } =
    useCreatePrivateLeadMutation()
  const { mutateAsync: createCmaReport, isLoading: createCmaReportLoading } =
    useCreateCmaReportMutation()
  const { mutateAsync: createProductCheckoutSession, isLoading: productCheckoutSessionLoading } =
    useCreateProductCheckoutSessionMutation()
  const productPrice = data?.getProducts[0]?.prices[0]

  useEffect(() => {
    if (checkout_success === "true") {
      gtag.event({
        action: "Report_stripe_paid_success",
      })
      toast({
        status: "success",
        title: "Success!",
        description: "You have successfully paid for the CMA report!",
        position: "top-right",
        isClosable: true,
      })
    }
  }, [checkout_success])

  const onPropertySelect = async (selectedProperty: PropertyFullFragment) => {
    try {
      gtag.event({
        action: "Report_address_searched",
      })

      const { data } = await loadProperty({ variables: { id: selectedProperty.id } })
      setProperty(data?.property)
      const province = provinceLongToProvinceAbbr(data?.property?.province)

      await createPrivateLead({
        input: {
          province,
          source: PrivateLeadSource.CmaSearch,
          type: PrivateLeadType.Sell,
          cityName: data?.property?.cityName,
          streetAddress: data?.property?.unparsedAddress,
          slug: data?.property?.slug,
          hdPrice: data?.property?.predictedValue,
        },
      })
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  const handlePay = async () => {
    try {
      gtag.event({
        action: "Report_stripe_clicked",
      })
      await createPrivateLead({
        input: {
          propertyId: property.id,
          province: property?.province,
          source: PrivateLeadSource.CmaClick,
          type: PrivateLeadType.Sell,
          cityId: property?.city,
          cityName: property?.cityName,
          streetAddress: property?.unparsedAddress,
          slug: property?.slug,
          hdPrice: property?.predictedValue,
          neighbourhoodId: property?.neighbourhood,
          neighbourhoodName: property?.neighbourhoodName,
        },
      })

      const { createCmaReport: cmaReport } = await createCmaReport({
        input: {
          cityId: property.city,
          cityName: property.cityName,
          province: property.province,
          propertyId: property.id,
          slug: property.slug,
          streetAddress: property.unparsedAddress,
        },
      })
      const {
        createProductCheckoutSession: { url },
      } = await createProductCheckoutSession({
        input: {
          userId: user?.id,
          customerEmail: user?.email,
          productId: productPrice.productId,
          priceId: productPrice.id,
          productReferenceId: cmaReport.id,
          successUrl: `${window.location.href}?checkout_success=true`,
          cancelUrl: `${window.location.href}?checkout_success=false`,
        },
      })
      window.location.href = url
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top",
        isClosable: true,
      })
    }
  }

  return (
    <>
      <Box color="initial">
        <Blur
          minH="110px"
          blurred={!user}
          overlay={
            <VStack h="100%" w="100%" justifyContent="center">
              <Text textAlign="center">
                <Link fontWeight="bold" onClick={() => openSignInModal()}>
                  Sign in
                </Link>
                &nbsp;to purchase a report
              </Text>
            </VStack>
          }
        >
          {(isPropertyLoading || isPrivateLeadCreateLoading) && (
            <Box position="absolute" left={0} right={0} top={0} bottom={0} zIndex={1000}>
              <Center h="100%" w="100%">
                <Spinner color="hdGold.full" size="xl" thickness="5px" />
              </Center>
            </Box>
          )}

          {property ? (
            <>
              {[provinces.AB, provinces.BC].includes(property.province) ? (
                <>
                  <Box textAlign="center" color="gray">
                    {[property.unparsedAddress, property.cityName, property.province].join(", ")}
                  </Box>

                  <Heading as="h2" fontSize="lg" my={6} textAlign="center">
                    One time payment
                  </Heading>
                  <Heading as="h2" textAlign="center" color="green.700">
                    {formatCurrency(productPrice?.amount / 100)}
                  </Heading>
                  <Box textAlign="center" my={6}>
                    <Button
                      variant="secondary"
                      onClick={handlePay}
                      isLoading={createCmaReportLoading || productCheckoutSessionLoading}
                    >
                      Pay now
                    </Button>
                  </Box>
                  <Center mt={3}>
                    <Button
                      variant={ButtonVariants.BlackRounded}
                      backgroundColor="hdMoney.full"
                      leftIcon={<Icon as={FaSearch} />}
                      onClick={() => setProperty(null)}
                    >
                      Search again
                    </Button>
                  </Center>
                </>
              ) : (
                <>
                  <Box color="hdFire.full">
                    We&apos;re still getting set up in {property.province}! Join our waiting list
                    and we&apos;ll notify you once we&apos;re up and running!
                  </Box>

                  <Box mt={3}>
                    <MailChimpForm />
                  </Box>

                  <Center mt={6}>
                    <Button
                      variant={ButtonVariants.BlackRounded}
                      backgroundColor="hdMoney.full"
                      leftIcon={<Icon as={FaSearch} />}
                      onClick={() => setProperty(null)}
                    >
                      Search again
                    </Button>
                  </Center>
                </>
              )}
            </>
          ) : (
            <PropertiesAutocomplete onSelect={onPropertySelect} showLabel={false} />
          )}
        </Blur>
      </Box>
    </>
  )
}
