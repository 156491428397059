import { FC, useState } from "react"
import {
  Flex,
  Text,
  Box,
  Image,
  SimpleGrid,
  HStack,
  IconButton,
  AbsoluteCenter,
} from "@chakra-ui/react"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa"
import { FileType, useCreateFileWritableUrlMutation } from "~/generated/graphql"
import { Spinner } from "@chakra-ui/spinner"
import { Badge } from "@chakra-ui/layout"
import { Tooltip } from "~/components/modules"
import { compressImageFile } from "~/utils/image"

interface IFile extends File {
  preview: string
  order: number
}

interface IS3Dropzone {
  files?: Partial<IFile>[]
  options?: DropzoneOptions
  fileType: FileType
  metadata?: Record<string, any>
  onChange: (files: Partial<IFile>[]) => void
  onLoadingChange: (isLoading: boolean) => void
  isDisabled?: boolean
}

export const S3Dropzone: FC<IS3Dropzone> = ({
  options,
  fileType,
  metadata,
  onChange,
  onLoadingChange,
  files: initialFiles = [],
  isDisabled,
}) => {
  const [files, setFiles] = useState(initialFiles)
  const { mutateAsync: createFileWritableUrl } = useCreateFileWritableUrlMutation()
  const [isLoading, setIsLoading] = useState(false)
  const { getRootProps, getInputProps } = useDropzone({
    disabled: isLoading || isDisabled,
    onDropAccepted: async droppedFiles => {
      setIsLoading(true)
      let nextIdx = files.length > 0 ? files[files.length - 1].order + 1 : 0
      onLoadingChange(true)

      const uploadedFiles = await Promise.all(
        droppedFiles.map(async (file) => {
          // TODO: improve with condition for non image files
          const fileComp = await compressImageFile(file, {
            quality: 0.8,
            type: 'image/jpeg',
            maxWidth: 1600,
            maxHeight: 1200,
          })

          const {
            createFileWritableUrl: { url, futureUrl },
          } = await createFileWritableUrl({
            input: {
              type: fileType,
              name: fileComp.name,
              contentLength: fileComp.size,
              metadata,
            },
          })
          await fetch(url, { method: "PUT", body: fileComp })
          return {
            name: fileComp.name,
            size: fileComp.size,
            type: fileComp.type,
            preview: URL.createObjectURL(fileComp),
            url: futureUrl,
          }
        }),
      )

      const newFiles = [
        ...files,
        ...uploadedFiles.map(f => ({
          ...f,
          order: nextIdx++,
        })),
      ]
      setFiles(newFiles)
      onChange(newFiles)
      onLoadingChange(false)
      setIsLoading(false)
    },
    ...options,
  })

  const handleReorder = (idx: number, direction: "left" | "right") => {
    const newFiles = [...files]
    const [removed] = newFiles.splice(idx, 1)
    newFiles.splice(direction === "left" ? idx - 1 : idx + 1, 0, removed)
    const sortedFiles = newFiles.map((file, idx) => ({ ...file, order: idx }))
    setFiles(sortedFiles)
    onChange(sortedFiles)
  }

  const handleDelete = (idx: number) => {
    const sortedFiles = files
      .filter((_, i) => i !== idx)
      .map((file, idx) => ({ ...file, order: idx }))
    setFiles(sortedFiles)
    onChange(sortedFiles)
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      minH="100px"
      borderColor="gray.200"
      borderWidth="1px"
      p={4}
      borderRadius="md"
      borderStyle="dashed"
      {...getRootProps({ className: "dropzone" })}
      position="relative"
    >
      <input {...getInputProps()} />
      <Box>
        <Text color="gray.500" textAlign="center">
          Drag &apos;n&apos; drop some images here, or click to select files
        </Text>
        {isLoading && (
          <AbsoluteCenter axis="both" zIndex={1000}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </AbsoluteCenter>
        )}
      </Box>

      {files.length > 0 && (
        <Box mt={6}>
          <SimpleGrid columns={[1, 1, 2, 3]} spacing="1em">
            {files.map((file, idx) => (
              <Box key={idx} position="relative" w="100%" maxH="200px">
                <HStack position="absolute" top={3} right={3} zIndex="1">
                  <Tooltip label="Move left">
                    <IconButton
                      rounded="full"
                      aria-label="Reorder left"
                      icon={<FaChevronLeft />}
                      size="xs"
                      onClick={e => {
                        e.stopPropagation()
                        handleReorder(idx, "left")
                      }}
                      isDisabled={idx === 0 || isLoading|| isDisabled}
                    />
                  </Tooltip>
                  <Tooltip label="Move right">
                    <IconButton
                      rounded="full"
                      aria-label="Reorder right"
                      icon={<FaChevronRight />}
                      size="xs"
                      onClick={e => {
                        e.stopPropagation()
                        handleReorder(idx, "right")
                      }}
                      isDisabled={idx === files.length - 1 || isLoading || isDisabled}
                    />
                  </Tooltip>
                  <IconButton
                    rounded="full"
                    size="xs"
                    aria-label="Delete"
                    icon={<FaTrash />}
                    onClick={e => {
                      e.stopPropagation()
                      handleDelete(idx)
                    }}
                    colorScheme="red"
                    isDisabled={isLoading|| isDisabled}
                  />
                </HStack>
                <Image src={file.preview} w="100%" h="100%" objectFit="cover" rounded="md" />
                {idx === 0 && (
                  <Badge
                    position="absolute"
                    bottom={3}
                    left={3}
                    zIndex="1"
                    ml="1"
                    backgroundColor="green.500"
                    color="white"
                    fontSize="12px"
                    fontWeight="normal"
                    textTransform="initial"
                  >
                    Main image
                  </Badge>
                )}
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Flex>
  )
}
