import NextLink, { LinkProps } from "next/link"
import { ReactElement } from "react"
import gtag from "~/utils/lib/gtag"

import { LinkProps as AProps, Link as ChakraLink, LinkOverlay } from "@chakra-ui/react"

type TInternalLinkProps = LinkProps & {
  onClick?: () => void
  disabled?: boolean
  overlay?: boolean
  linkProps?: AProps
} & Partial<AProps>

const InternalLink = (props: TInternalLinkProps): ReactElement => {
  const {
    children,
    href,
    prefetch = false,
    passHref = true,
    scroll,
    shallow,
    onClick,
    disabled,
    overlay = false,
    linkProps,
  } = props

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else {
      gtag.event({
        category: "Internal Link",
        action: `Follow ${href}`,
        label: `url: ${href}`,
      })
    }
  }

  if (disabled) return <>{children}</>
  else {
    return (
      <>
        {overlay ? (
          <LinkOverlay
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
            onClick={handleClick}
            {...linkProps}
          >
            {children}
          </LinkOverlay>
        ) : (
          <ChakraLink
            // @TODO: R18
            // as={"span"}
            as={NextLink}
            href={href ?? ""} // Login/logout actions don't need SEO
            passHref={passHref}
            prefetch={prefetch}
            scroll={scroll}
            shallow={shallow}
            textDecoration="none"
            _hover={{ textDecoration: "none", color: "hdGold.full" }}
            onClick={handleClick}
            {...linkProps}
          >
            {children}
          </ChakraLink>
        )}
      </>
    )
  }
}

export default InternalLink
