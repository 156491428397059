import { FC, PropsWithChildren, useState } from "react"
import { Box, Tooltip as ChakraTooltip, TooltipProps, useBreakpointValue } from "@chakra-ui/react"

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  children,
  placement = "bottom",
  ...rest
}) => {
  const isMobile = useBreakpointValue([true, true, false])
  const [isLabelOpen, setIsLabelOpen] = useState(false)

  return (
    <ChakraTooltip
      isOpen={isLabelOpen} placement={isMobile ? "bottom" : placement} {...rest}>
      <Box
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
      >
        {children}
      </Box>
    </ChakraTooltip>
  )
}
