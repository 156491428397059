import { cardAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

export enum CardVariants {
  Property = "property",
  CallToAction = "CTA",
}

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

export default defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    container: {
      padding: 5,
      borderWidth: "1px",
      borderColor: "gray.200",
      borderRadius: "md",
      overflow: "hidden",
      bgColor: "white",
    },
  }),

  variants: {
    property: definePartsStyle({
      container: {
        minHeight: "180px",
        cursor: "pointer",
        padding: 0,
        borderWidth: "1px",
        borderColor: "gray.200",
        borderRadius: "lg",
        transition: "all 0.2s ease-in-out",
        boxShadow: "card",
        _hover: {
          boxShadow: "cardHover",
          transform: "scale(1.01)",
        },
      },
    }),

    CTA: definePartsStyle({
      container: {
        cursor: "pointer",
        padding: 6,
        borderWidth: "1px",
        borderColor: "gray.200",
        borderRadius: "lg",
        transition: "all 0.2s ease-in-out",
        boxShadow: "card",
        _hover: {
          boxShadow: "cardHover",
          transform: "scale(1.01)",
        },
      },
    }),
  },
})
